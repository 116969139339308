import React from 'react';
import AbstractPage from './AbstractPage';
import ParallaxLayout from './ParallaxLayout';

class ParallaxPage extends AbstractPage {
  render() {
    const { announcement, loaded, headless } = this.state;
    const { headerMenu, mainMenu, secondaryFooterMenu, footerTopPartners, footerSecondaryPartners, content } = this.props;

    const children = this.renderChildren();

    return (
      <ParallaxLayout
        headerMenu={headerMenu && headerMenu}
        mainMenu={mainMenu && mainMenu}
        secondaryFooterMenu={secondaryFooterMenu && secondaryFooterMenu}
        announcement={loaded && announcement && announcement}
        hideAnnouncement={this.hideAnnouncement}
        footerTopPartners={footerTopPartners && footerTopPartners}
        footerSecondaryPartners={footerSecondaryPartners && footerSecondaryPartners}
        seo={content && content.seo}
        headless={headless}
      >
        {children}
      </ParallaxLayout>
    );
  }
}

export default ParallaxPage;
