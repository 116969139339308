import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-white-4.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const WhiteSplatter4: React.StatelessComponent<WidthHeightGreebleType> = ({ top, right, bottom, zIndex }) => (
  <StyledGreeble top={top} right={right} bottom={bottom} width={20} height={20} zIndex={zIndex} image={SplatterImage} />
);

export default WhiteSplatter4;
