import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';

export const StyledSection = styled(CSSParallax.Section)`
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  position: relative;
  z-index: 3;
  font-size: inherit;
`;
