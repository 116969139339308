import React from 'react';
import { FloaterInner, FloaterOuter } from './styles';

const Floater: React.FunctionComponent<any> = ({ children }) => (
  <FloaterOuter>
    <FloaterInner>{children}</FloaterInner>
  </FloaterOuter>
);

export default Floater;
