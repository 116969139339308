import styled from 'styled-components';
import { SvgIconType, ThemeType } from '../types';

interface Props extends SvgIconType, ThemeType {}

export default styled.svg(
  ({ theme: { iconSizes }, fill, size = 'md' }: Props) => `
    fill: ${fill || 'currentColor'};
    width: ${iconSizes[size]};
    height: ${iconSizes[size]};
`,
);
