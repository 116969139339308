import React from 'react';
import { Image } from '../../components';
import AppMockup from '../../assets/images/phone-app-mockup.png';
import Floater from '../../components/Floater';
import StyledRowBlock from './styles';
import AppButtons from '../../components/AppButtons';

interface Props {
  title: string;
  subTitle: string;
  appleLink?: string;
  androidLink?: string;
}

export const AppBlock: React.StatelessComponent<Props> = ({ title, subTitle, androidLink, appleLink }) => (
  <StyledRowBlock
    title={title}
    subTitle={subTitle}
    type="medium"
    operations={<AppButtons withStroke={true} appleLink={appleLink} androidLink={androidLink} />}
  >
    <Floater>
      <div
        style={{
          maxWidth: '300px',
          margin: 'auto',
        }}
      >
        <Image url={AppMockup} alt="MySplendour App Mockup" />
      </div>
    </Floater>
  </StyledRowBlock>
);

export default AppBlock;
