import styled from 'styled-components';
import SvgType from './type';

export default styled.svg(
  ({ color, flipX, flipY }: SvgType) => `
    font-size: 0;
    fill: ${color};
    transform:${flipY ? ' scaleY(-1)' : ''}${flipX ? ' scaleX(-1)' : ''} translateY(1px);
`,
);
