import styled from 'styled-components';

import StyledContentType from './types';

export default styled.div(
  ({ isFirstSection, pageHasAnnouncement, natural, theme: { everythingButIos } }: StyledContentType) => `
    position: relative;
    z-index: 14;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    min-height: 45vh;
    padding-top: ${isFirstSection ? `${pageHasAnnouncement ? '120' : '60'}px` : '0'};

    ${
      !natural
        ? `
          ${everythingButIos} {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
          }
        `
        : ''
    }
`,
);
