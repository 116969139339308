import styled from 'styled-components';
import { ThemeType } from '../../types';
import { Container } from '../../blocks/Block/styles';
import { HeroContainer, HeroWrapper } from '../../blocks/Hero/styles';
import ToggleButtonsWrapper, { OptionButton } from '../ToggleButtons/styles';

export const StyledFilterHeader = styled.div(
  ({ theme: { colors, spacing, mobileView } }: ThemeType) => `
    color: ${colors.green};
    padding: 4% ${spacing.x6};

    ${mobileView} {
      padding: 77px ${spacing.x3};
    }

    ${HeroWrapper} {
      padding: 0;

      h1 {
        margin-bottom: 48px;
      }
    }
  `,
);

export const StyledFilterButtonsHeader = styled.div(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
    padding: ${spacing.x11} 0 ${spacing.x4};
    z-index: 10;

    > section {
      padding: 0 !important;
    }

    ${Container} {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between;
      align-items: center;
    }

    ${HeroContainer} {
      width: auto !important;
      flex: none !important;
    }

    ${ToggleButtonsWrapper} {
      flex: 1;
      text-align: right;
    }

    ${OptionButton} {
      margin-right: 0 !important;
      margin-left: ${spacing.x3};
    }

    h1 {
      margin-bottom: 0 !important;
    }

    ${mobileView} {
      ${Container} {
        flex-direction: column !important;
        align-items: flex-start;
      }

      ${ToggleButtonsWrapper} {
        margin-top: ${spacing.x5};
        text-align: left;
      }

      ${OptionButton} {
        margin-left: 0 !important;
        margin-right: ${spacing.x2} !important;
      }
    }
`,
);

export const StyledFilterSidebar = styled.div(
  ({ theme: { colors, spacing, mobileView } }: ThemeType) => `
    text-align: left;
    border: 2px solid ${colors.white};
    border-radius: 8px;
    background-color: ${colors.black80};
    padding: ${spacing.x2};
    margin-bottom: ${spacing.x2};

    .filter-link {
      cursor: pointer;
      color: ${colors.white}
      margin-bottom: 14px;
      display: block;
      text-transform: uppercase;

      &:last-of-type {
        margin-bottom: 8px;
      }

      &:hover, &.active {
        color: ${colors.green}
      }
    }

    ${mobileView} {
      display: none;
    }
  `,
);

export const StyledFilterContent = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    ${mobileView} {
      width: 100%;
    }
`,
);

export const InputContainer = styled.div(
  ({ isFocused, theme: { colors } }: ThemeType & { isFocused: boolean }) => `
    margin: 0 auto;
    align-items: stretch;
    background-color: ${isFocused ? colors.white : colors.black75};
    border-radius: 8px;
    border: 2px solid ${colors.white};
    display: flex;
    max-width: 680px;
    overflow: hidden;
    width: 100%;
    transition: background .3s ease-in-out;

    button {
      margin-right: 16px;
      align-self: center;
      pointer-events: none;
      color: ${colors.white};
      opacity: ${isFocused ? 1 : 0.7};

      svg {     
        color: ${isFocused ? colors.black : colors.white};
        height: 22px;
        width: 22px;
        transition: color .3s ease-in-out;
      }
    }

    input {
      appearance: none;
      outline: none;
      background: transparent;      
      border: none;
      box-shadow: none;
      color: ${isFocused ? colors.black : colors.white};
      flex: 1 auto;
      font-size: 16px;
      height: 48px;
      padding-left: 16px;
      transition: color .3s ease-in-out;

      &::placeholder {
        color: ${isFocused ? colors.black : colors.white};
      }
    }
  `,
);
