import React from 'react';
import { MenuType } from '../../types';
import SlideMenu from '../SlideMenu';
import SubscriptionForm from '../SubscriptionForm';
import { SECTION_THEMES } from '../../constants';
import PinkSplatter3 from '../CSSParallax/Greeblies/PinkSplatter3';
import WhiteSplatter5 from '../CSSParallax/Greeblies/WhiteSplatter5';

const SubscriptionMenu: React.StatelessComponent<MenuType> = (props) => (
  <SlideMenu {...props} width="480px" mobileWidth="100%" theme={SECTION_THEMES.GREEN}>
    <WhiteSplatter5 top={0} right={0} zIndex={-1} />
    <PinkSplatter3 bottom={0} left={-22} zIndex={-1} />
    <SubscriptionForm formId="_form_18" />
  </SlideMenu>
);

export default SubscriptionMenu;
