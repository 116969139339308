import React from 'react';
import SvgType from './type';
import StyledSvg from './style';

const BrushStroke4: React.StatelessComponent<SvgType> = ({ color, flipX, flipY }) => (
  <StyledSvg flipX={flipX} flipY={flipY} preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 112" color={color}>
    <path
      d="M1440,112V37.7c-3.2-0.7-6.6-1-9.9-0.6c-4.6,0.6-9.1,0.1-13.6,0.2c-2.8,0.1-5.6-0.1-8.4-0.1c-1.2-0.1-2.5-0.2-3.7-0.2
			c-2.2,0.2-4.1,0.2-6-0.5c-1.2-0.5-2.6-0.4-3.7,0.2c-0.6,0.3-1.4,0.6-2.2,0.6c-2.9,0.1-5.4,1-8.4,0.8c-0.6,0-1.2,0.1-1.8,0.3
			c-5.3,0.9-10.8,1.1-16.1,0.6l-2.1,1.2l-1.1-0.9l-2.1,0.7l-1.1-0.4c-1.9,0.1-3.4,0.1-4.8,0.1c-0.7,0-1.5-0.3-2.1-0.3
			c-5.4,0.5-10.9,0.2-16.3,0.3c-1.7,0-3.3,0.4-4.6,1.1c-1,0.4-2,0.8-3,1c-1.5,0.4-3,0.8-5.2,1.4l-4.2,0.1l-2.1-0.8
			c-1.8,0-3.1,0-4.5-0.1c-1.4-0.1-2.8-0.4-4.1-0.6l-4.6,1.9l-3.4-0.4l-2.2,1.2l-4.4-0.2l-4.8,1.6c-5-0.2-9.7,0.7-14.8,1.1l-5.2-0.9
			c-2.2,1-5.9,0-8.2,1.4c-3.2,0.3-6.5,0.7-9.5,1s-6,0.7-9.4,1.2l-4.6-1.1l-5.2,0.4c-3.1-0.2-6.1-0.5-9-0.7c-0.9,0-1.7,0-2.5,0.2
			c-4.3,0.7-8.5,1.5-12.9,2l-5.3-1l-9,1c-3.1-0.8-3.1-0.8-6.7,0c-0.8,0.2-1.6,0.3-2.4,0.5c-3.6,0.9-7.5,0.8-11-0.3l-4.8,0.2
			l-0.8,0.5l-5.6,1c-2.7-0.3-5.2-1.3-6.8-2.8c-1.5-0.2-2.9-0.4-4.4-0.4c-1.5,0.1-2.9,0.2-4.3,0.4l-1.7-1.1c-2-0.2-3.4,0.3-4.8,0.6
			c-1.6,0.4-3.2,0.7-4.8,0.9c-2.5,0.1-5,0.7-7.1,1.7h-4.3l-2.3-1l-7.1,0.3c-1.9-0.9-4.6-0.5-6.9-0.8c-2.4-0.4-4.8,0.2-7.4-0.4
			c-3,1.3-7.1,1.8-10.8,2.7c-2.2-0.3-4.6-0.2-6.7,0.4c-1.5,0.4-3.1,0.4-4.7,0.1c-2.1-0.4-4.1-0.9-5.4-1.2l-6.6,0.6l-1.9,2.2
			c-4,0.8-7.7,0.4-11.4-0.1l-6.5,1.4c-1.5,0.4-3.1,0.8-4.8,1c-4.9,0.5-9.7,1.3-14.4,2.4c-2,0.4-3.7,0.9-3.9,2.1
			c-0.1,0.4-0.8,0.7-1.3,1.1c-0.1,0.1-0.4,0-0.8,0.1c-0.3-0.3-0.6-0.6-0.9-0.9l-2.7-0.5c-2.6,0.7-5.8,0.5-7.7,1.6
			c-0.5,0.7-1,1.4-1.5,2c-2.2-0.3-4.5,0-6.2-0.8c-0.5,0.2-1,0.5-1.5,0.5c-3.1,0-5.6,0.5-7.5,1.8c-1,0.6-2.5,0.8-3.7,0.3
			c-0.6-0.2-1.3-0.4-2.3-0.7l-12.5,0.9l-1.6-0.9l-2.1,0.3l-1.4,1.1c0.4,0.7,0.8,1.3,1.2,1.9l-0.4,0.1l-2.9-1.4l-2.5,0.3l-3.1-0.7
			l-5.2,0.7l-1.5-0.6c-3.4,1.4-6.9,0.9-10.6,0.8c-1.3,1.2-3.5,1-5.8,0.6c-1.1-0.2-2.1-0.5-3.1-0.7c-3.7-0.8-5.4-0.5-7.7,1.2
			l-4.6,0.8l1,0.5l-4.4,1.6l0.8,1.2c-3.2-1-5.7-0.2-8.3,0c-0.1,0.2-0.2,0.5-0.4,0.8l-3.1-0.6l-1.8,0.7l-2.7-0.4l-8.3,2.5l-7.5,0.3
			l-11.6-3.9c-3.6,0.6-5.7-0.9-8.2-1.6c-1.8-0.6-3.2-1.6-5-2.4c-2.1,0.4-4.5,0.6-6.5,1.2c-3.3,1-7,1.2-10.9,1.6
			c-0.3,0.1-0.9,0.4-1.4,0.6s-1.2,0.4-1.7,0.6c-3.7-0.2-4.6,0.3-4.1,2c0,0.2,0,0.4-0.1,0.6l-2.2-0.3c-1,0.4-1.8,1-3.3,0.5
			c0.1-0.3,0.1-0.5,0.2-0.9c-2.2,0.4-3.3,1-3.2,2.2c-2.9-0.3-5.9-0.1-8.7,0.7c-2,0.6-4,1.2-6.2,1.6c-2,0.3-3.9,0.9-5.6,1.7
			c-2.9-0.6-5.5-1.1-8.4-0.6c-0.8,0.2-1.6,0.4-2.4,0.4c-2.8-0.2-5.1,0.4-7.3,1c-1.5-0.1-2.9-0.3-4.4-0.6c-1-0.3-2-0.4-3.1-0.4
			c-1.6,0-3.1,0.1-4.6,0.4c-2,0.3-4.4,0.1-6.7,0.1c-2.4-1.3-5.8-1.2-8.9-1.6l-1.2,0.7c-1.3-1-4.3-0.8-5.2-2c-1.8,0.8-5-0.3-6,1.4
			l-2.9-1l-1.1,0.7c-2.6,0.2-5.1,0.8-7.1-0.3l-1.5,0.9l-5.2,0.5l-4.4-0.7c-4.4,1.2-9.3,0.4-13.9,0.9l-2,1.5l-3.9,0.9l-3.8-0.6
			l-2.7,1.5h-13.1l-2.4,1.5c0.1-0.5,0.2-0.8,0.2-1.1l-1.5-0.3l-0.7,0.8c-0.3-0.4-0.5-0.6-0.7-0.9l-3.2-0.7
			c-6.3,0.8-10.9-0.6-15.4-2.6l-3.3,0.7l-3.2-0.8l-3.8,1.6c-0.6,1.7-1.7,2.5-4,2.7c-0.8,0.1-1.8-0.2-2.5-0.1
			c-4.4,0.9-7.5-1.1-11.4-1.3c-1-1.1-3.5-1.2-5.1-2c-1.4,0.5-2.8,1-4.1,1.3c-1.3,0.3-2.8,0.5-4.2,0.8l-0.2-0.2l-2.1,1.2
			c-0.2,0.9-0.4,1.8-0.6,2.7c-2.4,0.6-4.5-0.6-6.8,0c0-0.5,0.1-0.9,0.1-1.2l-11-1.4l-2.7,1.5c-6.7-1-6.7-1-10.1-0.5l-13-1.4
			l-1.7,0.8l-2.4-0.8l-6.7,2l0.3,0.9c-1.5,0.7-3.3,0.4-4.7,0.3l-1.5,0.6c-3.5-0.8-6.3-0.7-7.9,0.4c-1.8,1.3-4.3,1.7-7.1,1.9l-2.4,2
			c-1.7-0.1-3.1,0-4.4-0.1c-1.8-0.1-3.7,0-5.4,0.5c-3.8,1-7.5,2.1-11.3,3.1c-3.4-0.8-7-0.2-10.1-0.4c-1.9,0.5-3.8,1.1-5.7,1.8
			c-1.6,0.8-3,1.6-4.4,2.6c-0.4-0.6-0.6-0.9-0.9-1.3c-0.2-0.3-0.5-0.7-1-1.2c-2.5,1.7-6.7,0.1-9.3,1.4c0,0.4,0.1,0.8,0.1,1.1l-9,0.7
			c-1.9,0.6-3.7,1-5.7,1.4c-2.9,0.5-5.6,1.2-8.7,1.4c-0.6,0.1-1.1,0.2-1.6,0.4c-3.1,0.9-6.6,1.1-9.8,1.8c-0.7,0.1-1.3,0.1-2,0.1
			c-1.9-1.1-4.3-1.7-6.8-1.5c-0.6,0-1.2-0.1-1.8-0.2c-2.1-0.6-4.3-0.5-6.6-0.4l-3.1,1l-3.3-1.4c-1.8,0-4.1,0.3-6-0.5l-1.2-1.7h-3.8
			l-4.2,1.4c-2.4-0.4-4.5-1.1-6.4-1c-1.7,0-3.4-0.1-5.1-0.2c-1,0-2-0.1-3-0.1c-1.6-0.7-3.4-1.1-5.3-1.2c-1.2-0.1-2.3-0.4-3.3-0.8
			c-2.2-0.8-4.7-1.3-7.2-1.5c-2.2-0.2-3.9-0.4-5.4-1.2c-0.7-0.4-1.6-0.7-2.5-0.7c-3.2-0.2-6.4-0.4-9.6-0.4c-2.3,0-4.7,0.2-7.6,0.4
			c-1.2,0.7-2.9,1.5-4.4,2.4c-2.6,1.7-6.2,2.1-10.2,2.1c-0.6,0-1.2-0.1-1.7-0.3c-3.8-1.5-4.5-1.6-7.3-1.7l-5.8-1.8
			c-3.6,0.6-7.6-0.3-10,1.2l-2-0.1c-2.5,1.4-4.9,2.7-8.5,3.2l-2.5-1.4c-1.8-0.2-3.6-0.2-5.4-0.2c-1.9,0.2-3.6,0.4-5.2-0.2l-3.2,0.6
			l-2,1.6c-3.4,0.6-7.2,0.3-10,1.7l-2.8-1.3c-2.1,1.2-6.3,0.6-7.2,2.1c-3.6,0.9-7.4,1.6-11.3,2c-1.4,0.2-2.9,0.3-4.4,0.1
			c-2.9-0.4-6-0.4-9-0.8c-4.1-0.5-8,0-12,0.1c-1.5,0.1-2.9,0.4-4.2,0.9c-2.1-0.6-3.4,0.1-4.5,0.8c-2.2-0.6-4.5-1-6.8-1.2
			c-1.8-0.1-3.5,0.1-5.1-0.1c-2.6-0.3-5-0.9-7.8-1.5c-1.1,0.1-2.2,0.1-3.3,0.1c-1-0.2-2-0.6-2.8-1l-2.4,0.1c0.1-0.4,0.1-0.7,0.2-1
			c-3.7,0.2-7.2,0.6-9.2,2.1c-2.2,0.4-4.3,0.6-5.9,1.1c-1.6,0.5-2.7,1.4-4.2,2.2l-2.9-0.2l-3.2,0.8l-8.4-1.5l-6.4,0.9
			c-0.8-0.2-1.7-0.4-2.6-0.4c-3,0.4-6,0.2-9,0.8c-3.2,0.7-6.5,0.8-9.8,0.4c-2.4-0.3-4.8-0.8-7-1.2l-3.4,0.9l-4.7-0.3l-2-1.2
			l-6.8,0.9c-4.8-1.7-10-1.8-15.6-0.6l-9.5-2.9c-0.3-0.6-0.5-1-0.7-1.3l-8.1-0.8c-1,1.2-3.7,1.5-5.4,2.4c-0.9,0.5-2.4,0.3-3.5-0.1
			c-0.7-0.2-1.2-0.6-2-0.8c-1.2-0.3-2.6-0.8-3.7-0.7c-3.5,0.4-7.7,0.3-9.2,2.6c-6.5-0.4-12.4-0.1-17.2,2.1l-3.7-0.7
			c-3.6,0.3-7,0.5-10.5,0.7c-1,0-1.9,0-2.9-0.1c-2.4-0.3-2.3-0.3-4.2,0.9c0.1,0.2,0.2,0.5,0.3,0.7s0.1,0.4,0.2,0.8l-3.2-1
			c-0.7,0.1-1.6,0.4-2.1,0.3c-2.9-0.6-5.6,0.1-8.3,0.3l-1.1-0.3L0,109.6l0,2.4H1440z M238.3,105.6l-0.2,0.1v-0.1L238.3,105.6z
			 M842.7,74.9c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1,0-0.2-0.1-0.3-0.2c0.2,0,0.4-0.1,0.6-0.1C842.6,74.8,842.7,74.9,842.7,74.9z
			 M1023.5,63.1h-0.8v-0.1h0.8V63.1z M1027.3,60.7l-0.1,0.1l-0.1-0.1L1027.3,60.7z"
    />
  </StyledSvg>
);

export default BrushStroke4;
