import React from 'react';
import BrushStroke1 from '../../../assets/svg/brush-stroke-1';
import BrushStroke2 from '../../../assets/svg/brush-stroke-2';
import BrushStroke3 from '../../../assets/svg/brush-stroke-3';
import BrushStroke4 from '../../../assets/svg/brush-stroke-4';
import BrushStroke5 from '../../../assets/svg/brush-stroke-5';
import BrushStroke6 from '../../../assets/svg/brush-stroke-6';
import BrushStroke7 from '../../../assets/svg/brush-stroke-7';
import BrushStroke8 from '../../../assets/svg/brush-stroke-8';
import StyledDivider from './style';

interface Props {
  variant: number;
  color: string;
  flipX?: boolean;
  flipY?: boolean;
  offsetY?: number;
}

const STROKE_VARIANTS = {
  1: BrushStroke1,
  2: BrushStroke2,
  3: BrushStroke3,
  4: BrushStroke4,
  5: BrushStroke5,
  6: BrushStroke6,
  7: BrushStroke7,
  8: BrushStroke8,
};

const BrushStroke: React.StatelessComponent<Props> = ({ variant, color, flipX, flipY, offsetY }) => {
  const StrokeVariant = STROKE_VARIANTS[variant];

  return (
    <StyledDivider offsetY={offsetY} marginBottom={offsetY}>
      <StrokeVariant color={color} flipX={flipX} flipY={flipY} />
    </StyledDivider>
  );
};

export default BrushStroke;
