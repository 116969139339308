import React from 'react';
import { withTheme } from 'styled-components';
import Image from '../../components/Image';
import { ColumnBlockType, PostType, ThemeType } from '../../types';
import StyledColumnBlock, { ImageWrapper, PostLink, PostList } from './styles';
import { slugify } from '../../utils';

interface Props extends ColumnBlockType, ThemeType {
  items: PostType[];
}

export const PostLinks: React.FunctionComponent<Props> = ({ title, cta, subTitle, items, theme }) => (
  <StyledColumnBlock id={title && slugify(title)} title={title} cta={cta} subTitle={subTitle} type="small" titleColor={theme.colors.green}>
    <PostList>
      {items.map(({ slug, featuredImage, title: postTitle }, key) => (
        <PostLink key={key} href={slug} data-test="PostLink">
          <ImageWrapper>
            <Image className="post-image" {...featuredImage} />
          </ImageWrapper>
          <h4 className="h6 post-title">{postTitle}</h4>
        </PostLink>
      ))}
    </PostList>
  </StyledColumnBlock>
);

export default withTheme(PostLinks);
