import React from 'react';
import { PageLinkType } from '../../types';
import SiteMapWrapper, { PrimaryLink, SecondaryLink } from './styles';

interface Props {
  items: PageLinkType[];
}

const SiteMap: React.FunctionComponent<Props> = ({ items }) => (
  <SiteMapWrapper>
    {items &&
      items.map(({ title, url, wordpress_children: sublinks }, index) => (
        <div key={index}>
          <PrimaryLink href={url}>
            <span className="h9">{title}</span>
          </PrimaryLink>
          {sublinks &&
            sublinks.map((sublink: PageLinkType, sublinkIndex) => (
              <SecondaryLink key={sublinkIndex} href={sublink.url}>
                <span className="h9">{sublink.title}</span>
              </SecondaryLink>
            ))}
        </div>
      ))}
  </SiteMapWrapper>
);

export default SiteMap;
