import React from 'react';

import { MenuType, PageLinkType } from '../../types';
import SlideMenu from '../SlideMenu';
import { SECTION_THEMES, SOCIALS } from '../../constants';
import PageWrapper, { MenuItem, MenuWrapper, Link } from './styles';
import SocialLinks from '../SocialLinks';

interface Props extends MenuType {
  items: PageLinkType[];
  openSubscribeMenu: () => void;
}

const MainMenu: React.FunctionComponent<Props> = (props) => {
  return (
    <SlideMenu right={true} showSubscribe={true} {...props} showOverspray={true} theme={SECTION_THEMES.PURPLE}>
      <PageWrapper>
        <MenuWrapper>
          {props.items &&
            props.items.map(({ title, url, wordpress_children: sublinks }, index) => (
              <MenuItem key={index}>
                <Link href={url}>
                  <span className="h4 main-link">{title}</span>
                </Link>
                {sublinks && (
                  <div className="sublinks">
                    {sublinks.map((sublink: PageLinkType, sublinkIndex) => (
                      <Link key={sublinkIndex} href={sublink.url}>
                        <span className="h7">{sublink.title}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </MenuItem>
            ))}
        </MenuWrapper>
        <SocialLinks links={SOCIALS} size="lg" />
      </PageWrapper>
    </SlideMenu>
  );
};

export default MainMenu;
