import styled from 'styled-components';
import { ThemeType } from '../../types';

export const StyledHits = styled.div(
  ({ theme: { colors, mobileView } }) => `
    overflow-y: scroll;
    height: calc(100vh - 280px);    
    padding-bottom: 32px;

    ${mobileView} {
      height: calc(100vh - 192px);    
    }

    ul {
      list-style:none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 24px 120px 24px 0;
        position: relative;

        ${mobileView} {
          padding: 12px 60px 12px 0;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          opacity: 0.5;
          height: 1px;
          width: 100%;
          background: ${colors.white};
        }
      }
    }

    em {
      background: ${colors.babyPink};
    }
`,
);

export const SearchWrapper = styled.div(
  ({ theme: { fontSizes, mobileView, colors, spacing } }: ThemeType) => `
    width: 100%;
    max-width: 1152px;
    margin: ${spacing.x8} auto 0;

    ${mobileView} {
      margin: 0 auto 0;
    }

    .ais-SearchBox {
      margin: 0 auto ${spacing.x6};
      max-width: 672px;
      position: relative;
      font-size: ${fontSizes.sm}
      outline: none;

      .ais-SearchBox-form {
        position: relative;
      }

      .ais-SearchBox-input {
        outline: none;
        font-size: ${fontSizes.sm};
        appearance: none;
        padding: 12px 0;
        width: 100%;
        position: relative;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid ${colors.white};
        color: ${colors.white};
        border-radius: 0;

        &:focus {
          outline: 0;
        }

        ::-webkit-search-cancel-button{
          display: none;
        }

        &::placeholder {
          color: ${colors.white};
        }

        &:placeholder-shown + .ais-SearchBox-submit {
          transform: translateY(-50%) scale(1);
        }

        &:placeholder-shown + .ais-SearchBox-reset {
          transform: translateY(-50%) scale(0);
        }
      }

      .ais-SearchBox-submit, 
      .ais-SearchBox-reset {
        appearance: none;
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        top: 50%;
        border: none;
        background: transparent;
        color: ${colors.white};
        font-size: 1em;
        right: 0.1em;
        will-change: transform;
        transition: transform 0.2s cubic-bezier(0.42, 0.18, 0.11, 1.31);

        .ais-SearchBox-submitIcon {
          width: 18px;
          height: 18px;
        }

        .ais-SearchBox-resetIcon {
          width: 14px;
          height: 14px;
        }
        
        .ais-SearchBox-submitIcon, 
        .ais-SearchBox-resetIcon {
          fill: currentColor;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .ais-SearchBox-submit {
        transform: translateY(-50%) scale(0);
      }

      .ais-SearchBox-reset {
        transform: translateY(-50%) scale(1);
      }
    }
  `,
);
export const PostName = styled.h6(
  ({ theme: { fontSizes } }) => `
    text-transform: none;
    font-size: ${fontSizes.sm};
  `,
);

export const PostType = styled.span(
  ({ theme: {} }) => `
    display: inline-block;
    margin: 0 0.5em;
    font-size: 0.9em;
    font-style: italic;
    opacity: 0.8;
  `,
);

export const SearchHeading = styled.h4(
  ({ theme: {} }) => `
    margin: 1em 0 0.5em;
    text-transform: none;
    font-weight: 600;
  `,
);
