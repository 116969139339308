import React from 'react';
import { ICONS } from '../../constants';
import { IconType, IconSizeType } from '../../types';
import StyledAnchor from './styles';

interface Props {
  type: IconType;
  href: string;
  size?: IconSizeType;
}

const IconLink: React.FunctionComponent<Props> = ({ type, href, size }) => {
  const Component: React.FunctionComponent<any> = ICONS[type];
  return (
    <StyledAnchor href={href} target="_blank" data-test="Link">
      <Component size={size} />
    </StyledAnchor>
  );
};

export default IconLink;
