import React from 'react';
import Carousel, { CarouselBlock, CarouselSize } from '../../components/Carousel';
import { PostType, ColumnBlockType } from '../../types';
import ColumnBlock from '../Block';

interface Props extends ColumnBlockType {
  items?: PostType[];
  news?: PostType[];
  carouselSize?: CarouselSize;
  carousel: boolean;
}

export const FeaturedNews: React.StatelessComponent<Props> = ({
  type = 'medium',
  carouselSize = 'small',
  title,
  cta,
  news,
  items,
  carousel = true,
}) => {
  items = items || news || [];
  const Block: React.FunctionComponent<ColumnBlockType> = carousel ? CarouselBlock : ColumnBlock;

  if (!carousel) {
    items = items.filter((_, index) => index < 4);
  }

  return (
    <Block title={title} cta={cta} type={type}>
      {items && <Carousel size={carouselSize} items={items} carousel={carousel} />}
    </Block>
  );
};

export default FeaturedNews;
