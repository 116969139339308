import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-green.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const GreenSplatter1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} bottom={bottom} left={0} flipX={true} width={63.75} height={45.0} image={SplatterImage} />
);

export default GreenSplatter1;
