import styled from 'styled-components';
import { ThemeType } from '../../types';
import { FrontTicket } from '../../components/Ticket/styles';

export default styled.div(
  ({ theme: { mobileView, screenWidths } }: ThemeType) => `
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 80px;

    ${mobileView} {
      flex-wrap: wrap;
      justify-content: center;         
      padding-bottom: 0;
    }

    &.visible ${FrontTicket} {  
      @media (min-width: 926px) {
        transform: translateX(0) translateY(0) rotate(0) !important;
      }

      &:not(:last-of-type) {
        @media (max-width: ${screenWidths.tablet}) and (min-width: 1081px) {
          margin-right: -50px;
        }

        @media (max-width: 1080px) and (min-width: 926px) {
          margin-right: -125px;
        }
      }
    }
`,
);
