import React from 'react';
import { CONTENT_BLOCKS, MODALS } from '../../constants';
import { hideAnnouncement, shouldShowAnnouncement } from '../../localstorage/announcement';
import onReady from '../../utils/onReady';
import onReadyUnmount from '../../utils/onReadyUnmount';
import isHeadless from '../../utils/isHeadless';
import { PageDataType } from '../../types/Page';

class AbstractPage extends React.Component<PageDataType> {
  static getDerivedStateFromProps(props, prevState) {
    const derivedState = {
      ...prevState,
      headless: isHeadless(),
    };

    if (props.announcement) {
      derivedState.announcement = props.announcement;
    }

    return derivedState;
  }

  state = {
    announcement: null,
    loaded: false,
    headless: isHeadless(),
    showModal: false,
  };

  results = {};

  constructor(props) {
    super(props);

    this.hideAnnouncement = this.hideAnnouncement.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    onReady(this.handleLoad);
  }

  handleLoad() {
    this.setState({
      loaded: true,
    });
  }

  componentWillUnmount() {
    onReadyUnmount(this.handleLoad);
  }

  pageHasAnnouncement() {
    const { announcement, loaded } = this.state;

    return loaded && !!announcement && shouldShowAnnouncement(announcement);
  }

  hideAnnouncement() {
    const { announcement } = this.state;

    if (announcement !== null) {
      hideAnnouncement(announcement);
    }

    this.setState({ announcement: null });
  }

  renderBlock({ type, data }, index?: number) {
    const contentBlock = CONTENT_BLOCKS[type];

    if (!contentBlock) {
      return;
    }

    const Block: React.StatelessComponent = contentBlock;

    return <Block key={index} {...data} />;
  }

  renderChildren() {
    return <></>;
  }

  render() {
    return <></>;
  }
}

export default AbstractPage;
