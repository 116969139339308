import styled from 'styled-components';
import { HeaderContent } from '../styles';
import { ThemeType } from '../../../types';
import IconButton from '../../IconButton/styles';

export const StyledPrimaryHeader = styled.header(
  ({ theme: { spacing, colors, mobileView } }: ThemeType) => `
    width: 100%;
    background-color: ${colors.black};
    color: ${colors.white};
    position: relative;
    z-index: 300;
    transition: margin-top 0.3s ease-in-out 0.3s;

    ${mobileView} {
      margin-top: -37px !important;

      button {
        display: none;
      }
    }

    ${HeaderContent} {
      padding: ${spacing.x1} ${spacing.x6};
    }

    ${IconButton} {
      &:before {
        top: -3px;
        bottom: -4px;
      }
    }
  `,
);
