import styled from 'styled-components';

export default styled.footer(
  ({ theme: { colors } }) => `
    width: 100%;
    background-color: ${colors.black};
    color: ${colors.white};

    letter-spacing: 0.5px;

    .subcontent {
      opacity: 0.5;
    }
`,
);

export const ContentWrapper = styled.div(
  ({ theme: { mobileView, spacing, width } }) => `
    max-width: ${width.default};
    margin: 0 auto;
    padding: ${spacing.x6} ${spacing.x6} ${spacing.x2};

    ${mobileView} {
      padding: ${spacing.x6} ${spacing.x3} ${spacing.x16};
    }

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 
    ". . back-to-top"
    "site-map site-map site-map"
    "partners partners partners"
    "copyright social-links site-links"
    "credits credits credits";

    ${mobileView} {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 
      "site-map back-to-top"
      "partners partners"
      "social-links social-links"
      "copyright copyright"
      "site-links site-links"
      "credits credits";
    }

`,
);

export const BackToTop = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    grid-area: back-to-top;
    padding-bottom: ${spacing.x2};
    
    ${mobileView} {
      padding-bottom: 0;
      display: none;
    }

    & > * {
      float: right;
    }
`,
);

export const Partners = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    grid-area: partners;
    padding-bottom: ${spacing.x8};
    > div{
      display: grid;  
      grid-template-columns: repeat(auto-fit, 120px);
      grid-gap: ${spacing.x3};
      justify-items: center;
      align-items: center;
      justify-content: center;	

      ${mobileView} {
        padding-bottom: ${spacing.x6};
        grid-template-columns: repeat(3, 1fr);
      }

      &:hover a {

      }

      a {
        display: block;
        width: 100%;
        padding: 7%;
        transition: padding .3s;
        &:hover {
          padding: 0;
        }
      }
    }
  `,
);

export const SiteMapWrapper = styled.div(
  ({ theme: { spacing } }) => `
  grid-area: site-map;
  padding-bottom: ${spacing.x8};
`,
);

const ContentBlock = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    padding-bottom: ${spacing.x6};
    ${mobileView} {
      padding-bottom: ${spacing.x5};
    }
`,
);

export const Copyright = styled(ContentBlock)(
  ({ theme: { mobileView, spacing } }) => `
    grid-area: copyright;
    display: flex;

    & > *:first-child {
      padding-bottom: ${spacing.x2};
    }

    ${mobileView} {
      justify-content: center;
      align-content: center;
      align-items: center;
    }
`,
);

export const SiteLinks = styled(ContentBlock)(
  ({ theme: { mobileView, spacing } }) => `
  grid-area: site-links;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: start;

  & > *:not(:first-child) {
    margin-left: ${spacing.x2};
    ${mobileView} { margin-left: 0; }
  }

  ${mobileView} {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
`,
);

export const SocialLinksWrapper = styled(ContentBlock)`
  grid-area: social-links;
  margin: 0 auto;
`;

export const Credits = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    grid-area: credits;
    margin: 0 auto;
    display: flex;

    .separator {
      margin: 0 ${spacing.x1};
    }

  ${mobileView} {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    span:first-child {
      padding-bottom: ${spacing.x4};
    }

    .separator {
      display: none;
    }
  }
`,
);
