import styled from 'styled-components';
import { ThemeType } from '../../types';
import { RowBlock } from '../Block';
import { Column, Container } from '../Block/styles';

export default styled(RowBlock)(
  ({ theme: { mobileView } }: ThemeType) => `
    ${Container} {
      ${mobileView} {
        flex-direction: column;
      }
    }

    ${Column} {      
      &:first-of-type {
        flex: 1;     
        
        h2 {
          margin-bottom: 48px;
        }
      }

      &:nth-of-type(2) {
        flex: 0;
      }
    }
`,
);

export const LinkWrapper = styled.div(
  ({ theme: { colors, mobileView } }) => `
    a {
      display: inline-flex;
      align-items: center;
      color: ${colors.green};
    }

    ${mobileView} {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      z-index: 10;
      justify-content: center;
    }
  `,
);

export const Label = styled.span(
  ({ theme: { linearGradient } }: ThemeType) => `
    background-image: ${linearGradient.blueGreenRight};
    padding-left: 8px;
`,
);
