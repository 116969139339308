import React from 'react';
import { SectionType } from '../../types';
import Content from './Content';
import ContentInner from './ContentInner';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import StyledSection from './style';

class Section extends React.PureComponent<SectionType> {
  static Header = Header;
  static Footer = Footer;
  static Content = Content;
  static ContentInner = ContentInner;
  static Sidebar = Sidebar;

  render() {
    return <StyledSection>{this.props.children && this.props.children}</StyledSection>;
  }
}

export default Section;
