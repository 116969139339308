import React from 'react';
import Accordion from '../../components/Accordion';
import { AccordionType } from '../../types';
import { slugify } from '../../utils/slugify';
import ColumnBlock from '../Block';
import Wrapper from './styles';

interface Props {
  title: string;
  faqs?: AccordionType[];
  accordionItems?: AccordionType[];
}

export const AccordionBlock: React.FunctionComponent<Props> = ({ title, accordionItems, faqs }) => {
  accordionItems = accordionItems || faqs;

  return (
    <ColumnBlock title={title} type="small">
      <Wrapper id={title && slugify(title)}>
        {accordionItems && accordionItems.map((data, index) => <Accordion key={index} {...data} data-test="Accordion" />)}
      </Wrapper>
    </ColumnBlock>
  );
};

export default AccordionBlock;
