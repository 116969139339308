import React from 'react';
import StyledLogo from './styles';

const Logo: React.FunctionComponent = () => (
  <StyledLogo className="tk-alfarn" href="/">
    #SITG2019
  </StyledLogo>
);

export default Logo;
