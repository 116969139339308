import React, { useState } from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, isFuture } from 'date-fns';
import Banner, { Content, FooterBanner, Title, SubscribeWrapper, Button } from './styles';
import { CloseIcon } from '../../icons';
import AnnouncementType from '../../types/Announcement';
import SubscriptionForm from '../SubscriptionForm';
import { useInterval } from '../../hooks';
import { shouldShowAnnouncement } from '../../localstorage';

interface AnnouncementComponentType extends AnnouncementType {
  hide: () => void;
}

const Countdown: React.StatelessComponent<{ endDate: string }> = ({ endDate }) => {
  const getDistance = (end, start) => ({
    days: differenceInDays(end, start),
    hours: differenceInHours(end, start) % 24,
    minutes: differenceInMinutes(end, start) % 60,
  });

  const [distance, setDistance] = useState(getDistance(new Date(), endDate));
  useInterval(() => {
    setDistance(getDistance(endDate, new Date()));
  }, 1000);

  return (
    <span>
      {distance.days} days {distance.hours} hours {distance.minutes} mins
    </span>
  );
};

class FooterCTA extends React.PureComponent {
  state: any;
  props: any;

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
  }

  handleFilter(e) {
    this.setState({ showForm: true });
  }

  render() {
    const { subscribe, cta, hide, wordpress_id } = this.props;

    return (
      <FooterBanner showForm={this.state.showForm}>
        {subscribe && (
          <div>
            <input type="checkbox" id="reveal-email" role="button" onChange={(e) => this.handleFilter(e)} />
            <Title htmlFor="reveal-email">{cta.text}</Title>
            <SubscribeWrapper>
              <SubscriptionForm formId={`_form_${wordpress_id}`} />
            </SubscribeWrapper>
          </div>
        )}
        {!subscribe && cta.link && (
          <div>
            <a href={cta.link.url} target={cta.link.target} onClick={hide}>
              <Title>{cta.text}</Title>
            </a>
          </div>
        )}
        <button type="unstyled" onClick={hide}>
          <CloseIcon size="sm" />
        </button>
      </FooterBanner>
    );
  }
}

const countdownInFuture = (countdown) => !countdown || !countdown.showCountdown || isFuture(countdown.endDate);

const AnnouncementBanner: React.StatelessComponent<AnnouncementComponentType> = ({
  content,
  countdown,
  cta,
  hide,
  template,
  subscribe,
  wordpress_id,
}) => {
  switch (template) {
    case 'footerCta':
      return (
        <>
          {shouldShowAnnouncement({ content, countdown, cta, wordpress_id }) && (
            <FooterCTA hide={hide} cta={cta} subscribe={subscribe} wordpress_id={wordpress_id} />
          )}
        </>
      );
    default:
      return (
        <>
          {countdownInFuture(countdown) && shouldShowAnnouncement({ content, countdown, cta, wordpress_id }) && (
            <Banner>
              <div className="h7">
                <Content dangerouslySetInnerHTML={{ __html: content }} />
                {cta.link && (
                  <Button href={cta.link.url} target={cta.link.target} onClick={hide}>
                    {cta.text}
                  </Button>
                )}
                {countdown && countdown.showCountdown && <Countdown endDate={countdown.countdownEnd} />}
              </div>
              <button type="unstyled" onClick={hide}>
                <CloseIcon size="sm" />
              </button>
            </Banner>
          )}
        </>
      );
  }
};

export default AnnouncementBanner;
