import styled from 'styled-components';
import IconLink from '../IconLink';

export default styled.div(
  ({ theme: { mobileView, spacing, fontSizes } }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: ${spacing.x8};
    height: 100%;
    width: 100%;
    overflow: auto;

    & > *:last-child {
      align-self: center;
    }

    ${mobileView} {
      display: block;
      text-align: center;
      padding-bottom: ${spacing.x2};

      .sublinks { 
        display: none; 
      }

      .main-link { 
        font-size: ${fontSizes.md}; 
      }

      ${IconLink} {
        svg {
          height: 24px;
        }
      }
    }
  `,
);

export const MenuWrapper = styled.div(
  ({ theme: { spacing, mobileView } }) => `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-template-rows: auto;
    grid-row-gap: ${spacing.x5};
    -webkit-box-pack: center;
    justify-content: center;
    align-content: center;
    flex: 1 1 100%;
    padding: 0px 10vw ${spacing.x4};

    ${mobileView} {
      grid-template-columns: auto;
      padding: ${spacing.x2} 0 ${spacing.x4};
      grid-row-gap: 4vh;
    }
  `,
);

export const MenuItem = styled.div(
  ({ theme: { mobileView } }) => `
    text-align: center;

    .sublinks { 
      opacity: 0.6; 
      transition: opacity 1000ms; 
    }

    &:hover {
      .sublinks { 
        opacity: 1; 
      }
    }

    ${mobileView} {
      .main-link { 
        opacity: 1; 
      }

      .sublinks { 
        opacity: 0.6; 
      }
    }
  `,
);

export const Link = styled.a(
  ({ theme: { spacing, mobileView } }) => `
    display: block;
    padding-bottom: ${spacing.x3};

    ${mobileView} {
      padding-bottom: 0;
    }
  `,
);
