import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { StyledSection } from './styles';
import theme from '../../styles/theme';

const Footer: React.FunctionComponent<any> = ({ children, natural }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content natural={natural}>
      <CSSParallax.Dividers.BrushStroke variant={3} color={theme.colors.black} flipX={true} offsetY={-6} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
    </CSSParallax.Content>
  </StyledSection>
);

export default Footer;
