import styled from 'styled-components';
import { default as ImageComponent } from '../../components/Image';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  hasMobile: boolean;
}

export const DesktopImage = styled(ImageComponent)(
  ({ theme: { mobileView }, hasMobile }: Props) => `
  ${
    hasMobile
      ? `
    ${mobileView} {
      display: none;
    }
  `
      : ''
  }

`,
);

export const MobileImage = styled(ImageComponent)(
  ({ theme: { mobileView } }) => `
  display: none;

  ${mobileView} {
    display: block;
  }
`,
);
