import styled from 'styled-components';
import Theme from '../../types/Theme';

interface ModalProps extends Theme {
  visible: boolean;
}

const Outer = styled.div(
  ({ theme, visible }: ModalProps) => `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.colors.black75};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: ${visible ? 1 : 0};
    pointer-events: ${visible ? 'auto' : 'none'};
    z-index: 999;
  `,
);

const Inner = styled.div(
  ({ theme }: Theme) => `
    width: 100%;
    max-width: 384px;
    background: ${theme.linearGradient.blueGreenBottom};
    padding: 32px 32px 40px 32px;
    color: ${theme.colors.black}
    max-height: calc(100vh - 40px);
    overflow: auto;
    position: relative;

    .close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      height: 24px;
    }
`,
);

const Heading = styled.h2(
  ({ theme }: Theme) => `
    line-height: 1;
    border-bottom: 1px solid ${theme.colors.black};
    font-size: 20px;
    padding-bottom: 32px;
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
  `,
);

export const SocialButton = styled.button(
  ({ theme }: Theme) => `
    -webkit-appearance: none;
    border: none;
    width: 100%;
    background-color: ${theme.colors.black}
    display: flex;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.xs};
    padding: 12px;
    text-decoration: none !important;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    margin-bottom: 16px;
    line-height: 1;
    align-items: center;
    cursor: pointer;
    
    span {
      padding-left: 16px;      
    }

    &:hover {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
    }
  `,
);

export const Terms = styled.p(
  ({ theme }: Theme) => `
    font-size: 14px;
    text-align: center;
    margin: 0;

    a {
      text-decoration: underline;
      transition: color .2s ease-in-out;

      &:hover {
        opacity: .6;
      }
    }
  `,
);

export default { Inner, Outer, Heading };
