import styled from 'styled-components';
import { StyledSectionFooterType } from '../../../types';

export const StyledFooter = styled.div(
  ({ paddingTop, marginTop, marginBottom }: StyledSectionFooterType) => `
    position: relative;
    width: 100%;
    height: 0;
    overflow: visible;
    display: block;
    z-index: 100;
    ${paddingTop ? `padding-top: ${paddingTop};` : ''}
    ${marginTop ? `margin-bottom: ${marginTop};` : ''}
    ${marginBottom ? `margin-bottom: ${marginBottom};` : ''}
    pointer-events: none;
  `,
);
