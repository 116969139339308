import React from 'react';
import HeroBlock from '../../blocks/Hero';
import ParallaxPage from '../../layout/components/ParallaxPage';
import PinkSandscapeSection from '../../sections/PinkSandscape';
import PurpleSection from '../../sections/Purple';
import TallBlackSection from '../../sections/TallBlack';
import theme from '../../styles/theme';
import FeaturedNews from '../../blocks/FeaturedNews';
import { FeaturedPostBlock } from '../../blocks';

class NewsPage extends ParallaxPage {
  constructor(props) {
    super(props);
  }

  renderChildren() {
    const { title, footerBlocks, news = [], featuredPosts = [] } = this.props.content;

    const footerBlock = footerBlocks[footerBlocks.length - 1];

    const { moreNews, otherNews } = news.reduce(
      (acc, post, index) => {
        index <= 3 ? acc.moreNews.push(post) : acc.otherNews.push(post);
        return acc;
      },
      { moreNews: [], otherNews: [] },
    );

    return (
      <>
        {title && (
          <PinkSandscapeSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()}>
            <HeroBlock title={title} textColor={theme.colors.green} />
          </PinkSandscapeSection>
        )}

        <TallBlackSection isFirstSection={!title} pageHasAnnouncement={this.pageHasAnnouncement()} noFooterBlocks={!footerBlock}>
          {featuredPosts.map((post, index) => (
            <FeaturedPostBlock key={`featured-${index}`} alignment={index % 2 ? 'right' : 'left'} item={post} />
          ))}

          {moreNews.length && <FeaturedNews title="More News" items={moreNews} carousel={false} />}

          {otherNews.map((post, index) => (
            <FeaturedPostBlock key={`news-${index}`} alignment={index % 2 ? 'left' : 'right'} item={post} />
          ))}

          {footerBlocks &&
            footerBlocks.map((block, index) => {
              if (index < footerBlocks.length - 1) {
                return this.renderBlock(block, index);
              }
            })}
        </TallBlackSection>

        {footerBlock && <PurpleSection>{this.renderBlock(footerBlock)}</PurpleSection>}
      </>
    );
  }
}

export default NewsPage;
