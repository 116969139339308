import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-blue-2.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const BlueSplatter2: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom, left, right, flipX }) => (
  <StyledGreeble top={top} right={right} left={left} bottom={bottom} flipX={flipX} width={13.15} height={60.0} image={SplatterImage} />
);

export default BlueSplatter2;
