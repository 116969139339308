import React from 'react';
import StyledOverspray from './styles';

interface Props {
  height: string;
}

const Overspray: React.FunctionComponent<Props> = (props) => <StyledOverspray {...props} />;

export default Overspray;
