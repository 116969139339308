import React from 'react';
import GalaxyHoleImage from '../../../assets/images/galaxy-hole.png';
import WhiteStarsImage from '../../../assets/images/stars-white.png';
import StyledGreeble, { StyledGalaxyHoleClipper, StyledGalaxyHoleInner } from './style';
import { SizeGreebleType } from './types';

const GalaxyHole: React.StatelessComponent<SizeGreebleType> = ({ size, top, left, right }) => (
  <StyledGreeble width={size} height={size * (232 / 383)} top={top} left={left} right={right} image={GalaxyHoleImage}>
    <StyledGalaxyHoleClipper>
      <StyledGalaxyHoleInner image={WhiteStarsImage} />
    </StyledGalaxyHoleClipper>
  </StyledGreeble>
);

export default GalaxyHole;
