import React from 'react';
import { ColumnBlockType, EmbedType } from '../../types';
import Video from '../../components/Video';
import ColumnBlock from '../Block';

interface Props extends ColumnBlockType {
  video: EmbedType;
}

export const VideoBlock: React.StatelessComponent<Props> = ({ title, cta, video }) => (
  <ColumnBlock title={title} cta={cta} type="small">
    <Video {...video} />
  </ColumnBlock>
);

export default VideoBlock;
