import React from 'react';
import { MenuType } from '../../types';
import { HitComponentType } from '../../types/Search';
import SlideMenu from '../SlideMenu';
import { THEMES } from '../../constants/sections';
import { SearchWrapper, StyledHits, PostType, PostName, SearchHeading } from './styles';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SearchBox, Highlight, Configure, Snippet, connectStateResults } from 'react-instantsearch-dom';

const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APPLICATION_ID, process.env.GATSBY_ALGOLIA_API_KEY, {
  _useRequestCache: true,
});

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== '');
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

const SearchMenu: React.FunctionComponent<MenuType> = (props) => (
  <SlideMenu right={true} {...props} showOverspray={true} theme={THEMES.PURPLE}>
    <SearchWrapper>
      <InstantSearch indexName="wp_searchable_posts" searchClient={searchClient}>
        <Configure hitsPerPage={4} attributesToSnippet={['content_fields:20', 'content:20']} />
        <SearchBox translations={{ placeholder: 'Search' }} />
        <Content />
      </InstantSearch>
    </SearchWrapper>
  </SlideMenu>
);

const Content = connectStateResults(({ searchState, searchResults }) =>
  searchResults && searchResults.nbHits !== 0 && searchState.query ? (
    <>
      <SearchHeading className="h4">Showing results for "{searchState.query}"</SearchHeading>
      <StyledHits>
        <Hits hitComponent={Hit} />
      </StyledHits>
    </>
  ) : searchState.query ? (
    <div>No results has been found for "{searchState.query}"</div>
  ) : null,
);

const Hit: React.FunctionComponent<HitComponentType> = ({ hit }) => (
  <a href={hit.permalink}>
    <PostName>
      <Highlight attribute="post_title" hit={hit} />
      <PostType>({hit.post_type})</PostType>
    </PostName>

    {hit.content_fields ? (
      <div className="description">
        <Snippet attribute="content_fields" hit={hit} />
      </div>
    ) : (
      <div className="description">
        <Snippet attribute="content" hit={hit} />
      </div>
    )}
  </a>
);

export default SearchMenu;
