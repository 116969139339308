import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-pink-2.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const PinkSplatter2: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} left={0} flipX={true} bottom={bottom} width={15.85} height={60.0} image={SplatterImage} />
);

export default PinkSplatter2;
