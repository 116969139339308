import styled from 'styled-components';
import { ThemeType } from '../../../types';

export default styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    position: relative;
    display: block;
    min-height: 13vw;
    width: 100%;

    ${mobileView} {
      min-height: 0;
    }
`,
);
