import styled from 'styled-components';
import { ThemeType } from '../../types';

export default styled.div(
  ({ theme: { colors, mobileView, spacing } }) => `
    padding: ${spacing.x1} ${spacing.x3};
    min-height: ${spacing.x12};
    border-top: 1px solid ${colors.mediumGrey};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${mobileView} {
      min-height: ${spacing.x8};
      padding: ${spacing.x3} 0 ${spacing.x3} ${spacing.x2};
    }
`,
);

export const Session = styled.span(
  ({ theme: { spacing, lineupSeparator } }) => `
    display: inline-block;

    &:not(:last-child) {
      padding-right: ${spacing.x2};
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        right: 6px;
        top: calc(50% - 2px);
        height: 4px;
        width: 4px;
        background-image: ${lineupSeparator};
        background-size: contain;
        background-position: 50% 25%;
        background-repeat: no-repeat;
      }
    }
`,
);

export const Title = styled.h4(
  ({ theme: { spacing }, fade }: ThemeType & { fade: boolean }) => `
    flex: 2;
    margin-right: ${spacing.x1};
    cursor: pointer;
    ${fade ? 'opacity: 0.6' : ''};

    &:hover {
      text-decoration: underline;
    }
`,
);

export const Sessions = styled.div(
  ({ theme: { mobileView } }) => `
    flex: 1;
    
    ${mobileView} {
      display: none;
    }
`,
);

export const IconButton = styled.button(
  () => `
    flex: 1;
    text-align: right;

    svg {
      translate: transform 0.2s ease-in;
    }

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
`,
);
