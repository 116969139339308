import React from 'react';
import ColumnBlock from '../Block';
import { VideosWrapper } from './styles';
import ReactPlayer from 'react-player';
import { CtaType } from '../../types';
import getSafe from '../../utils/getSafe';

interface Video {
  title: string;
  video: {
    url: string;
  };
}
interface Props {
  cta?: CtaType;
  title: string;
  videos: Video[];
}
interface State {
  url: string;
  videoTitle: string;
  activeIndex: number;
  playing: boolean;
}

class Videos extends React.Component<Props, State> {
  player: any;

  constructor(props) {
    super(props);

    const { videos } = props;

    this.state = {
      activeIndex: 0,
      url: getSafe(() => videos[0].video.url),
      videoTitle: getSafe(() => videos[0].title),
      playing: true,
    };
  }

  load = (video, index) =>
    this.setState({
      url: video.video.url,
      videoTitle: video.title,
      activeIndex: index,
      playing: true,
    });

  render() {
    const { title, cta, videos } = this.props;
    const { url, videoTitle } = this.state;

    return (
      <ColumnBlock title={title} cta={cta}>
        {videos && (
          <VideosWrapper>
            <div className="main-video">
              <div className="video-player">
                <ReactPlayer
                  url={url}
                  data-test="Video"
                  light={false}
                  className="react-player"
                  controls={false}
                  playing={false}
                  height="100%"
                  width="100%"
                />
              </div>
              <h3 className="h4">{videoTitle}</h3>
            </div>
            <div className="video-playlist" data-test="Playlist">
              {videos.map((video, index) => (
                <div
                  key={`video-${index}`}
                  onClick={() => this.load(video, index)}
                  className={this.state.activeIndex === index ? 'active' : 'video-link'}
                >
                  <div className="video-poster">
                    <ReactPlayer
                      url={getSafe(() => video.video.url)}
                      light={false}
                      className="react-player"
                      controls={false}
                      playing={false}
                      height="100%"
                      width="100%"
                    />
                  </div>
                  <h4 className="h7">{video.title}</h4>
                </div>
              ))}
            </div>
          </VideosWrapper>
        )}
      </ColumnBlock>
    );
  }
}

export default Videos;
