import React from 'react';
import { ColumnBlockType, ImageType, LinkType } from '../../types';
import ColumnBlock from '../Block';
import { DesktopImage, MobileImage } from './styles';

const hasImage = (image: ImageType) =>
  image && !!(image.url || (image.sizes && Object.values(image.sizes).filter((value) => value).length));

export interface Props extends ColumnBlockType {
  image: ImageType;
  imageMobile?: ImageType;
  imageLink?: LinkType;
}

export const Image: React.StatelessComponent<Props> = ({ title, cta, image, imageMobile, imageLink }) => {
  const hasMobile = hasImage(imageMobile);

  return (
    <ColumnBlock title={title} cta={cta} data-test="BlockHeader" type="small">
      {imageLink && imageLink.url ? (
        <a href={imageLink.url} target={imageLink.target} data-test="ImageLink">
          <DesktopImage {...image} hasMobile={hasMobile} />
          {hasMobile && <MobileImage {...imageMobile} />}
        </a>
      ) : (
        <>
          <DesktopImage {...image} hasMobile={hasMobile} />
          {hasMobile && <MobileImage {...imageMobile} />}
        </>
      )}
    </ColumnBlock>
  );
};

export default Image;
