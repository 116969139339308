import React from 'react';
import { SectionHeaderType } from '../../../types';
import { StyledHeader } from './style';

const Header: React.StatelessComponent<SectionHeaderType> = ({ children, height, offsetAbove, offsetBelow }) => (
  <StyledHeader
    paddingTop={height ? `${height}%` : undefined}
    marginTop={offsetAbove ? `-${offsetAbove}%` : undefined}
    marginBottom={offsetBelow ? `-${offsetBelow}%` : undefined}
  >
    {children}
  </StyledHeader>
);

export default Header;
