import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { PostType, ArtistType, SessionType } from '../../types';
import Image from '../Image';
import Slider, { Grid, Slide, ImageContainer, ContentContainer, Arrow } from './styles';

// Weird bug where I can't use styled(ColumnBlock) inside `/blocks/Block/styles`
export { CarouselBlock } from './styles';
export type CarouselSize = 'small' | 'large';

interface Props {
  items?: PostType[] | ArtistType[];
  news?: PostType[];
  size?: CarouselSize;
  carousel: boolean;
}

const defaultSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  variableWidth: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  nextArrow: <Arrow to="next" />,
  prevArrow: <Arrow to="previous" />,
};

const styleSettings = {
  small: {
    title: 'h4',
    subtitle: 'p5',
    sponsored: 'p5',
  },
  large: {
    title: 'h4',
    subtitle: 'p5',
    sponsored: 'p5',
  },
};

export const Carousel: React.StatelessComponent<Props> = ({ size = 'large', items, news, carousel = true }) => {
  // Adopt the correct item list
  const slides: any = items || news || [];

  // Set default Container type and props
  let Container: any = Slider;
  let containerProps: any = { size };

  // Set the container height of each slide item
  const tallestItem =
    slides.length &&
    slides.reduce(
      (dimensions, { featuredImage }) => {
        if (featuredImage && featuredImage.height && featuredImage.width && featuredImage.height > dimensions.height) {
          dimensions = { width: featuredImage.width, height: featuredImage.height };
        }
        return dimensions;
      },
      { height: 1, width: 1 },
    );

  // Continer is a carousel if explicitly stated
  if (carousel) {
    containerProps = { ...containerProps, ...defaultSettings };

    // Hide arrows when 4 or less items on small carousel
    if (size === 'small' && items.length < 4) {
      containerProps = { ...containerProps, arrows: false };
    }
  }
  // Otherwise it is just a Flex Grid
  else {
    Container = Grid;
  }

  // Prevent scrolling up or down the page when we are using the slider
  let firstClientX;
  const touchStart = (e) => {
    const [touch] = e.touches;
    firstClientX = touch.clientX;
  };

  const preventTouch = (e) => {
    const [touch] = e.touches;
    const threshold = 5;

    const clientX = touch.clientX - firstClientX;

    if (Math.abs(clientX) > threshold) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });

    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch);
    };
  }, []);

  return (
    <Container {...containerProps} size={size}>
      {slides &&
        slides.map(({ featuredImage, title, sponsored, slug, data }, index) => {
          let foundSession: SessionType;

          if (data && data.sessions && data.sessions.length) {
            foundSession = data.sessions.find(({ sessionDate }) => sessionDate);

            if (foundSession) {
              foundSession = {
                ...foundSession,
                sessionDate: format(foundSession.sessionDate, 'dddd'),
              };
            }
          }

          return (
            <Slide key={`slide-${index}`} size={size}>
              <ImageContainer paddingTop={(tallestItem.height / tallestItem.width) * 100}>
                {slug ? (
                  <a href={slug} data-test="NewsLink">
                    <Image {...featuredImage} data-test="Image" />
                  </a>
                ) : (
                  <a data-test="NewsLink">
                    <Image {...featuredImage} data-test="Image" />
                  </a>
                )}
              </ImageContainer>

              <ContentContainer href={slug} as={slug ? 'a' : 'div'}>
                <h3 className={styleSettings[size].title} data-test="Title">
                  {title}
                </h3>
                {foundSession && foundSession.sessionDate && (
                  <span className={styleSettings[size].subtitle} data-test="Session">
                    {foundSession.stage ? `${foundSession.stage} • ${foundSession.sessionDate}` : foundSession.sessionDate}
                  </span>
                )}
                {sponsored && (
                  <span className={styleSettings[size].sponsored} data-test="Sponsored">
                    Sponsored content
                  </span>
                )}
              </ContentContainer>
            </Slide>
          );
        })}
    </Container>
  );
};

export default Carousel;
