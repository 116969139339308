import React from 'react';
import Modal, { SocialButton, Terms } from './styles';
import { FacebookIcon, InstagramIcon } from '../../icons';
import { useKeyPress } from '../../hooks';
import IconButton from '../IconButton';
import API from '../../agents/API';
import { set as setSessionStorage } from '../../utils/sessionStorage';
import { set as setLocalStorage } from '../../utils/localStorage';

import SocialLogin from './components/SocialLogin';
import isBrowser from '../../utils/isBrowser';

const { Heading } = Modal;

interface Props {
  visible: boolean;
  close: () => void;
  updateParent: () => void;
}

const responseFacebook = async ({ response, close, updateParent }) => {
  try {
    const { data: newContact } = await API.facebookLogin({ token: response._token.accessToken });
    const { token, ...user } = newContact;

    if (token && user) {
      setSessionStorage('user', JSON.stringify(user));

      if (user.new_user) {
        setSessionStorage('token', token);
        window.location.replace('/subscribe');
      } else {
        setLocalStorage('token', token);
        updateParent();
        close();
      }
    } else {
      close();
    }
  } catch (e) {
    close();
  }
};

const MySplendourModal: React.FunctionComponent<Props> = ({ visible = false, close, updateParent }) => {
  useKeyPress('Escape', close);

  return (
    <Modal.Outer visible={visible} data-test="Modal">
      <Modal.Inner>
        <IconButton className="close-button" type="close" onClick={close} />

        <Heading>Signup to my splendour</Heading>
        <p>Create a My Splendour account now to start planning your best festival weekend!</p>
        <p style={{ marginBottom: 32 }}>
          Just select the artists you most want to see each day and My Splendour will create you a personalised schedule.
        </p>

        <div style={{ marginBottom: 32 }}>
          <SocialLogin
            provider="instagram"
            appId="144b6aeec99f42efaa1364f6f32110b6"
            redirect={isBrowser() && `${window.location.origin}/callback`}
          >
            <SocialButton>
              <InstagramIcon />
              <span>Signup with Instagram</span>
            </SocialButton>
          </SocialLogin>
          <SocialLogin
            provider="facebook"
            appId="563405637511416"
            onLoginSuccess={(response) => responseFacebook({ response, close, updateParent })}
            onLoginFailure={() => close()}
          >
            <SocialButton>
              <FacebookIcon />
              <span>Signup with Facebook</span>
            </SocialButton>
          </SocialLogin>
        </div>

        <Terms>
          By signing up you agree to our{' '}
          <a href="https://2019.splendourinthegrass.com/privacy" target="_blank">
            Terms of Use
          </a>
        </Terms>
      </Modal.Inner>
    </Modal.Outer>
  );
};

export default MySplendourModal;
