import React from 'react';
import styled from 'styled-components';
import MoonImage from '../../../assets/images/moon.png';
import { generateFloaterInnerCss, generateFloaterOuterCss } from '../../../components/Floater/styles';
import StyledGreeble from './style';
import { SizeGreebleType } from './types';

const FloaterOuter = styled.div`
  ${generateFloaterOuterCss()}
`;

const FloaterInner = styled(StyledGreeble)`
  ${generateFloaterInnerCss()}
`;

const Moon2: React.StatelessComponent<SizeGreebleType> = ({ size, top, bottom, left, right, flipX, flipY }) => (
  <FloaterOuter>
    <FloaterInner
      width={size}
      height={size}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      flipX={flipX}
      flipY={flipY}
      image={MoonImage}
    />
  </FloaterOuter>
);

export default Moon2;
