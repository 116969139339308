import styled from 'styled-components';
import { default as ImageComponent } from '../../components/Image';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  hasMobile: boolean;
}

export const Gallery = styled.div(
  ({ theme: { mobileView } }: Props) => `
  display: grid;
  grid-template-columns: auto auto auto;
  ${mobileView}{
    grid-template-columns: auto;
  }
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: center;
  align-items: center;
  > div {
    max-width: 100%;
    height: auto;
  }
`,
);
export const DesktopImage = styled(ImageComponent)(
  ({ theme: { mobileView }, hasMobile }: Props) => `
  ${
    hasMobile
      ? `
    ${mobileView} {
      display: none;
    }
  `
      : ''
  }

`,
);

export const MobileImage = styled(ImageComponent)(
  ({ theme: { mobileView } }) => `
  display: none;

  ${mobileView} {
    display: block;
  }
`,
);
