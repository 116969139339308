import React from 'react';
import { HeroType } from '../../types';
import { HeroContainer, HeroWrapper } from './styles';
import { ExternalLinkIcon } from '../../icons';

export const Hero: React.FunctionComponent<HeroType> = ({
  title,
  leadingParagraph,
  returnURL,
  returnLabel,
  center,
  textColor,
  children,
  paddingTop,
  sponsored
}) => (
  <HeroWrapper textColor={textColor} paddingTop={paddingTop}>
    <HeroContainer center={center}>
      {returnURL && returnLabel && (
        <a className="p4" href={returnURL} data-test="Button">
          <ExternalLinkIcon rotation="225" size="sm" />
          {returnLabel}
        </a>
      )}

      <h1 className="h0" data-test="Title">
        {title}
      </h1>

      {leadingParagraph && <div className="p1" data-test="Subtitle" dangerouslySetInnerHTML={{ __html: leadingParagraph }} />}
      {sponsored && (
        <span data-test="Sponsored">
          Sponsored content
        </span>
      )}
    </HeroContainer>
    {children && children}
  </HeroWrapper>
);

export default Hero;
