import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-green-2.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const GreenSplatter3: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} left={0} flipX={true} bottom={bottom} width={16.9} height={60.0} image={SplatterImage} />
);

export default GreenSplatter3;
