import React from 'react';
import { ColumnBlockType } from '../../types';
import ColumnBlock from '../Block';
import StyledContent from './styles';

interface Props extends ColumnBlockType {
  html: string;
}

export const HtmlTextBlock: React.FunctionComponent<Props> = ({ title, cta, html }) => (
  <ColumnBlock title={title} cta={cta} type="small">
    <StyledContent className="p3" dangerouslySetInnerHTML={{ __html: html }} data-test="Html" />
  </ColumnBlock>
);

export default HtmlTextBlock;
