import React from 'react';
import { ColumnBlockType, ImageType } from '../../types';
import ColumnBlock from '../Block';
import { DesktopImage, Gallery } from './styles';

export interface Props extends ColumnBlockType {
  images: [ImageType];
}

export const Image: React.StatelessComponent<Props> = ({ title, cta, images }) => {
  return (
    <ColumnBlock title={title} cta={cta} data-test="BlockHeader" type="small">
      {images && (
        <Gallery>
          {images.map((image, index) => {
            return (
              <>
                <DesktopImage {...image} />
              </>
            );
          })}
        </Gallery>
      )}
    </ColumnBlock>
  );
};

export default Image;
