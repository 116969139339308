import styled from 'styled-components';
import Frame1 from '../../assets/images/polaroid/frame-1.png';
import Frame2 from '../../assets/images/polaroid/frame-2.png';
import Frame3 from '../../assets/images/polaroid/frame-3.png';
import { generateFloaterInnerCss, generateFloaterOuterCss } from '../../components/Floater/styles';
import { ThemeType } from '../../types';
import { RowBlock } from '../Block';
import { Column, Container } from '../Block/styles';

export default styled(RowBlock)(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
    ${Container} {
      ${mobileView} {
        flex-direction: column;
        width: 100%;
      }
    }

    ${Column} {
      flex: 1;
      width: 100%;

      &:first-of-type {
        h2 {
          margin-bottom: ${spacing.x3};
          padding-right: ${spacing.x6};
        }
      }
    }
`,
);

export const SocialLinksWrapper = styled.div(
  ({ theme: { mobileView, spacing } }) => `
  ${mobileView} {
    position: absolute;
    bottom: -${spacing.x3};
    left: 0;
    right: 0;
    text-align: center;
  }
`,
);

export const ImagesWrapper = styled.div(
  ({ theme: { mobileView, spacing } }) => `
  display: grid;
  transform: translateX(${spacing.x3});
  padding: ${spacing.x6} 0;
  grid-row-gap: ${spacing.x12};

  grid-template-areas:
  'img1 img1 img1 img1 . . . . img4 img4 img4 img4'
  'img1 img1 img1 img1 . . . . img4 img4 img4 img4'
  'img2 img2 img2 . img3 img3 img3 img3 img4 img4 img4 img4'
  'img2 img2 img2 . img3 img3 img3 img3 . . . .';

 ${mobileView} {
  grid-template-areas:
    'img2 img2 img2 . .    .    .    .'
    'img2 img2 img2 . img3 img3 img3 img3'
    '.    .    .    . img3 img3 img3 img3';
 }
    

  ${mobileView} {
    padding: ${spacing.x6} 0 ${spacing.x12};
  }
`,
);

export const ImageWrapper = styled.div`
  & > * {
    height: 100%;
    object-fit: cover;
  }
`;

const ImageWrapperDesktopOnly = styled(ImageWrapper)(
  ({ theme: { mobileView } }) => `
  ${mobileView} { 
    display: none;
  }
`,
);

export const ImageWrapper1 = styled(ImageWrapperDesktopOnly)`
  ${generateFloaterOuterCss()}
  grid-area: img1;
  transform: rotate(-5deg);
`;

export const ImageWrapperInner1 = styled(ImageWrapper)`
  ${generateFloaterInnerCss()}

  &::after {
    background-image: url(${Frame1});
    content: '';
    position: absolute;
    top: -5%;
    left: -6%;
    right: -7%;
    bottom: -24%;
    background-repeat: round;
  }
`;

export const ImageWrapper2 = styled(ImageWrapper)`
  ${generateFloaterOuterCss()}

  grid-area: img2;
  transform: rotate(-10deg);
`;

export const ImageWrapperInner2 = styled(ImageWrapper)`
  ${generateFloaterInnerCss()}

  &::after {
    background-image: url(${Frame2});
    content: '';
    position: absolute;
    top: -6%;
    left: -7%;
    right: -7%;
    bottom: -16%;
    background-repeat: round;
  }
`;

export const ImageWrapper3 = styled(ImageWrapper)`
  ${generateFloaterOuterCss()}

  grid-area: img3;
  transform: rotate(2deg);
`;

export const ImageWrapperInner3 = styled(ImageWrapper)`
  ${generateFloaterInnerCss()}

  &::after {
    background-image: url(${Frame2});
    content: '';
    position: absolute;
    top: -6%;
    left: -7%;
    right: -7%;
    bottom: -24%;
    background-repeat: round;
  }
`;

export const ImageWrapper4 = styled(ImageWrapperDesktopOnly)`
  ${generateFloaterOuterCss()}

  grid-area: img4;
  transform: rotate(10deg);
`;

export const ImageWrapperInner4 = styled(ImageWrapper)`
  ${generateFloaterInnerCss()}

  &::after {
    background-image: url(${Frame3});
    content: '';
    position: absolute;
    top: -10%;
    left: -8%;
    right: -8%;
    bottom: -25%;
    background-repeat: round;
  }
`;
