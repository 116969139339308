import React from 'react';
import { SocialsType, IconSizeType } from '../../types';
import IconLink from '../IconLink';
import StyledWrapper from './styles';

interface Props {
  links: SocialsType;
  size?: IconSizeType;
}

const SocialLinks: React.StatelessComponent<Props> = ({ links, size }) => (
  <StyledWrapper>
    {Object.entries(links).map(([type, href]: [any, string], index) => {
      if (href) {
        return <IconLink key={index} type={type} size={size} href={href} data-test="IconLink" />;
      }
    })}
  </StyledWrapper>
);

export default SocialLinks;
