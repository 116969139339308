import React from 'react';
import { LineupType } from '../../types';
import LineupWrapper, { ArtistLink, ScrollingArtistLink, ArtistWrapper } from './styles';

const numberOfTopBilledActs = 4;

const Lineup: React.FunctionComponent<LineupType> = ({ artists, align = 'center', containerId, artistOffset, onArtistClick }) => (
  <LineupWrapper align={align}>
    <div>
      {artists &&
        artists.map(({ wordpress_id, title, slug }, index) => (
          <ArtistWrapper key={index} id={`Link-${wordpress_id}`}>
            {!containerId && (
              <ArtistLink href={`${slug}`} data-test="ArtistLink">
                {title}
              </ArtistLink>
            )}
            {containerId && (
              <ScrollingArtistLink
                to={`Artist-${wordpress_id}`}
                smooth={true}
                offset={artistOffset}
                duration={500}
                onClick={() => {
                  if (onArtistClick) {
                    onArtistClick();
                  }
                  window.history.pushState(null, '', `${slug}`);
                }}
                data-test="ArtistLink"
                containerId={containerId}
              >
                {title}
              </ScrollingArtistLink>
            )}
            {index === numberOfTopBilledActs - 1 && <br />}
          </ArtistWrapper>
        ))}
    </div>
  </LineupWrapper>
);

export default Lineup;
