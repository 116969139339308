import styled, { createGlobalStyle } from 'styled-components';
import { ThemeType } from '../types';
import './fonts/index.css';

export default createGlobalStyle(
  ({ theme: { colors, fontSizes, fontFamilies, mobileView } }: ThemeType) => `
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    -webkit-backface-visibility: hidden !important;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${colors.white70};
    }
  }

  html, body {
    margin: 0;
    padding: 0;
    font-family: ${fontFamilies.default};
    font-weight: 600;
    background-color: ${colors.black};
    color: ${colors.white};
    overflow-x: hidden;
  }

  ${'' /* Required to prevent page scroll on Menu overlay */}
  #___gatsby {
    height: 100%;
    & > * {
      height: 100%;
    }
  }  

  .page-layout {
    height: 100%;
  }

  main {
    margin: 0 auto;
    width: 100%;
    flex: 1;
  }

  button[type="unstyled"] {
    background: transparent;
    color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    pointer: cursor;
    color: inherit;      
  }

  a:hover {
    text-decoration: underline;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  [class^=h] {
    font-family: ${fontFamilies.default};
    font-weight: 800;
    text-transform: uppercase;
  }

  [class^=p] {
    font-family: ${fontFamilies.default};
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.32px;
  }

  input, textarea, select, button {
    font-weight: 600;
  }  

  .font {
    &-400 { font-weight: 600; }
    &-600 { font-weight: 600; }
    &-800 { font-weight: 800; }

    &-normal { font-style: normal; }
    &-italic { font-style: italic; }
  }

  .text {
    &-green { color: ${colors.green}; }
    &-purple { color: ${colors.purple}; }
    &-black { color: ${colors.black}; }
    &-white { color: ${colors.white}; }
    &-pink { color: ${colors.pink}; } 
    
    &-left { text-align: left; }
    &-right { text-align: right; }
    &-center { text-align: center; }
    &-justify { text-align: justify; }

    &-uppercase  {text-transform: uppercase;}
    &-lowercase  {text-transform: lowercase;}
    &-capitalize {text-transform: capitalize;}
    &-unset {text-transform: unset;}
  }

  .h0 { font-size: ${fontSizes.major}; }
  .h1 { font-size: ${fontSizes.xxl}; }
  .h2 { font-size: ${fontSizes.xxl};}
  .h3 { font-size: ${fontSizes.xl}; }
  .h4 { font-size: ${fontSizes.lg}; }
  .h5 { font-size: ${fontSizes.md}; }
  .h6 { font-size: ${fontSizes.sm}; }
  .h7 { font-size: ${fontSizes.xs}; }
  .h8 { font-size: ${fontSizes.xxs}; }
  .h9 { font-size: ${fontSizes.micro}; }

  .p1 { font-size: ${fontSizes.lg}; }
  .p2 { font-size: ${fontSizes.md}; }
  .p3 { font-size: ${fontSizes.sm}; }
  .p4 { font-size: ${fontSizes.xs}; }
  .p5 { font-size: ${fontSizes.xxs}; }
  .p6 { font-size: ${fontSizes.micro}; } 

  ${mobileView} {
    .h0 { font-size: ${fontSizes.xl} }
    .h1 { font-size: ${fontSizes.lg} }
    .h2 { font-size: ${fontSizes.md} }
    .h3 { font-size: ${fontSizes.sm} }
    .h4 { font-size: ${fontSizes.xs} }
    .h5 { font-size: ${fontSizes.xs} }

    .p1 { font-size: ${fontSizes.sm}; }
    .p2 { font-size: ${fontSizes.xs}; }
    .p3 { font-size: ${fontSizes.xxs}; }
    .p4 { font-size: ${fontSizes.micro}; }
  }
`,
);

export const Page = styled.div(
  ({ hideOverflow }: { hideOverflow: boolean }) => `
    overflow: ${hideOverflow ? 'auto' : 'visible'};
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`,
);

export const PageContent = styled.main`
  position: relative;
  margin-top: -63px;
  background-color: white;
`;
