import {
  AccordionBlock,
  AppBlock,
  ExploreLineupBlock,
  FeaturedPostBlock,
  HtmlTextBlock,
  ImageBlock,
  FeaturedNewsBlock,
  LineupBlock,
  PostListBlock,
  SocialBlock,
  SpotifyBlock,
  TicketBlock,
  VideoBlock,
  VideosBlock,
  CodeEmbed,
  LineupListBlock,
  GalleryBlock,
} from '../blocks';

export default {
  accordionBlock: AccordionBlock,
  app: AppBlock,
  exploreLineup: ExploreLineupBlock,
  faqBlock: AccordionBlock,
  featured: FeaturedPostBlock,
  featuredNews: FeaturedNewsBlock,
  htmlText: HtmlTextBlock,
  image: ImageBlock,
  latestNews: FeaturedNewsBlock,
  lineup: LineupBlock,
  postBlocks: PostListBlock,
  social: SocialBlock,
  spotify: SpotifyBlock,
  tickets: TicketBlock,
  video: VideoBlock,
  videos: VideosBlock,
  codeEmbed: CodeEmbed,
  lineupList: LineupListBlock,
  galleryBlock: GalleryBlock,
};
