import StarsGreenImage from '../assets/images/stars-green.png';
import StarsWhiteImage from '../assets/images/stars-white.jpg';
import StarsBlueImage from '../assets/images/stars-blue.png';
import theme from '../styles/theme';

const SPACE_GREEN = {
  backgroundImage: `url(${StarsGreenImage})`,
  backgroundColor: theme.colors.lightBlack,
  backgroundPosition: 'center top',
  backgroundRepeat: 'repeat-y',
  backgroundSize: '100% auto',
  backgroundAttachment: 'fixed',
  color: theme.colors.white,
};

export const THEMES = {
  SPACE_WHITE: {
    backgroundImage: `url(${StarsWhiteImage})`,
    // backgroundColor: theme.colors.black,
    backgroundPosition: 'center top',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100% auto',
    backgroundAttachment: 'fixed',
    color: theme.colors.white,
  },
  SPACE_GREEN,
  SPACE_GREEN_2: {
    ...SPACE_GREEN,
    color: theme.colors.green,
  },
  BLACK: {
    backgroundColor: theme.colors.lightBlack,
    color: theme.colors.white,
  },
  GREEN: {
    background: `linear-gradient(to bottom, ${theme.colors.green}, ${theme.colors.blue})`,
    backgroundColor: theme.colors.blue,
    color: theme.colors.black3,
    fill: theme.colors.black,
    backgroundImage: `url(${StarsBlueImage})`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    backgroundAttachment: 'fixed',
  },
  BLUE: {
    background: `linear-gradient(to bottom, ${theme.colors.blue}, ${theme.colors.green})`,
    backgroundColor: theme.colors.green,
    color: theme.colors.black3,
    fill: theme.colors.black,
  },
  PINK: {
    background: `linear-gradient(to bottom, ${theme.colors.pink}, ${theme.colors.babyPink})`,
    backgroundColor: theme.colors.babyPink,
    color: theme.colors.white,
  },
  HOT_PINK: {
    background: theme.colors.hotPink,
    color: theme.colors.white,
  },
  PURPLE: {
    background: `linear-gradient(to bottom, ${theme.colors.purple}, ${theme.colors.hotPink})`,
    backgroundColor: theme.colors.hotPink,
    color: theme.colors.white,
  },
};
