import styled, { css, keyframes } from 'styled-components';

export const HeaderWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
`;

export const SecondaryHeader = styled.header(
  ({ theme: { colors } }) => `
    width: 100%;
    background-color: ${colors.black80};
    color: ${colors.white};
    position: sticky;
    top: 0;
    position: relative;
    z-index: 300;
    transition: margin-top 0.3s ease-in-out 0.3s;
`,
);

export const HeaderContent = styled.div(
  ({ theme: { spacing, mobileView } }) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing.x2} ${spacing.x6};

    ${mobileView} {
      padding: ${spacing.x2}; ${spacing.x2};
    }
`,
);

export const ContentWrapper = styled.div(
  ({ theme: { spacing } }) => `
    display: flex;
    align-items: center;

    & > * {
      display: inline;
    }

    & > *:not(:last-child) {
      margin-right: ${spacing.x3};
    }
`,
);

export const PageLinksWrapper = styled.div(
  ({ theme: { tabletView, spacing } }) => `
    display: inline;
    text-transform: uppercase;
    & > * {
      display: inline-block;
      padding: 0 ${spacing.x2};
    }

    ${tabletView} {
      display: none;
    }

`,
);

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

export const TicketButton = styled.a(
  ({ theme: { mobileView, colors } }) => css`
    font-size: 0.9em;
    display: inline-block;
    padding: 0.5em 0.8em;
    background-color: ${colors.pink};
    border: 1px solid ${colors.pink};
    text-transform: uppercase;
    font-weight: 800;
    transition: 0.2s background-color;
    animation: ${pulse} 1s ease infinite;
    white-space: nowrap;

    &:hover {
      background-color: transparent;
      text-decoration: none;
    }
    ${mobileView} {
      font-size: 3vmin;
    }
  `,
);
