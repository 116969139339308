import Content from './Content';
import ContentInner from './ContentInner';
import BrushStroke from './Dividers/BrushStroke';
import Gradient from './Dividers/Gradient';
import PinkBeachBack from './Dividers/PinkBeachBack';
import PinkBeachFront from './Dividers/PinkBeachFront';
import PinkDesert from './Dividers/PinkDesert';
import BlueSplatter1 from './Greeblies/BlueSplatter1';
import BlueSplatter2 from './Greeblies/BlueSplatter2';
import BlueSplatter3 from './Greeblies/BlueSplatter3';
import Cactus1 from './Greeblies/Cactus1';
import Cactus2 from './Greeblies/Cactus2';
import Cactus3 from './Greeblies/Cactus3';
import FarJerrySwarm from './Greeblies/FarJerrySwarm';
import GalaxyHole from './Greeblies/GalaxyHole';
import GreenSplatter1 from './Greeblies/GreenSplatter1';
import GreenSplatter2 from './Greeblies/GreenSplatter2';
import GreenSplatter3 from './Greeblies/GreenSplatter3';
import GreenSplatter4 from './Greeblies/GreenSplatter4';
import GreenSplatter5 from './Greeblies/GreenSplatter5';
import MidJerrySwarm from './Greeblies/MidJerrySwarm';
import Moon from './Greeblies/Moon';
import Moon2 from './Greeblies/Moon2';
import NearJerrySwarm from './Greeblies/NearJerrySwarm';
import PinkSplatter1 from './Greeblies/PinkSplatter1';
import PinkSplatter2 from './Greeblies/PinkSplatter2';
import StageHeader1 from './Greeblies/StageHeader1';
import StageHeader2 from './Greeblies/StageHeader2';
import StageHeader3 from './Greeblies/StageHeader3';
import StageHeader4 from './Greeblies/StageHeader4';
import WhiteSplatter1 from './Greeblies/WhiteSplatter1';
import WhiteSplatter2 from './Greeblies/WhiteSplatter2';
import WhiteSplatter3 from './Greeblies/WhiteSplatter3';
import WhiteSplatter4 from './Greeblies/WhiteSplatter4';
import Layer from './Layer';
import Layout from './Layout';
import Padder from './Padder';
import Page from './Page';
import Section from './Section';

const CSSParallax = {
  Content,
  ContentInner,
  Dividers: {
    BrushStroke,
    PinkBeachBack,
    PinkBeachFront,
    PinkDesert,
    Gradient,
  },
  Greeblies: {
    NearJerrySwarm,
    MidJerrySwarm,
    FarJerrySwarm,
    Moon,
    Moon2,
    BlueSplatter1,
    BlueSplatter2,
    BlueSplatter3,
    Cactus1,
    Cactus2,
    Cactus3,
    GalaxyHole,
    GreenSplatter1,
    GreenSplatter2,
    GreenSplatter3,
    GreenSplatter4,
    GreenSplatter5,
    PinkSplatter1,
    PinkSplatter2,
    StageHeader1,
    StageHeader2,
    StageHeader3,
    StageHeader4,
    WhiteSplatter1,
    WhiteSplatter2,
    WhiteSplatter3,
    WhiteSplatter4,
  },
  Layer,
  Layout,
  Padder,
  Page,
  Section,
};

export default CSSParallax;
