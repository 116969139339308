import styled from 'styled-components';
import { ThemeType } from '../../../types';

interface LayoutType extends ThemeType {
  natural?: boolean;
}

export default styled.div(
  ({ natural, theme: { everythingButIos } }: LayoutType) => `
    ${
      !natural
        ? `
          ${everythingButIos} {
            perspective: 300px;
            height: 100vh;
            overflow: hidden;
          }
        `
        : ''
    }
`,
);
