import styled from 'styled-components';
import ColumnBlock from '../Block';
import { ThemeType, ColumnBlockType } from '../../types';
import { TitleWrapper, HeaderRow } from '../Block/styles';

export default styled(ColumnBlock)(
  ({ subTitle, theme: { mobileView, fontSizes, screenWidths } }: ThemeType & ColumnBlockType) => `    
    ${HeaderRow} {
      padding-bottom: ${!!subTitle ? '120px' : '40px'};

      ${TitleWrapper} {
        h2 {
          line-height: 1.1;
        }
  
        h3 {      
          margin-top: 0;
          text-transform: inherit;
          font-size: ${fontSizes.md};
          line-height: 1.5;
        }  

        ${mobileView} {
          h2 {
            font-size: ${fontSizes.lg};
            line-height: 1;
            padding-bottom: ${!!subTitle ? '16px' : 'inherit'};
          }
    
          h3 {
            font-size: ${fontSizes.xs};
            line-height: 1.75;
          }
        }    

        ${!!subTitle &&
          `
          // Only above tablet width
          @media (min-width: ${screenWidths.tablet + 1}px) {
            flex-direction: row;
            max-width: 100%;
            align-items: flex-end;
            
            h2 {
              max-width: 352px;
              flex: 0 auto;
              margin-right: 48px;
              hyphens: auto;
            }
  
            h3 {
              hyphens: auto;
              flex: 0 1 480px;
            }
          }
        `}       
      }      

      ${mobileView} {
        padding-bottom: ${!!subTitle ? '48px' : '24px'};        
      }
    }      
`,
);

export const ImageWrapper = styled.div(
  ({ theme: { colors, mobileView } }: ThemeType) => `
      padding-top: 56.34%;
      width: 100%;
      background-color: ${colors.black80};    
      position: relative;
      overflow: hidden;
      margin-bottom: 16px;    

    .post-image {
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      box-shadow: 0 0 24px 8px ${colors.green}; 
      transition: box-shadow 0.2s;
    }

    @media (pointer: none) {
      &:hover {
        box-shadow: none; 
      }
    }

    ${mobileView} {
      height: 96px;
      width: 96px;
      margin: 0;
      padding: 0;
    }
  `,
);

export const PostList = styled.div(
  ({ theme: { tabletView, mobileView } }: ThemeType) => `
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px;

    ${tabletView} {
      grid-template-columns: repeat(2, 1fr);
    } 

    ${mobileView} {
      grid-template-columns: 1fr;
    } 
  `,
);

export const PostLink = styled.a(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    cursor: pointer;    

    &:hover {
      text-decoration-color: ${colors.green};          

      .post-title {
        color: ${colors.green};
        transition: color 0.2s;
      }
    }    

    ${mobileView} {
      display: flex;
      flex-direction: row;

      .post-title {
        padding: 16px 0 0 16px;
      }
    }
`,
);
