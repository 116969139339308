import React from 'react';
import Artist from '../Artist';
import { SECTION_THEMES } from '../../constants';
import ArtistConsumer from '../../context/ArtistContext';
import SlideMenu from '../SlideMenu';
import ContentWrapper, { BrushStrokeWrapper } from './styles';
import BrushStroke from '../../assets/svg/brush-stroke-3';

const ArtistMenu: React.StatelessComponent<{ showSubscribeModal: () => void }> = ({ showSubscribeModal }) => (
  <ArtistConsumer>
    {({ artist, updateArtist }) => (
      <SlideMenu
        isOpen={!!artist}
        close={() => updateArtist()}
        scroll={true}
        width="50%"
        maxWidth="720px"
        mobileWidth="80%"
        right={true}
        theme={SECTION_THEMES.HOT_PINK}
      >
        <ContentWrapper>
          {artist && (
            <Artist
              showSubscribeModal={() => {
                updateArtist();
                showSubscribeModal();
              }}
              {...artist}
            />
          )}
        </ContentWrapper>
        <BrushStrokeWrapper>
          <BrushStroke color={SECTION_THEMES.HOT_PINK.background} />
        </BrushStrokeWrapper>
      </SlideMenu>
    )}
  </ArtistConsumer>
);

export default ArtistMenu;
