import styled from 'styled-components';
import PaintStroke from '../../assets/images/paint-stroke-green-2.png';
import { ThemeType } from '../../types';

export const AppButtons = styled.div(
  ({ theme: { mobileView, spacing }, backgroundStroke }: ThemeType & { backgroundStroke: boolean }) => `
      position: relative;

      ${
        backgroundStroke
          ? `
          &:not(:empty) {
            &:after {
              content: '';
              position: absolute;
              background-image: url(${PaintStroke});
              top: -30px;
              z-index: -1;
              left: -20px;
              height: 110px;
              width: 412px;
      
              ${mobileView} {
                max-width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        `
          : ''
      }
  
      a {
        display: inline-block;
        transition: transform .3s;
        
        &:hover {
          transform: scale(1.03);
        }
        
        &:not(:last-of-type) {
          margin-right: ${spacing.x4};
  
          ${mobileView} {
            margin-right: ${spacing.x3};
          }
        }
  
        img {
          display: inline-block;
          width: 135px;
        }
      }
    `,
);
