import React from 'react';
import { CodeType } from '../../types';
import isBrowser from '../../utils/isBrowser';
// import isMobile from '../../utils/isMobile';

const Ad: React.FunctionComponent<CodeType> = ({ js, mobileJs, divId }) => {
  if (!isBrowser()) {
    return null;
  }
  // 
  // const scriptRef = useRef<HTMLDivElement>(null);
  // const mobile = isMobile();
  // 
  // useEffect(() => {
  //   if (scriptRef && scriptRef.current) {
  //     const scriptElem = document.createElement('script');
  //     scriptElem.innerHTML = 'var googletag = googletag || {}; googletag.cmd = googletag.cmd || []; ' + (mobile ? mobileJs : js);
  //     scriptRef.current.appendChild(scriptElem);
  //   }
  // }, []);

  return <iframe src="https://servedbyadbutler.com/adserve/;ID=170069;size=300x250;setID=372555;type=iframe;click=CLICK_MACRO_PLACEHOLDER" width="300" height="250" marginwidth="0" marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="no">
</iframe>;
};

export default Ad;
