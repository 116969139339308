import styled, { css } from 'styled-components';
import { ThemeType } from '../../../types';

interface StyledLayerProps extends ThemeType {
  depth: number;
  natural?: boolean;
}

const depthValues = {
  '-4': {
    translate: -5,
    scale: 1.87,
    zIndex: 10,
  },
  '-3': {
    translate: -3,
    scale: 1.52,
    zIndex: 11,
  },
  '-2': {
    translate: -2,
    scale: 1.38,
    zIndex: 12,
  },
  '-1': {
    translate: -1.5,
    scale: 1.42,
    zIndex: 13,
  },
  '0': {
    translate: 0,
    scale: 1,
    zIndex: -1,
  },
  '1': {
    translate: 0.6,
    scale: 0.94,
    zIndex: 15,
  },
  '2': {
    translate: 2,
    scale: 0.8,
    zIndex: 16,
  },
};

export default styled.div(({ depth, natural, theme: { everythingButIos } }: StyledLayerProps) => {
  const values = depthValues[`${depth}`];

  return css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: ${values.zIndex};

    ${!natural
      ? `
        ${everythingButIos} {
          will-change: transform;
          -webkit-transform: translateZ(${values.translate}vw) scale(${values.scale});
          transform: translateZ(${values.translate}vw) scale(${values.scale});

          ${
            depth > 0
              ? `
                @media screen and (orientation:portrait) {
                  -webkit-transform: translateZ(${values.translate}vh) scale(${values.scale});
                  transform: translateZ(${values.translate}vh) scale(${values.scale});
                }
              `
              : ''
          }
        }
      `
      : ''}
  `;
});
