import React from 'react';
import { IframeWrapper, SpotifyEmbedWrapper } from './styles';

interface Props {
  embed?: string;
}

const SpotifyEmbed: React.FunctionComponent<Props> = ({ embed }) => (
  <SpotifyEmbedWrapper>
    {embed && (
      <IframeWrapper>
        <div dangerouslySetInnerHTML={{ __html: embed }} data-test="Iframe" />
      </IframeWrapper>
    )}
  </SpotifyEmbedWrapper>
);

export default SpotifyEmbed;
