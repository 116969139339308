import axios from 'axios';
import React from 'react';

interface State {
  data: any;
  fetching: boolean;
  error: boolean | string;
}

export default (WrappedComponent: any) => {
  return class extends React.Component<any, State> {
    constructor(props: any) {
      super(props);
      this.state = {
        data: undefined,
        fetching: true,
        error: false,
      };
    }

    componentWillReceiveProps({ location: newLocation }) {
      const { location } = this.props;
      if (location.href !== newLocation.href) {
        this.fetchPreview();
      }
    }

    componentDidMount() {
      this.fetchPreview();
    }

    fetchPreview = async () => {
      const { location } = this.props;
      const url = new URL(location.href);

      const previewId = url.searchParams.get('p') || url.searchParams.get('preview_id');
      const postType = url.searchParams.get('post_type');
      const revisionId = url.searchParams.get('revision_id');
      const wpNonce = url.searchParams.get('_wpnonce');

      const endPoint = `${process.env.GATSBY_API_URL}/wp-json/sitg/v1/${postType}/${previewId}/preview/${revisionId}?_wpnonce=${wpNonce}`;
      await axios
        .get(endPoint, {
          withCredentials: true,
        })
        .then(({ data }) => {
          this.setState({
            fetching: false,
            error: !data && `Cannot find preview content with preview Id ${previewId}`,
            data: data && Array.isArray(data) ? data[0] : data,
          });
        })
        .catch(({ message }) => {
          this.setState({ fetching: false, error: message });
        });
    };

    render() {
      const { fetching, error, data } = this.state;
      if (fetching) {
        return <div data-test="FetchMessage">Fetching</div>;
      }
      if (error) {
        return <div data-test="ErrorMessage">Error: {error}</div>;
      }
      return <WrappedComponent content={data} data-test="WrappedComponent" />;
    }
  };
};
