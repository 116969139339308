import styled from 'styled-components';
import { HorizontalAlignmentType, ThemeType } from '../../types';

interface Props extends ThemeType {
  alignment?: HorizontalAlignmentType;
  postType?: string;
}

const direction = {
  left: 'ltr',
  right: 'rtl',
};

export default styled.div(
  ({ theme: { colors, mobileView, spacing }, alignment, postType }: Props) => `
    display: grid;
    grid-template-rows: auto repeat(2, 0.25fr);
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-template-areas:
      'media title'
      'media content';
    grid-column-gap: ${spacing.x6};
    direction: ${direction[alignment]};
    text-align: left;
    a {
      text-decoration: none !important;
      direction: ltr;
    }
    ${mobileView} {
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-template-areas:
        'title'
        'media'
        'content';
    }

    .content {
      grid-area: content;
      direction: ltr;
    }

    .title {
      grid-area: title;
      color: ${colors.green};
      padding-bottom: ${spacing.x3};
      direction: ltr;
    }

    &:hover {
      ${Media} {
        & > * {
          box-shadow: 0 0 32px 8px ${colors.green};
          transition: 0.3s;
        }
      }
    }
    
    ${postType === 'partner' &&
      `
      grid-template-columns: 250px minmax(0, 1fr);
      max-width: 960px;
      margin: auto;
      grid-template-rows: auto;
      grid-template-areas: 'media content';

      ${mobileView} {
        grid-template-columns: auto;
        grid-template-areas:
          'media'
          'content';
      }
      .content {
        p:first-of-type {
          margin-top: 0;
        }
      }
      `}
  `,
);

export const Media = styled.section(
  ({ theme: { mobileView, spacing } }) => `
  grid-area: media;
  position: relative;
  a{
    display: block;
    ${mobileView}{
      height: auto;
    }
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display:block;
  }
  ${mobileView} {
    margin-bottom: ${spacing.x3};
    min-height: 0;
  }
`,
);

export const Published = styled.div(
  ({ theme: { colors, mobileView, spacing } }) => `
    color: ${colors.babyPink};
    padding-bottom: ${spacing.x1};
    ${mobileView} {
      padding-bottom: ${spacing.x2};
    }
`,
);
