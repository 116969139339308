import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-blue.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const BlueSplatter1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom, left, right, flipY, flipX }) => (
  <StyledGreeble
    top={top}
    right={right}
    left={left}
    flipY={flipY}
    flipX={flipX}
    bottom={bottom}
    width={16.2}
    height={60.0}
    image={SplatterImage}
  />
);

export default BlueSplatter1;
