import styled from 'styled-components';
import { ThemeType } from '../../../types';

interface SectionType extends ThemeType {
  natural?: boolean;
}

export default styled.section(
  ({ natural }: SectionType) => `
    display: block;
    min-height: 45vh;
    font-size: 0;
    z-index: 0;

    ${
      !natural
        ? `
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          transform: translate3d(0, 0, 0);
        `
        : ''
    }
`,
);
