import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-green-3.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const GreenSplatter5: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} bottom={bottom} left={0} flipX={true} width={10.0875} height={45.0} image={SplatterImage} />
);

export default GreenSplatter5;
