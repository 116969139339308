import React from 'react';
import FallbackAsset from '../../assets/images/fallback.jpg';
import { ImageType } from '../../types';
import getSafe from '../../utils/getSafe';
import { FallbackImage, GatsbyImage, HtmlImage } from './styles';

interface Props extends ImageType {
  className?: string;
  size?: string;
}

const ImageWrapper: React.FunctionComponent<Props> = ({ className, size, url, alt, sizes }) => {
  if (typeof url === 'string') {
    return <HtmlImage className={className} src={url} alt={alt} data-test="html-img" />;
  } else if (url && getSafe(() => url.localFile.childImageSharp)) {
    return <GatsbyImage className={className} {...url.localFile.childImageSharp} alt={alt} data-test="gatsby-img" />;
  } else if (url && url.mime_type === 'image/gif') {
    return <HtmlImage className={className} src={url.source_url} alt={alt} data-test="html-img" />;
  }
  const bestSize =
    sizes &&
    ((size && sizes[size]) ||
      sizes['hero-width'] ||
      sizes.large ||
      sizes['large-width'] ||
      sizes['large-square'] ||
      sizes.medium ||
      sizes['medium-width'] ||
      sizes['medium-square'] ||
      sizes.thumbnail ||
      sizes['post-thumbnail']);
  if (bestSize) {
    return <HtmlImage className={className} src={bestSize.url} alt={alt} data-test="html-img" />;
  }
  return <FallbackImage className={className} src={FallbackAsset} data-test="no-img" />;
};

export default ImageWrapper;
