import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { StyledSection } from './styles';

const Purple: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Padder height={2} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={5} />
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.PinkSplatter1 top={-40} flipX={true} left={0} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={1} natural={natural}>
      <CSSParallax.Greeblies.GreenSplatter4 bottom={0} />
    </CSSParallax.Layer>
  </StyledSection>
);

export default Purple;
