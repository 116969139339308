import React from 'react';
import { SectionSidebarType } from '../../../types';
import { StyledSidebar, Sticky } from './style';

const Sidebar: React.StatelessComponent<SectionSidebarType> = ({ children }) => (
  <StyledSidebar>
    <Sticky>{children}</Sticky>
  </StyledSidebar>
);

export default Sidebar;
