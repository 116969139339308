import styled, { css } from 'styled-components';

interface Props {
  image?: string;
  height?: number;
  flipY?: boolean;
  offsetY?: number;
  marginTop?: number;
  marginBottom?: number;
  attachToTop?: boolean;
  attachToBottom?: boolean;
}

export default styled.div(({ image, height, offsetY, flipY, marginTop, marginBottom, attachToTop, attachToBottom }: Props) => {
  return css`
    ${
      attachToTop || attachToBottom
        ? `
          position: absolute;
          ${attachToTop ? 'top: 0;' : ''}
          ${attachToBottom ? 'bottom: 0;' : ''}
        `
        : `
          position: relative;
        `
    }
    display: block;
    font-size: 0;
    width: 100vw;
    ${height ? 'height: 0;' : ''}
    ${height ? `padding-top: ${height}vw;` : ''}
    ${marginTop ? `margin-top: ${marginTop}vw;` : ''}
    ${marginBottom ? `margin-bottom: ${marginBottom}vw;` : ''}
    ${image ? `background-image: url(${image});` : ''}
    ${image ? 'background-size: 100% auto;' : ''}
    transform:${flipY ? 'scaleY(-1)' : ''}${offsetY ? ` translateY(${offsetY}vw)` : ''}${!flipY && !offsetY ? 'none' : ''};
  `;
});

export const StyledGradientSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
