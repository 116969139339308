import styled from 'styled-components';
import { CSSProperties } from 'react';

interface Props {
  localTheme: CSSProperties;
}

export default styled.div(
  ({ localTheme: theme }: Props) => `
  ${theme.backgroundImage ? `background-image: ${theme.backgroundImage}, ${theme.background};` : ''}
  ${theme.backgroundColor ? `background-color: ${theme.backgroundColor};` : ''}
  ${theme.backgroundPosition ? `background-position: ${theme.backgroundPosition};` : ''}
  ${theme.backgroundRepeat ? `background-repeat: ${theme.backgroundRepeat};` : ''}
  ${theme.backgroundAttachment ? `background-attachment: ${theme.backgroundAttachment};` : ''}
  ${!theme.backgroundImage ? `background: ${theme.background};` : ''}
  ${theme.backgroundSize ? `background-size: ${theme.backgroundSize}` : ''}
  ${theme.color ? `color: ${theme.color}` : ''}
  ${theme.fill ? `fill: ${theme.fill}` : ''}
`,
);
