import React from 'react';
import { LineupType } from '../../types';
import { ColumnBlock } from '../Block';
import LineupRow from '../../components/LineupRow';
import ArtistConsumer from '../../context/ArtistContext';

interface Props extends LineupType {
  title: string;
}

const LineupList: React.FunctionComponent<Props> = ({ title, artists }) => (
  <ArtistConsumer>
    {({ artist: selectedArtist, updateArtist }) => (
      <ColumnBlock title={title}>
        <div>
          {artists.map((artist) => (
            <LineupRow
              {...artist}
              fade={!!selectedArtist && selectedArtist.wordpress_id !== artist.wordpress_id}
              selectArtist={(id) => updateArtist(artists.find(({ wordpress_id }) => wordpress_id === id))}
            />
          ))}
        </div>
      </ColumnBlock>
    )}
  </ArtistConsumer>
);

export default LineupList;
