import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';

export const StyledSection = styled(CSSParallax.Section)`
  background: linear-gradient(to bottom, ${theme.colors.purple}, ${theme.colors.hotPink});
  background-color: ${theme.colors.hotPink};
  color: ${theme.colors.white};
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
  margin-top: -1px;
`;
