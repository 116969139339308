import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-pink-3.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const PinkSplatter3: React.StatelessComponent<WidthHeightGreebleType> = ({ left, bottom, zIndex }) => (
  <StyledGreeble left={left} flipX={true} bottom={bottom} width={30} height={30} zIndex={zIndex} image={SplatterImage} />
);

export default PinkSplatter3;
