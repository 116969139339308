import styled from 'styled-components';

export default styled.a(
  ({ theme: { spacing, linearGradient } }) => `
    padding: ${spacing.x2} ${spacing.x4};
    border-radius: 4px;
    line-height: 1;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s cubic-bezier(0.42, 0.18, 0.11, 1.31);
    backface-visibility: hidden; 

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-style: solid;
      border-width: 2px;
      border-image-source: ${linearGradient.blueGreenBottom};
      border-image-slice: 1;
      background-image: rgba(0, 0, 0, 0.5), ${linearGradient.blueGreenBottom};
    }


    &:hover {
      text-decoration: none;
      transform: scale(1.03);
    }

    & > * {
      background-image: ${linearGradient.blueGreenLeft};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
`,
);
