import React from 'react';
import { SOCIALS, WEBSITES } from '../../constants';
import { PageLinkType } from '../../types';
import IconButton from '../IconButton';
import SiteMap from '../SiteMap';
import SocialLinks from '../SocialLinks';
import Image from '../Image';

import FooterWrapper, {
  BackToTop,
  ContentWrapper,
  Copyright,
  Credits,
  Partners,
  SiteLinks,
  SiteMapWrapper,
  SocialLinksWrapper,
} from './styles';

const handleScroll = (pageWrapId) => {
  const page = pageWrapId ? document.getElementById(pageWrapId) : window;

  if (page) {
    page.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

interface Props {
  mainMenuItems: PageLinkType[];
  secondaryFooterItems?: PageLinkType[];
  partnerItems?: any;
  partnerTopItems?: any;
  pageWrapId?: string;
}

const Footer: React.FunctionComponent<Props> = ({ mainMenuItems, secondaryFooterItems, partnerTopItems, partnerItems, pageWrapId }) => (
  <FooterWrapper>
    <ContentWrapper>
      <BackToTop>
        <IconButton type="toTop" text="Back to top" reverse={true} textStyle="h9" onClick={() => handleScroll(pageWrapId)} />
      </BackToTop>
      <SiteMapWrapper>
        <SiteMap items={mainMenuItems} />
      </SiteMapWrapper>
      <Partners>
        {partnerTopItems && (
          <div>
            {partnerTopItems.length &&
              partnerTopItems.map(
                ({ node: { featuredImage, permalink } }, index) =>
                  featuredImage && (
                    <a key={`a-${index}`} href={permalink ? permalink : '#'} target={permalink ? '_blank' : ''}>
                      <Image {...featuredImage} />
                    </a>
                  ),
              )}
          </div>
        )}
        {partnerItems && (
          <div>
            {partnerItems.length &&
              partnerItems.map(
                ({ node: { featuredImage, permalink } }, index) =>
                  featuredImage && (
                    <a key={`a-${index}`} href={permalink ? permalink : '#'} target={permalink ? '_blank' : ''}>
                      <Image {...featuredImage} />
                    </a>
                  ),
              )}
          </div>
        )}
      </Partners>
      <Copyright>
        <span className="h9 subcontent">© Copyright SITG 2019</span>
      </Copyright>
      <SocialLinksWrapper>
        <SocialLinks links={SOCIALS} />
      </SocialLinksWrapper>
      {secondaryFooterItems && (
        <SiteLinks>
          {secondaryFooterItems.map(({ title, url }: PageLinkType, index) => (
            <a key={index} href={url}>
              <span className="h9 subcontent">{title}</span>
            </a>
          ))}
        </SiteLinks>
      )}
      <Credits className="h9 subcontent">
        <span>
          Original Art & Creative by{' '}
          <a href={WEBSITES.LEE_MCCONNELL} target="_blank">
            Lee McConnell
          </a>
        </span>
        <div className="separator">{'•'}</div>
        <span>
          Website by{' '}
          <a href={WEBSITES.BOLSTER} target="_blank">
            Bolster
          </a>
        </span>
      </Credits>
    </ContentWrapper>
  </FooterWrapper>
);

export default Footer;
