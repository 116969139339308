import styled from 'styled-components';
import { ThemeType } from '../../types';
import { RowBlock } from '../Block';
import { Column, Container } from '../Block/styles';

export default styled(RowBlock)(
  ({ theme: { tabletView, mobileView, spacing, screenWidths, singleUnit } }: ThemeType) => `
    ${Container} {
      max-width: ${screenWidths.tablet + singleUnit * 20}px;
    }

    ${Column} {      
      &:first-of-type {
        flex: 1 56%;

        h2 {
           margin-bottom: 16px; 
        }

        h3 { 
          margin-bottom: 64px; 

          ${mobileView} {
              margin-bottom: ${spacing.x6};
          }
        }
      }

      &:nth-of-type(2) {
        flex: 1 44%;

        ${tabletView} {
           display: none; 
        }
      }
    }
`,
);
