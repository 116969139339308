import React from 'react';
import { Link } from '../../styles/components';
import { ColumnBlockType, RowBlockType, CtaType } from '../../types';
import getSafe from '../../utils/getSafe';
import Block, { Column, Container, FlexContainer, HeaderRow, LinkWrapper, Title, TitleWrapper } from './styles';

interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  ctaStyling?: boolean;
  titleColor?: string;
}

const blockStyle = {
  small: {
    title: 'h3',
    subtitle: 'h7',
  },
  medium: {
    title: 'h1',
    subtitle: 'h5',
  },
  large: {
    title: 'h0',
    subtitle: 'h4',
  },
};

export const CtaLink: React.StatelessComponent<CtaType> = ({ text, link: { target, url } }) => (
  <LinkWrapper>
    <Link href={url} target={target} data-test="Link" className="h7">
      <span className="h7" data-test="LinkText">
        {text}
      </span>
    </Link>
  </LinkWrapper>
);

export const RowBlock: React.FunctionComponent<RowBlockType & Props> = ({
  title,
  subTitle,
  cta,
  operations,
  type = 'medium',
  children,
  className,
  ctaStyling,
  titleColor,
}) => {
  const hasCta = cta && getSafe(() => cta.link.url);

  return (
    <Block hasLink={hasCta || ctaStyling} className={className}>
      <FlexContainer>
        <Column>
          {title && (
            <Title color={titleColor} type={type} className={blockStyle[type].title} data-test="Title">
              {title}
            </Title>
          )}
          {subTitle && (
            <h3 className={blockStyle[type].subtitle} data-test="SubTitle">
              {subTitle}
            </h3>
          )}
          {cta && hasCta && <CtaLink {...cta} />}
          {operations && operations}
        </Column>
        {children && <Column>{children}</Column>}
      </FlexContainer>
    </Block>
  );
};

export const ColumnBlock: React.StatelessComponent<ColumnBlockType & Props> = ({
  id,
  title,
  subTitle,
  cta,
  type = 'large',
  children,
  className,
  ctaStyling,
  titleColor,
}) => {
  const hasCta = cta && getSafe(() => cta.link.url);

  return (
    <Block hasLink={hasCta || ctaStyling} className={className} id={id}>
      {title && (
        <HeaderRow hasLink={hasCta || ctaStyling} type={type}>
          <TitleWrapper>
            <Title color={titleColor} type={type} className={blockStyle[type].title} data-test="Title">
              {title}
            </Title>
            {subTitle && (
              <h3 className={blockStyle[type].subtitle} data-test="SubTitle">
                {subTitle}
              </h3>
            )}
          </TitleWrapper>
          {cta && hasCta && <CtaLink {...cta} />}
        </HeaderRow>
      )}
      {children && <Container>{children}</Container>}
    </Block>
  );
};

export default ColumnBlock;
