import styled from 'styled-components';
import { Link } from '../../styles/components';
import { ThemeType, BlockSizeType } from '../../types';

interface Props extends ThemeType {
  hasLink?: boolean;
  type?: 'small' | 'medium' | 'large';
}

interface TitleProps extends Props {
  color?: string;
}

export default styled.section(
  ({ hasLink = false, theme: { mobileView, spacing, fontSizes } }: Props) => `
      font-size: ${fontSizes.xs};
      padding: 0 ${spacing.x6};
      width: 100%;
      position: relative;      

    & + & {
      margin-top: ${spacing.x20};
    }

    ${mobileView} {
      padding: 0 ${spacing.x3};      
      padding-bottom: ${hasLink ? spacing.x14 : '0'};      

      & + & {
        margin-top: ${spacing.x8};
      }
    }
`,
);

export const HeaderRow = styled.div(
  ({ hasLink, type, theme: { mobileView, spacing, width } }: Props & { type: BlockSizeType }) => `
    display: flex;    
    flex-direction: row;
    align-items: flex-end;
    justify-content: ${hasLink ? 'space-between' : 'flex-start'};
    max-width: ${width.tablet};
    margin: 0 auto;
    padding-bottom: ${type === 'large' ? spacing.x12 : type === 'medium' ? spacing.x5 : spacing.x5};

    ${Link} {
      display: block;
    }

    ${mobileView} {
      padding-bottom: ${type === 'large' ? spacing.x8 : type === 'medium' ? spacing.x3 : spacing.x3};
    }
  `,
);

export const Title = styled.h2(
  ({ type, color }: TitleProps) => `
  line-height: 1.1;

  ${type && type === 'large' ? 'max-width: 650px;' : ''}
  ${
    color
      ? `
    color: ${color};
    text-decoration-color: ${color};
    `
      : ''
  }
`,
);

export const TitleWrapper = styled.div(
  ({ theme: { spacing, mobileView } }: ThemeType) => `
    display: flex;
    flex-direction: column;
    padding-right: ${spacing.x2};
    max-width: 650px;

    ${mobileView} {
      padding-right: 0;
    }

    h3 {
      margin-top: ${spacing.x2};
    }
  `,
);

export const LinkWrapper = styled.div(
  ({ theme: { mobileView } }) => `
    ${mobileView} {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      z-index: 10;
      justify-content: center;
    }
  `,
);

export const Container = styled.div(
  ({ theme: { width } }: ThemeType) => `
    margin: 0 auto;
    max-width: ${width.tablet};`,
);

export const Column = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    flex: 1 50%;

    ${mobileView} {
      flex-direction: column;
    }

    &:first-of-type {
      max-width: 690px;
    }
  `,
);

export const FlexContainer = styled(Container)(
  ({  }: ThemeType) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
);
