import React from 'react';
import StyledDivider, { StyledGradientSvg } from './style';

interface Props {
  flipY?: boolean;
  color: string;
  height: number;
}

const Gradient: React.StatelessComponent<Props> = ({ color, height, flipY }) => (
  <StyledDivider flipY={flipY} height={height} marginBottom={height * -1}>
    <StyledGradientSvg viewBox={`0 0 100 ${height}`}>
      <defs>
        <linearGradient id="myGradient" gradientTransform="rotate(90)">
          <stop offset="0" stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
      <rect x="0" y="0" width="100" height={height} fill="url('#myGradient')" />
    </StyledGradientSvg>
  </StyledDivider>
);

export default Gradient;
