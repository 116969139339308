import styled from 'styled-components';
import { CarouselBlock } from '../../components/Carousel';
import { Slide, ImageContainer, ContentContainer } from '../../components/Carousel/styles';
import { ThemeType } from '../../types';

export default styled(CarouselBlock)(
  ({ theme: { colors } }: ThemeType) => ` 
    .slick-list {
      overflow: visible !important;
    }

    ${Slide} {
      ${ImageContainer} {
        overflow: visible !important;
        
        a {
          transition: box-shadow .3s;              
        }
      }      

      ${ContentContainer} {
        text-decoration: none;
      }  
      
      @media (pointer: fine) {
        &:hover {
          ${ImageContainer} {
            a {
              box-shadow: 0 0 32px 8px ${colors.green};
            }
          }   
        }              
      }
    }
  `,
);
