import { format } from 'date-fns';
import React from 'react';
import { ArtistType } from '../../types';
import Image from '../Image';
import SocialLinks from '../SocialLinks';
import SpotifyEmbed from '../SpotifyEmbed';
import Video from '../Video';
import ArtistWrapper, { ArtistDetail, Bio, ContentWrapper, LeadingContent, SocialsWrapper, Title } from './styles';
import MySplendourArtist from '../MySplendourArtist';

const Artist: React.StatelessComponent<ArtistType & { showSubscribeModal: () => void }> = ({
  showSubscribeModal,
  wordpress_id: id,
  title,
  featuredImage,
  data: { bio, socials, spotify, sessions, video },
}) => (
  <ArtistWrapper id={id}>
    <LeadingContent>
      {video && video.url ? (
        <Video {...video} poster={featuredImage} data-test="Video" />
      ) : (
        <Image {...featuredImage} data-test="FeaturedImage" />
      )}
    </LeadingContent>
    {spotify && spotify.embed && <SpotifyEmbed {...spotify} />}
    <ContentWrapper>
      <SocialsWrapper>
        <SocialLinks links={socials} />
      </SocialsWrapper>
      <div>
        <Title className="h3" data-test="Header">
          {title}
        </Title>
        <div style={{ marginBottom: 10 }}>
          {sessions &&
            sessions.map(({ sessionDate, startTime, endTime, stage }, index) => (
              <ArtistDetail key={index} className="p4">
                {sessionDate && format(sessionDate, 'dddd')}
                {stage && ` • ${stage}`}
                {startTime && endTime && ` • ${format(startTime, 'h:mma')}`}
              </ArtistDetail>
            ))}
        </div>

        <MySplendourArtist style={{ marginBottom: 32 }} artistID={id} showSubscribeModal={showSubscribeModal} />

        <Bio className="p4" dangerouslySetInnerHTML={{ __html: bio }} />
      </div>
    </ContentWrapper>
  </ArtistWrapper>
);

export default Artist;
