import styled from 'styled-components';
import StyledAnchor from '../IconLink/styles';

export default styled.div(
  ({ theme: { mobileView, spacing } }) => `
    width: 100%;
    margin: 0 auto;
    padding-bottom: ${spacing.x8};

    ${mobileView} {
      border-bottom-width: 0;
    }

    &:not(:last-child) {
      padding-bottom: ${spacing.x6};
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: black;
    }
`,
);

export const ContentWrapper = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    display: flex;
    flex-direction: column;

    ${mobileView} {
      flex-direction: column-reverse;
      padding-top: ${spacing.x3};
    }
`,
);

export const Title = styled.h3(
  ({ theme: { mobileView, spacing, fontSizes } }) => `
    padding-bottom: ${spacing.x2};
    ${mobileView} {
      font-size: ${fontSizes.lg} !important;
    }
`,
);

export const LeadingContent = styled.div`
  width: 100%;
  height: auto;
`;

export const SocialsWrapper = styled.div(
  ({ theme: { mobileView, colors, spacing } }) => `
    padding: ${spacing.x3} 0 0;

    ${StyledAnchor} {
      svg {
        margin-bottom: ${spacing.x3};

        ${mobileView} {
          fill: ${colors.black};
        }
      }
    }
`,
);

export const ArtistDetail = styled.div(
  ({ theme: { mobileView, colors, spacing, fontSizes } }) => `
  color: ${colors.green}

  &::not(:last-child) {
    padding-bottom: ${spacing.x2};
  }

  ${mobileView} {
    color: ${colors.hotPink};
    font-size: ${fontSizes.xs} !important;
  }
`,
);

export const Bio = styled.div(
  ({ theme: { mobileView, fontSizes } }) => `
  p {
    margin: 0;
  }
  ${mobileView} {
    font-size: ${fontSizes.xxs} !important;
  }
`,
);
