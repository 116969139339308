import React, { CSSProperties } from 'react';
import { MenuType } from '../../types';
import IconButton from '../IconButton';
import Overspray from '../Overspray';
import { MenuBody, MenuContent, SlideMenuBg, SlideMenuWrapper } from './styles';
import { useKeyPress } from '../../hooks';

interface Props extends MenuType {
  children: React.ReactNode;
  theme: CSSProperties;
}

const SlideMenu: React.FunctionComponent<Props> = ({
  isOpen,
  right,
  children,
  close,
  theme,
  openSubscribeMenu,
  showSubscribe = false,
  scroll = false,
  width,
  mobileWidth,
  maxWidth,
  showOverspray = false,
}) => {
  useKeyPress('Escape', close);

  return (
    <SlideMenuWrapper isOpen={isOpen} right={!!right}>
      <SlideMenuBg localTheme={theme} width={width} maxWidth={maxWidth} mobileWidth={mobileWidth} isOpen={isOpen} right={!!right} />
      <MenuBody
        color={theme.color}
        width={width || '100%'}
        maxWidth={maxWidth}
        mobileWidth={mobileWidth}
        isOpen={isOpen}
        right={!!right}
        data-test="SlideMenu"
      >
        {showOverspray && <Overspray height="70vh" />}
        <MenuContent right={!!right} scroll={scroll}>
          <div className="menu-header">
            {showSubscribe && <IconButton type="paperPlane" text="Subscribe" onClick={openSubscribeMenu || (() => null)} />}
            <IconButton type="close" onClick={close} data-test="CloseButton" fill={theme.fill} />
          </div>
          {children}
        </MenuContent>
      </MenuBody>
    </SlideMenuWrapper>
  );
};

export default SlideMenu;
