import React from 'react';
import { SectionFooterType } from '../../../types';
import { StyledFooter } from './style';

const Footer: React.StatelessComponent<SectionFooterType> = ({ children, height, offsetAbove, offsetBelow }) => (
  <StyledFooter
    paddingTop={height ? `${height}%` : undefined}
    marginTop={offsetAbove ? `-${offsetAbove}%` : undefined}
    marginBottom={offsetBelow ? `-${offsetBelow}%` : undefined}
  >
    {children}
  </StyledFooter>
);

export default Footer;
