import styled from 'styled-components';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  selected: boolean;
  mySplendour: boolean;
}

export default styled.div(
  () => `
`,
);

export const OptionButton = styled.button(
  ({ theme: { colors, mobileView, spacing }, selected, mySplendour }: Props) => `
    color: ${mySplendour ? colors.pink : colors.green};
    border-color: ${mySplendour ? colors.pink : colors.green};
    border-radius: 100px;
    cursor: pointer;
    padding: ${spacing.x2} ${spacing.x3};
    border: 2px solid ${mySplendour ? colors.pink : colors.green}; !important;
    background-color: transparent;
    outline: none;
    transition: 0.3s;
    margin-top: ${spacing.x1};
    margin-bottom: ${spacing.x1};

    &:not(:last-child) {
      margin-right: ${spacing.x3};
    }

    &:hover {
      color: ${colors.black};
      background-color: ${mySplendour ? colors.pink : colors.green};
    }

    ${
      selected
        ? `
      color: ${colors.black};
      background-color: ${mySplendour ? colors.pink : colors.green};
    `
        : ''
    }

    ${mobileView} {
      padding: ${spacing.x1} ${spacing.x2};

      &:not(:last-child) {
        margin-right: ${spacing.x2};
        border-radius: 20px;
      }
    }
`,
);
