import React from 'react';
import { ExternalLinkIcon } from '../../icons';
import StyledExternalLink from './styles';

interface Props {
  href: string;
  text: string;
  className?: string;
  target?: string;
}

const ExternalLink: React.FunctionComponent<Props> = ({ text, ...rest }) => (
  <StyledExternalLink target="_blank" data-test="Link" {...rest}>
    <div className="h5" data-test="Text">
      {text}
    </div>
    <ExternalLinkIcon size="sm" />
  </StyledExternalLink>
);

export default ExternalLink;
