import React from 'react';
import Ticket from '../../components/Ticket';
import { TicketBlockType } from '../../types';
import { TicketsWrapper } from './styles';
import ColumnBlock from '../Block';

export const TicketBlock: React.FunctionComponent<TicketBlockType> = ({ title, subTitle, tickets }) => (
  <ColumnBlock title={title} subTitle={subTitle} type="small">
    <TicketsWrapper>
      {tickets && tickets.map((ticket, index) => <Ticket key={`ticket-${index}`} {...ticket} data-test="Ticket" />)}
    </TicketsWrapper>
  </ColumnBlock>
);

export default TicketBlock;
