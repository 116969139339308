import styled from 'styled-components';

export default styled.div(
  ({hasSidebar, theme}) =>`
  max-width: 100%;
  width: ${hasSidebar ? 'calc(100% - 300px)': 'auto'};
  ${theme.movileView} {
    width: 100%;
  }
`);
