import React, { SyntheticEvent } from 'react';
import { ICONS } from '../../constants';
import { IconType, IconSizeType } from '../../types';
import Button, { StyledSpan } from './styles';

interface Props {
  type: IconType;
  onClick: (e: SyntheticEvent) => void;
  size?: IconSizeType;
  text?: string;
  reverse?: boolean;
  textStyle?: string;
  fill?: string;
  className?: string;
}

const IconButton: React.StatelessComponent<Props> = ({ type, text, onClick, size, textStyle, reverse, fill, ...props }) => {
  const Component: React.FunctionComponent<any> = ICONS[type];

  return (
    <Button {...props} onClick={onClick} hasText={!!text} reverse={!!reverse} type="unstyled" data-test="Button">
      <div>
        <Component size={size} fill={fill} />
      </div>
      {text && (
        <StyledSpan className={textStyle || 'p4'} data-test="Text">
          {text}
        </StyledSpan>
      )}
    </Button>
  );
};

export default IconButton;
