import React from 'react';
import StyledLayer from './style';

interface LayerProps {
  depth: number;
  appearance?: React.CSSProperties;
  natural?: boolean;
}

const Layer: React.StatelessComponent<LayerProps> = ({ children, depth, appearance, natural }) => (
  <StyledLayer depth={depth} style={appearance} natural={natural}>
    {children}
  </StyledLayer>
);

export default Layer;
