import styled from 'styled-components';
import { ThemeType } from '../../types';

export const VideosWrapper = styled.div(
  ({ theme: { mobileView, spacing, colors } }: ThemeType) => `
    display: flex;

    .main-video {
      flex: 1 auto;
      margin-right: ${spacing.x6};
      
      ${mobileView} {
        width: 100%;
        margin-right: 0;
        margin-bottom: ${spacing.x10};
      }          
      .video-player, 
      .video-poster {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        width: 100%;
        background-color: ${colors.black};
        
        & > *:first-child {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .video-player {
        margin-bottom: ${spacing.x4};

        ${mobileView} {
          margin-bottom: ${spacing.x2};
        }
      }

      h3 {
        text-transform: inherit;

        ${mobileView} {
          font-size: 20px;
        }
      }
    }

    .video-playlist {
      display: flex;
      flex-direction: column;
      flex: 0 192px;
      justify-content: flex-start;
      
      .video-link {
        cursor: pointer;
        margin-bottom: ${spacing.x2};

        h4 {
          text-transform: inherit;
        }
        
        .video-poster {
          transition: transform  0.2s;
          margin-bottom: ${spacing.x1};        
          
          iframe {
            min-width: auto !important;
            min-height: 100px;
          }

          ${mobileView} {
            margin-bottom: ${spacing.x2};
          }
          
          .react-player {
            pointer-events: none;
          }

          &:hover {
            transform: scale(1.05);
          }          
        }
      }

      ${mobileView} {
        min-width: 100%;
        width: auto;
        flex-direction: row;
        overflow: auto;
        justify-content: flex-start;
        flex: auto;
        
        .video-link {          
          min-width: 188px;
          margin-right: ${spacing.x3};          
        }

        h4 {
          font-size: 14px;
        }
      }
      
      .active {
        display: none;
      }
    }
    
    ${mobileView} {
      flex-direction: column;
    }
  `,
);
