import React from 'react';
import Image from '../../components/Image';
import SocialLinks from '../../components/SocialLinks';
import { SocialType } from '../../types';
import StyledRowBlock, {
  ImagesWrapper,
  ImageWrapper1,
  ImageWrapper2,
  ImageWrapper3,
  ImageWrapper4,
  ImageWrapperInner1,
  ImageWrapperInner2,
  ImageWrapperInner3,
  ImageWrapperInner4,
  SocialLinksWrapper,
} from './styles';

export const Social: React.StatelessComponent<SocialType> = ({ title, socials, images }) => {
  return (
    <StyledRowBlock
      title={title}
      operations={
        <SocialLinksWrapper>
          <SocialLinks links={socials} size="md" />
        </SocialLinksWrapper>
      }
    >
      <ImagesWrapper className="images" data-test="Images">
        {images && images[3] && (
          <ImageWrapper4>
            <ImageWrapperInner4>
              <Image {...images[3]} />
            </ImageWrapperInner4>
          </ImageWrapper4>
        )}
        {images && images[2] && (
          <ImageWrapper3>
            <ImageWrapperInner3>
              <Image {...images[2]} />
            </ImageWrapperInner3>
          </ImageWrapper3>
        )}
        {images && images[0] && (
          <ImageWrapper1>
            <ImageWrapperInner1>
              <Image {...images[0]} />
            </ImageWrapperInner1>
          </ImageWrapper1>
        )}
        {images && images[1] && (
          <ImageWrapper2>
            <ImageWrapperInner2>
              <Image {...images[1]} />
            </ImageWrapperInner2>
          </ImageWrapper2>
        )}
      </ImagesWrapper>
    </StyledRowBlock>
  );
};

export default Social;
