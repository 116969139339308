import { distanceInWordsToNow } from 'date-fns';
import React from 'react';
import Image from '../../components/Image';
import Video from '../../components/Video';
import { HorizontalAlignmentType, PostType } from '../../types';
import ColumnBlock from '../Block';
import ContentWrapper, { Media, Published } from './styles';

interface Props {
  alignment?: HorizontalAlignmentType;
  item: PostType;
}

export const FeaturedPost: React.FunctionComponent<Props> = ({
  alignment = 'left',
  item: { published, title, excerpt, featuredImage, featuredVideo, slug, postType, sponsored },
}) => {
  return (
    <ColumnBlock>
      <ContentWrapper alignment={alignment} postType={postType}>
        <Media>
          {featuredVideo && featuredVideo.url ? (
            <Video {...featuredVideo} poster={featuredImage} data-test="Video" />
          ) : (
            <a href={slug} data-test="Link-Title">
              <Image {...featuredImage} data-test="Image" />
            </a>
          )}
        </Media>
        {postType !== 'partner' && (
          <section className="title">
            {published && postType === 'post' && (
              <Published className="p4">{distanceInWordsToNow(published, { addSuffix: true })}</Published>
            )}
            <a href={slug} data-test="Link-Title">
              <h2 className="h2" data-test="Title">
                {title}
              </h2>
              {sponsored && (
                <span data-test="Sponsored">
                  Sponsored content
                </span>
              )}
            </a>
          </section>
        )}
        <a href={slug} target={postType === 'partner' && '_blank'} data-test="Link-Content">
          <div className="p3 content" dangerouslySetInnerHTML={{ __html: excerpt }} data-test="Content" />
        </a>
      </ContentWrapper>
    </ColumnBlock>
  );
};

export default FeaturedPost;
