import React from 'react';
import SocialLogin from 'react-social-login';
import { set as setSessionStorage } from '../../../utils/sessionStorage';

class Button extends React.Component<any, any> {
  render() {
    const { children, triggerLogin, ...props } = this.props;

    return (
      <a
        onClick={() => {
          setSessionStorage('origin', window.location.href);
          triggerLogin();
        }}
        {...props}
      >
        {children}
      </a>
    );
  }
}

export default SocialLogin(Button);
