import styled from 'styled-components';
import { SectionThemeType } from '../../types';

export default styled.section(({  }: SectionThemeType) => '');

export const StyledBackgroundWrapper = styled.div`
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledBackground = styled.div(
  ({ sectionTheme }: SectionThemeType) => `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${sectionTheme && sectionTheme.background ? `background: ${sectionTheme.background};` : ''}
    ${sectionTheme && sectionTheme.backgroundImage ? `background-image: ${sectionTheme.backgroundImage};` : ''}
    ${sectionTheme && sectionTheme.backgroundColor ? `background-color: ${sectionTheme.backgroundColor};` : ''}
    ${sectionTheme && sectionTheme.backgroundPosition ? `background-position: ${sectionTheme.backgroundPosition};` : ''}
    ${sectionTheme && sectionTheme.backgroundRepeat ? `background-repeat: ${sectionTheme.backgroundRepeat};` : ''}
    ${sectionTheme && sectionTheme.backgroundSize ? `background-size: ${sectionTheme.backgroundSize};` : ''}
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top;
    transform: translateZ(0);
    will-change: transform;
`,
);
