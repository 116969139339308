import React from 'react';
import StageHeaderImage1 from '../../../assets/images/stage-header-4.png';
import { WidthHeightGreebleType } from './types';
import { StyledStageHeader, StyledBlock } from './style';

const StageHeader1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledBlock>
    <StyledStageHeader top={top} bottom={bottom} right={0} width={40} height={40 * (393 / 469)} image={StageHeaderImage1} />
  </StyledBlock>
);

export default StageHeader1;
