import React from 'react';
import StageHeaderImage1 from '../../../assets/images/stage-header-1.png';
import { WidthHeightGreebleType } from './types';
import { StyledStageHeader, StyledBlock } from './style';

const StageHeader1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledBlock>
    <StyledStageHeader top={top} bottom={bottom} right={-10} width={60} height={60 * (328 / 600)} image={StageHeaderImage1} />
  </StyledBlock>
);

export default StageHeader1;
