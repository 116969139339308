import styled, { keyframes, css } from 'styled-components';

const generateX = () => {
  const rotate = generateRotation();
  const distance = generateDistance();

  return keyframes`
    0% {
      transform: translateX(${distance / 2}px) rotate(${rotate / 2}deg);
    }
    50% {
      transform: translateX(${(distance / 2) * -1}px) rotate(${(rotate / 2) * -1}deg);
    }
    100% {
      transform: translateX(${distance / 2}px) rotate(${rotate / 2}deg);
    }
  `;
};

const generateY = () => {
  const rotate = generateRotation();
  const distance = generateDistance();

  return keyframes`
    0% {
      transform: translateY(${distance / 2}px) rotate(${rotate / 2}deg);
    }
    50% {
      transform: translateY(${(distance / 2) * -1}px) rotate(${(rotate / 2) * -1}deg);
    }
    100% {
      transform: translateY(${distance / 2}px) rotate(${rotate / 2}deg);
    }
  `;
};

const generateRotation = () => {
  return Math.random() * 10 - Math.random() * 10;
};

const generateDistance = () => {
  return Math.random() * 30;
};

const generateDelay = () => {
  return Math.random() * 100 * -1;
};

const generateDuration = () => {
  return 18 + Math.random() * 10;
};

export const generateFloaterOuterCss = () => css`
  animation: ${generateX()} ${generateDuration()}s ease-in-out infinite;
  animation-delay: -${generateDelay()}s;
`;

export const generateFloaterInnerCss = () => css`
  animation: ${generateY()} ${generateDuration()}s ease-in-out infinite;
  animation-delay: -${generateDelay()}s;
`;

export const FloaterOuter = styled.div`
  ${generateFloaterOuterCss()}
`;

export const FloaterInner = styled.div`
  ${generateFloaterInnerCss()}
`;
