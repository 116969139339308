import styled from 'styled-components';
import { ThemeType } from '../../../types';

interface PageType extends ThemeType {
  natural?: boolean;
}

export default styled.div(
  ({ natural, theme: { everythingButIos } }: PageType) => `
    ${
      !natural
        ? `
          ${everythingButIos} {
            height: 500px; /* fallback for older browsers */
            height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-perspective: 10vh;
            perspective: 10vh;

            @media screen and (orientation: landscape) {
              -webkit-perspective: 10vw;
              perspective: 10vw;
            }
          }
        `
        : `
          position: relative;
        `
    }
`,
);
