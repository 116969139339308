import React from 'react';
import ReactPlayer from 'react-player';
import VideoWrapper from './styles';
import { EmbedType, ImageType } from '../../types';
import { getSafe } from '../../utils';

interface Props extends EmbedType {
  poster?: ImageType;
}

const getUrl = ({ url, sizes }: ImageType) =>
  typeof url === 'string'
    ? url
    : getSafe(() => url.localFile.childImageSharp.fixed.src)
    ? url.localFile.childImageSharp.fixed.src
    : sizes['hero-width']
    ? sizes['hero-width'].url
    : sizes.large
    ? sizes.large.url
    : sizes['medium-large']
    ? sizes['medium-large'].url
    : sizes.medium
    ? sizes.medium.url
    : sizes['medium-width']
    ? sizes['medium-width'].url
    : false;

const Video: React.FunctionComponent<Props> = ({ url, poster }) => (
  <VideoWrapper>
    <div className="video-player">
      <ReactPlayer
        url={url}
        light={false}
        className="react-player"
        controls={false}
        playing={!!poster}
        height="100%"
        width="100%"
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
        }}
        data-test="Video"
      />
    </div>
  </VideoWrapper>
);
export default Video;
