import React from 'react';
import { VerticalRect } from './styles';

interface Props {
  isOpen: boolean;
}

const OpenCloseToggle: React.FunctionComponent<Props> = ({ isOpen }) => (
  <svg width="16" height="16" viewBox="0 0 48 48">
    <rect height="6" width="48" x="0" y="20" />
    <VerticalRect horizontal={isOpen} width="6" height="48" x="20" y="0" data-test="VerticalLine" />
  </svg>
);

export default OpenCloseToggle;
