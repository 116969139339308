import React from 'react';
import { MENUS, MODALS } from '../../constants/layouts';
import { LayoutState } from '../../types/Layout';

class AbstractLayout extends React.Component<any, LayoutState> {
  state = {
    showMenu: false,
    showModal: false,
  };

  PAGE_WRAP_ID = 'page-wrap';

  openMainMenu = () => this.setState({ showMenu: MENUS.MAIN });

  openSearchMenu = () => this.setState({ showMenu: MENUS.SEARCH });

  openSubscribeMenu = () => this.setState({ showMenu: MENUS.SUBSCRIBE });

  openMySplendourModal = () => this.setState({ showModal: MODALS.MYSPLENDOUR });

  closeMenu = () => this.setState({ showMenu: false });

  closeModal = () => this.setState({ showModal: false });
}

export default AbstractLayout;
