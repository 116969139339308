import React, { useState, useEffect } from 'react';
import Collapse from '@kunukn/react-collapse';
import { FaqType } from '../../types';
import OpenCloseToggle from '../OpenCloseToggle';
import AccordionWrapper, { ContentBlock, TitleRow } from './styles';
import { slugify } from '../../utils/slugify';
import isBrowser from '../../utils/isBrowser';

const Accordion: React.StatelessComponent<FaqType> = ({ title, content }) => {
  if (!isBrowser()) {
    return null;
  }

  const [showAnswer, toggleAnswer] = useState(false);

  useEffect(() => {
    if (window.location.hash === `#${slugify(title)}`) {
      toggleAnswer(!showAnswer);
    }

    return;
  }, []);

  return (
    <AccordionWrapper id={title && slugify(title)}>
      <TitleRow
        type="unstyled"
        open={showAnswer}
        onClick={() => {
          toggleAnswer(!showAnswer);
          window.history.pushState(null, '', title && '#' + slugify(title));
        }}
      >
        <span className="p3" data-test="Title">
          {title}
        </span>
        <OpenCloseToggle isOpen={showAnswer} />
      </TitleRow>
      <Collapse isOpen={showAnswer} data-test="Collapse">
        <ContentBlock className="p4" dangerouslySetInnerHTML={{ __html: content }} data-test="ContentBlock" />
      </Collapse>
    </AccordionWrapper>
  );
};

export default Accordion;
