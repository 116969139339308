import styled from 'styled-components';

export default styled.div(
  ({ height }: { height?: number }) => `
    position: relative;
    display: block;
    height: 0;
    ${height ? `padding-top: ${height}vw;` : ''}
`,
);
