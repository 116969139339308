import React, { useEffect, useRef } from 'react';
import { ExternalLinkIcon } from '../../icons';
import ContentWrapper, { FormHeader, InputWrapper, RadioGroup, StyledForm, SubmitButton, TextInput, ThankYouMessage } from './styles';

export const RadioInput: React.StatelessComponent<{ id: string; title: string; list: string[] }> = ({ id, title, list }) => (
  <div className={`_field${id}`} data-test="RadioInput">
    <FormHeader className="h6" data-test="Title">
      {title}
    </FormHeader>
    <input type="hidden" name={`field[${id}][]`} value="~|" data-test="HiddenInput" />
    <RadioGroup>
      {list.map((item: string, index) => (
        <div key={index} className="_checkbox-radio" data-test="RadioCheckbox">
          <input id={`field_${id}${item}`} type="checkbox" name={`field[${id}][]`} value={item} data-test="Input" />
          <label className="p5" htmlFor={`field_${id}${item}`} data-test="Label">
            <span />
            {item}
          </label>
        </div>
      ))}
    </RadioGroup>
  </div>
);

const SubscriptionForm: React.StatelessComponent<{ formId?: string }> = ({ formId }) => {
  const scriptRef = useRef<HTMLDivElement>(null);
  const formScript = `
  var documentReady = function(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', function() {
        if (document.readyState !== 'loading') {
          fn();
        }
      });
    }
  };

  documentReady(function() {
    window.cfields = {"2":"post_code","14":"year_of_birth","5":"would_you_like_to_receive_more_information_from_secret_sounds_1","7":"please_tell_us_your_preferred_genres_of_music"};
  window._show_thank_you = function(id, message, trackcmp_url) {
    var form = document.getElementById(id), thank_you = form.querySelector('._form-thank-you');
    document.querySelector('.sign-up-header').style.display = 'none';
    form.querySelector('._form-content').style.display = 'none';
    thank_you.innerHTML = message;
    thank_you.style.display = 'block';
    if (typeof(trackcmp_url) != 'undefined' && trackcmp_url) {
      // Site tracking URL to use after inline form submission.
      _load_script(trackcmp_url);
    }
    if (typeof window._form_callback !== 'undefined') window._form_callback(id);
  };
  window._show_error = function(id, message, html) {
    var form = document.getElementById(id), err = document.createElement('div'), button = form.querySelector('button'), old_error = form.querySelector('._form_error');
    if (old_error) old_error.parentNode.removeChild(old_error);
    err.innerHTML = message;
    err.className = '_error-inner _form_error _no_arrow';
    var wrapper = document.createElement('div');
    wrapper.className = '_form-inner';
    wrapper.appendChild(err);
    button.parentNode.insertBefore(wrapper, button);
    document.querySelector('[id^="_form"][id$="_submit"]').disabled = false;
    if (html) {
      var div = document.createElement('div');
      div.className = '_error-html';
      div.innerHTML = html;
      err.appendChild(div);
    }
  };
  window._load_script = function(url, callback) {
      var head = document.querySelector('head'), script = document.createElement('script'), r = false;
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function() {
        if (!r && (!this.readyState || this.readyState == 'complete')) {
          r = true;
          callback();
          }
        };
      }
      head.appendChild(script);
  };
  (function() {
    if (window.location.search.search("excludeform") !== -1) return false;
    var getCookie = function(name) {
      var match = document.cookie.match(new RegExp('(^|; )' + name + '=([^;]+)'));
      return match ? match[2] : null;
    }
    var setCookie = function(name, value) {
      var now = new Date();
      var time = now.getTime();
      var expireTime = time + 1000 * 60 * 60 * 24 * 365;
      now.setTime(expireTime);
      document.cookie = name + '=' + value + '; expires=' + now + ';path=/';
    }
        var addEvent = function(element, event, func) {
      if (element.addEventListener) {
        element.addEventListener(event, func);
      } else {
        var oldFunc = element['on' + event];
        element['on' + event] = function() {
          oldFunc.apply(this, arguments);
          func.apply(this, arguments);
        };
      }
    }
    var _removed = false;
    var form_to_submit = document.getElementById('${formId}');
    var allInputs = form_to_submit.querySelectorAll('input, select, textarea'), tooltips = [], submitted = false;

    var getUrlParam = function(name) {
      var regexStr = '[\?&]' + name + '=([^&#]*)';
      var results = new RegExp(regexStr, 'i').exec(window.location.href);
      return results != undefined ? decodeURIComponent(results[1]) : false;
    };

    for (var i = 0; i < allInputs.length; i++) {
      var regexStr = "field\\[(\\d+)\\]";
      var results = new RegExp(regexStr).exec(allInputs[i].name);
      if (results != undefined) {
        allInputs[i].dataset.name = window.cfields[results[1]];
      } else {
        allInputs[i].dataset.name = allInputs[i].name;
      }
      var fieldVal = getUrlParam(allInputs[i].dataset.name);

      if (fieldVal) {
        if (allInputs[i].type == "radio" || allInputs[i].type == "checkbox") {
          if (allInputs[i].value == fieldVal) {
            allInputs[i].checked = true;
          }
        } else {
          allInputs[i].value = fieldVal;
        }
      }
    }

    var remove_tooltips = function() {
      for (var i = 0; i < tooltips.length; i++) {
        tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
      }
        tooltips = [];
    };
    var remove_tooltip = function(elem) {
      for (var i = 0; i < tooltips.length; i++) {
        if (tooltips[i].elem === elem) {
          tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
          tooltips.splice(i, 1);
          return;
        }
      }
    };
    var create_tooltip = function(elem, text) {
      var tooltip = document.createElement('div'), arrow = document.createElement('div'), inner = document.createElement('div'), new_tooltip = {};
      if (elem.type != 'radio' && elem.type != 'checkbox') {
        tooltip.className = '_error';
        arrow.className = '_error-arrow';
        inner.className = '_error-inner';
        inner.innerHTML = text;
        tooltip.appendChild(arrow);
        tooltip.appendChild(inner);
        elem.parentNode.appendChild(tooltip);
      } else {
        tooltip.className = '_error-inner _no_arrow';
        tooltip.innerHTML = text;
        elem.parentNode.insertBefore(tooltip, elem);
        new_tooltip.no_arrow = true;
      }
      new_tooltip.tip = tooltip;
      new_tooltip.elem = elem;
      tooltips.push(new_tooltip);
      return new_tooltip;
    };
    var resize_tooltip = function(tooltip) {
      var rect = tooltip.elem.getBoundingClientRect();
      var doc = document.documentElement, scrollPosition = rect.top - ((window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0));
      if (scrollPosition < 40) {
        tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _below';
      } else {
        tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _above';
      }
    };
    var resize_tooltips = function() {
      if (_removed) return;
      for (var i = 0; i < tooltips.length; i++) {
        if (!tooltips[i].no_arrow) resize_tooltip(tooltips[i]);
      }
    };
    var validate_field = function(elem, remove) {
      var tooltip = null, value = elem.value, no_error = true;
      remove ? remove_tooltip(elem) : false;
      if (elem.type != 'checkbox') elem.className = elem.className.replace(/ ?_has_error ?/g, '');
      if (elem.getAttribute('required') !== null) {
        if (elem.type == 'radio' || (elem.type == 'checkbox' && /any/.test(elem.className))) {
          var elems = form_to_submit.elements[elem.name];
          if (!(elems instanceof NodeList || elems instanceof HTMLCollection) || elems.length <= 1) {
            no_error = elem.checked;
          }
          else {
            no_error = false;
            for (var i = 0; i < elems.length; i++) {
              if (elems[i].checked) no_error = true;
            }
          }
          if (!no_error) {
            tooltip = create_tooltip(elem, "Please select an option.");
          }
        } else if (elem.type =='checkbox') {
          var elems = form_to_submit.elements[elem.name], found = false, err = [];
          no_error = true;
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].getAttribute('required') === null) continue;
            if (!found && elems[i] !== elem) return true;
            found = true;
            elems[i].className = elems[i].className.replace(/ ?_has_error ?/g, '');
            if (!elems[i].checked) {
              no_error = false;
              elems[i].className = elems[i].className + ' _has_error';
              err.push("Checking %s is required".replace("%s", elems[i].value));
            }
          }
          if (!no_error) {
            tooltip = create_tooltip(elem, err.join('<br/>'));
          }
        } else if (elem.tagName == 'SELECT') {
          var selected = true;
          if (elem.multiple) {
            selected = false;
            for (var i = 0; i < elem.options.length; i++) {
              if (elem.options[i].selected) {
                selected = true;
                break;
              }
            }
          } else {
            for (var i = 0; i < elem.options.length; i++) {
              if (elem.options[i].selected && !elem.options[i].value) {
                selected = false;
              }
            }
          }
          if (!selected) {
            elem.className = elem.className + ' _has_error';
            no_error = false;
            tooltip = create_tooltip(elem, "Please select an option.");
          }
        } else if (value === undefined || value === null || value === '') {
          elem.className = elem.className + ' _has_error';
          no_error = false;
          tooltip = create_tooltip(elem, "This field is required.");
        }
      }
      if (no_error && elem.name == 'email') {
        if (!value.match(/^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
          elem.className = elem.className + ' _has_error';
          no_error = false;
          tooltip = create_tooltip(elem, "Enter a valid email address.");
        }
      }
      if (no_error && /date_field/.test(elem.className)) {
        if (!value.match(/^\d\d\d\d-\d\d-\d\d$/)) {
          elem.className = elem.className + ' _has_error';
          no_error = false;
          tooltip = create_tooltip(elem, "Enter a valid date.");
        }
      }
      tooltip ? resize_tooltip(tooltip) : false;
      return no_error;
    };
    var needs_validate = function(el) {
      return el.name == 'email' || el.getAttribute('required') !== null;
    };
    var validate_form = function(e) {
      var err = form_to_submit.querySelector('._form_error'), no_error = true;
      if (!submitted) {
        submitted = true;
        for (var i = 0, len = allInputs.length; i < len; i++) {
          var input = allInputs[i];
          if (needs_validate(input)) {
            if (input.type == 'text') {
              addEvent(input, 'blur', function() {
                this.value = this.value.trim();
                validate_field(this, true);
              });
              addEvent(input, 'input', function() {
                validate_field(this, true);
              });
            } else if (input.type == 'radio' || input.type == 'checkbox') {
              (function(el) {
                var radios = form_to_submit.elements[el.name];
                for (var i = 0; i < radios.length; i++) {
                  addEvent(radios[i], 'click', function() {
                    validate_field(el, true);
                  });
                }
              })(input);
            } else if (input.tagName == 'SELECT') {
              addEvent(input, 'change', function() {
                validate_field(this, true);
              });
            } else if (input.type == 'textarea'){
              addEvent(input, 'input', function() {
                validate_field(this, true);
              });
            }
          }
        }
      }
      remove_tooltips();
      for (var i = 0, len = allInputs.length; i < len; i++) {
        var elem = allInputs[i];
        if (needs_validate(elem)) {
          if (elem.tagName.toLowerCase() !== "select") {
            elem.value = elem.value.trim();
          }
          validate_field(elem) ? true : no_error = false;
        }
      }
      if (!no_error && e) {
        e.preventDefault();
      }
      resize_tooltips();
      return no_error;
    };
    addEvent(window, 'resize', resize_tooltips);
    addEvent(window, 'scroll', resize_tooltips);
    window._old_serialize = null;
    if (typeof serialize !== 'undefined') window._old_serialize = window.serialize;
    _load_script("//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js", function() {
      window._form_serialize = window.serialize;
      if (window._old_serialize) window.serialize = window._old_serialize;
    });
    var form_submit = function(e) {
      e.preventDefault();
      if (validate_form()) {
        // use this trick to get the submit button & disable it using plain javascript
        document.querySelector('#${formId}submit').disabled = true;
              var serialized = _form_serialize(document.getElementById('${formId}'));
        var err = form_to_submit.querySelector('._form_error');
        err ? err.parentNode.removeChild(err) : false;
        _load_script('https://secretsounds.activehosted.com/proc.php?' + serialized + '&jsonp=true');
      }
      return false;
    };
    addEvent(form_to_submit, 'submit', form_submit);
  })();
  });
  `;

  useEffect(() => {
    if (scriptRef && scriptRef.current) {
      const scriptElem = document.createElement('script');
      scriptElem.innerHTML = formScript;
      scriptRef.current.appendChild(scriptElem);
    }
  }, []);

  return (
    <ContentWrapper id="scroll-container" ref={scriptRef}>
      <h3 className="h4 sign-up-header">Sign up to receive the latest Splendour news</h3>
      <StyledForm
        method="POST"
        action="https://secretsounds.activehosted.com/proc.php"
        id={`${formId}`}
        className={`_form ${formId} _inline-form  `}
        novalidate={true}
      >
        <input type="hidden" name="u" value={formId} />
        <input type="hidden" name="f" value="18" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <div className="_form-content">
          <InputWrapper className="_x48282083">
            <TextInput type="text" name="fullname" placeholder="Full name *" />
          </InputWrapper>
          <InputWrapper className="_x48635967">
            <TextInput type="text" name="email" placeholder="Email address *" required={true} />
          </InputWrapper>
          <InputWrapper className="_field2">
            <TextInput type="text" name="field[2]" placeholder="Postcode *" required={true} />
          </InputWrapper>
          <InputWrapper className="_field14">
            <TextInput type="text" name="field[14]" placeholder="Year of birth *" required={true} />
          </InputWrapper>
          <div data-test="RadioInput">
            <FormHeader className="h6" data-test="Title">
              Receive more information from <span style={{ whiteSpace: 'nowrap' }}>Secret Sounds</span>?
            </FormHeader>
            <input type="hidden" name="field[5][]" value="~|" />
            <RadioGroup>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input
                  id={`${formId}_field_5Tours &amp; Tour Presales`}
                  type="checkbox"
                  name="field[5][]"
                  value="Tours &amp; Tour Presales"
                />
                <label className="p5" htmlFor={`${formId}_field_5Tours &amp; Tour Presales`}>
                  <span />
                  Sideshows and Tours from Secret Sounds
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_5Falls Festival`} type="checkbox" name="field[5][]" value="Falls Festival" data-test="Input" />
                <label className="p5" htmlFor={`${formId}_field_5Falls Festival`}>
                  <span />
                  Falls Festival
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_5Download Festival`} type="checkbox" name="field[5][]" value="Download Festival" />
                <label className="p5" htmlFor={`${formId}_field_5Download Festival`}>
                  <span />
                  Download Festival
                </label>
              </div>
              <div
                className="_checkbox-radio"
                data-test="RadioCheckbox"
                style={{
                  display: 'none',
                }}
              >
                <input
                  id={`${formId}_field_5Splendour in the Grass`}
                  type="checkbox"
                  name="field[5][]"
                  value="Splendour in the Grass"
                  defaultChecked={true}
                />
                <label className="p5" htmlFor={`${formId}_field_5Splendour in the Grass`}>
                  <span />
                  Splendour in the Grass
                </label>
              </div>
            </RadioGroup>
          </div>
          <div data-test="RadioInput">
            <FormHeader className="h6" data-test="Title">
              Please tell us your preferred genres of music
            </FormHeader>
            <input type="hidden" name="field[7][]" value="~|" />
            <RadioGroup>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_7Electronic and Dance`} type="checkbox" name="field[7][]" value="Electronic and Dance" />
                <label className="p5" htmlFor={`${formId}_field_7Electronic and Dance`}>
                  <span />
                  Electronic and Dance
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_7Hip Hop and R&amp;B`} type="checkbox" name="field[7][]" value="Hip Hop and R&amp;B" />
                <label className="p5" htmlFor={`${formId}_field_7Hip Hop and R&amp;B`}>
                  <span />
                  Hip Hop and R&amp;B
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_7Indie and Alternative`} type="checkbox" name="field[7][]" value="Indie and Alternative" />
                <label className="p5" htmlFor={`${formId}_field_7Indie and Alternative`}>
                  <span />
                  Indie and Alternative
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_7Punk`} type="checkbox" name="field[7][]" value="Punk" />
                <label className="p5" htmlFor={`${formId}_field_7Punk`}>
                  <span />
                  Punk
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_7Pop`} type="checkbox" name="field[7][]" value="Pop" />
                <label className="p5" htmlFor={`${formId}_field_7Pop`}>
                  <span />
                  Pop
                </label>
              </div>
              <div className="_checkbox-radio" data-test="RadioCheckbox">
                <input id={`${formId}_field_7Rock`} type="checkbox" name="field[7][]" value="Rock" />
                <label className="p5" htmlFor={`${formId}_field_7Rock`}>
                  <span />
                  Rock
                </label>
              </div>
            </RadioGroup>
          </div>
          <SubmitButton id={`${formId}submit`} className="_submit" type="submit">
            <h6 className="h6">Submit</h6>
            <ExternalLinkIcon fill="inherit" size="sm" />
          </SubmitButton>
          <p className="p6">
            By clicking submit you accept the{' '}
            <a href="/terms-and-conditions" target="_blank">
              Terms &amp; Conditions
            </a>{' '}
            and{' '}
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <ThankYouMessage className="h4 _form-thank-you" />
      </StyledForm>
    </ContentWrapper>
  );
};

export default SubscriptionForm;
