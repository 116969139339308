import React from 'react';
import Jerry from '../../Jerry';

const NearJerrySwarm: React.StatelessComponent<any> = () => (
  <>
    <Jerry size={12} top={30} left={15} rotation={27} animated={true} />
    <Jerry size={10.5} top={45} left={21} rotation={27} animated={true} />
  </>
);

export default NearJerrySwarm;
