import React from 'react';
import { ThemeProvider } from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MainMenu from '../../components/MainMenu';
import SearchMenu from '../../components/SearchMenu';
import SubscriptionMenu from '../../components/SubscriptionMenu';
import MySplendourModal from '../../components/MySplendourModal';
import ArtistMenu from '../../components/ArtistMenu';
import { MODALS, MENUS, PAGE_WRAP_ID } from '../../constants/layouts';
import FooterSection from '../../sections/Footer';
import GlobalStyle from '../../styles/GlobalStyle';
import theme from '../../styles/theme';
import AbstractLayout from './AbstractLayout';
import Head from './Head';
import { ArtistProvider } from '../../context/ArtistContext';

class Layout extends AbstractLayout {
  render() {
    const { showMenu, showModal } = this.state;
    const {
      seo,
      announcement,
      hideAnnouncement,
      headerMenu,
      mainMenu,
      secondaryFooterMenu,
      footerTopPartners,
      footerSecondaryPartners,
      headless,
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <CSSParallax.Layout>
          <GlobalStyle />
          <Head {...seo} />
          <ArtistProvider>
            {!headless && (
              <>
                <MainMenu
                  isOpen={String(showMenu) === MENUS.MAIN}
                  close={this.closeMenu}
                  openSubscribeMenu={this.openSubscribeMenu}
                  items={mainMenu && mainMenu.items}
                />
                <SearchMenu isOpen={String(showMenu) === MENUS.SEARCH} close={this.closeMenu} />
                <SubscriptionMenu isOpen={String(showMenu) === MENUS.SUBSCRIBE} close={this.closeMenu} />
                <MySplendourModal visible={String(showModal) === MODALS.MYSPLENDOUR} close={this.closeModal} updateParent={() => null} />
                <ArtistMenu showSubscribeModal={() => this.setState({ showModal: MODALS.MYSPLENDOUR })} />
                <Header
                  openMainMenu={this.openMainMenu}
                  openSearch={this.openSearchMenu}
                  openMySplendourModal={this.openMySplendourModal}
                  openSubscribeMenu={this.openSubscribeMenu}
                  pageLinks={headerMenu && headerMenu.items}
                  announcement={announcement}
                  hideAnnouncement={hideAnnouncement}
                  pageWrapId={PAGE_WRAP_ID}
                />
              </>
            )}
            <CSSParallax.Page id={PAGE_WRAP_ID}>
              {this.props.children}
              {!headless && (
                <FooterSection>
                  <Footer
                    mainMenuItems={mainMenu && mainMenu.items}
                    secondaryFooterItems={secondaryFooterMenu && secondaryFooterMenu.items}
                    partnerTopItems={footerTopPartners && footerTopPartners.edges}
                    partnerItems={footerSecondaryPartners && footerSecondaryPartners.edges}
                    pageWrapId={PAGE_WRAP_ID}
                  />
                </FooterSection>
              )}
            </CSSParallax.Page>
          </ArtistProvider>
        </CSSParallax.Layout>
      </ThemeProvider>
    );
  }
}

export default Layout;
