import styled from 'styled-components';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  horizontal: boolean;
}

export const VerticalRect = styled.rect(
  ({ horizontal }: Props) => `
  ${horizontal && 'transform: rotate(90deg);'}
  -webkit-transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transform-origin: center center;
`,
);
