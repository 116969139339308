import React, { useEffect, useState } from 'react';
import { AddButton } from './styles';
import { AddIcon, RemoveIcon } from '../../icons';
import { CSSObject } from 'styled-components';
import API from '../../agents/API';

import { get as getLocalStorage } from '../../utils/localStorage';
import { get as getSessionStorage, set as setSessionStorage } from '../../utils/sessionStorage';

interface Props {
  artistID: number | string;
  showSubscribeModal?: () => void;
  style?: CSSObject;
}

const updateArtist = async ({ token, user, saved, setSaved, artistID }) => {
  const newestUser = getSessionStorage('user');
  const artists = newestUser && newestUser.artists ? newestUser.artists : [];
  
  const { data: updatedContact } = await API.updateContact({
    token,
    contact: { artists: saved ? artists.filter((id) => artistID !== id) : [...artists, artistID] },
  });

  if (updatedContact) {
    setSessionStorage('user', JSON.stringify(updatedContact));
    setSaved(!saved);
  }
};

const checkState = ({ token, user, artistID }) => {
  const artists = user && user.artists ? user.artists : [];
  return token && artists ? artists.includes(artistID) : false;
};

const MySplendourArtist: React.FunctionComponent<Props> = ({ artistID, showSubscribeModal, ...props }) => {
  const [saved, setSaved] = useState(null);
  const token = getLocalStorage('token');
  const user = getSessionStorage('user');

  useEffect(() => setSaved(checkState({ token, user, artistID })));

  return (
    <AddButton
      {...props}
      onClick={() => {
        if (token && user) {
          updateArtist({ token, user, saved, setSaved, artistID });
        } else if (showSubscribeModal && showSubscribeModal.constructor === Function) {
          showSubscribeModal();
        }
      }}
    >
      {saved ? (
        <>
          <RemoveIcon />
          <span>Remove from My Splendour</span>
        </>
      ) : (
        <>
          <AddIcon />
          <span>Add to My Splendour</span>
        </>
      )}
    </AddButton>
  );
};

export default MySplendourArtist;
