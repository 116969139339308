import styled from 'styled-components';
import ColumnBlock from '../../blocks/Block';
import Theme from '../../types/Theme';

interface ContainerProps extends Theme {
  center?: boolean;
}

interface HeroWrapperProps extends Theme {
  textColor?: string;
  center?: boolean;
}

export const HeroWrapper = styled(ColumnBlock)(
  ({ textColor, paddingTop, theme: { spacing, mobileView } }: HeroWrapperProps) => `
    ${textColor ? `color: ${textColor};` : ''}
    text-align: left;
    padding-top: ${paddingTop ? paddingTop : spacing.x8};

    ${mobileView} {
      padding-top: ${spacing.x6};
      padding-bottom: ${spacing.x18};
    }
`,
);

export const HeroContainer = styled.div(
  ({ theme: { spacing, mobileView }, center }: ContainerProps) => `
    display: block;
    width: 100%;
    ${center ? 'text-align: center;' : 'max-width: 600px; '}

    a {
      display: block;
      line-height: 1;
      margin-bottom: ${spacing.x4};
      text-transform: uppercase;
    }

    h1 {
      margin: 0 0 ${spacing.x4} 0;
      text-transform: uppercase;
    }

    p {
      margin: 0 0 ${spacing.x2} 0;
      line-height: 1.2;
    }

    ${mobileView} {
      a {
        margin-bottom: ${spacing.x3}
      }

      h1 {
        margin: 0 0 ${spacing.x2} 0;
        font-size: 40px;
      }

      p {
        line-height: 1.6;
      }
    }
`,
);
