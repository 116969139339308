import styled from 'styled-components';

export default styled.div(
  ({ theme: { spacing, mobileView } }) => `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr) );
    grid-gap: ${spacing.x2};
    justify-items: stretch;

    ${mobileView} {
      grid-template-columns: 1fr;
      justify-items: start;
    }
`,
);

export const PrimaryLink = styled.a(
  ({ theme: { mobileView, spacing } }) => `
    display: block;
    margin-bottom: ${spacing.x1};
    ${mobileView} {
      margin-bottom: ${spacing.x3};
    }
`,
);

export const SecondaryLink = styled(PrimaryLink)(
  ({ theme: { mobileView } }) => `
    opacity: 0.5;
    ${mobileView} {
      display: none;
    }
`,
);
