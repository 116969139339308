import styled from 'styled-components';
import theme from '../../styles/theme';

const StyledLogo = styled.a(
  ({ theme: { mobileView } }) => `
  display: block;
  color: ${theme.colors.hotPink};
  font-size: 1.25em;
  ${mobileView} {
      font-size: 5vmin;
  }
`,
);

export default StyledLogo;
