import styled from 'styled-components';
import { ThemeType } from '../../types';
import Moon from '../../assets/images/moon.png';
import Jelly from '../../assets/images/jelly-fish.png';
import Bus from '../../assets/images/bus.png';
import PinkSplatter from '../../assets/images/paint-splatter-pink.png';
import BlueSplatter from '../../assets/images/paint-splatter-blue-2.png';
import WhiteSplatter from '../../assets/images/paint-splatter-white-2.png';

const Ticket = styled.div(
  ({ theme: { linearGradient, colors, spacing } }: ThemeType) => `
    background-color: ${colors.black50};
    border-image-slice: 1;
    border-image-source: ${linearGradient.blueGreenBottom};
    border-style: solid;
    border-width: 2px;
    padding: 24px 48px 32px;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: box-shadow .3s, transform .3s;

    .ticket-price {
      text-transform: uppercase;
      display: block;
      font-weight: 800;
    }

    .ticket-name {
      color: ${colors.green}
      max-width: 328px;
      margin-left: auto;
      margin-right: auto;
    }

    .ticket-description {
      color: ${colors.white}
      display: block;

      a {        
        text-decoration: underline;
      }

      & > p {        
        margin-top: 0;
        margin-bottom: ${spacing.x1};

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }    

    .ticket-link {
      padding: ${spacing.x1} ${spacing.x1} ${spacing.x1} 0;
      text-align: left;
      text-decoration: none;
      color: ${colors.green}
    }

    .ticket-terms_url {
      display: block;
      text-align: left;
      text-decoration: underline;
    }

    .ticket-terms,
    .ticket-description,
    .ticket-terms_url {
      letter-spacing: .24px;
      line-height: 1.75;
    }
  `,
);

export const FrontTicket = styled(Ticket.withComponent('a'))(
  ({ theme: { mobileView, colors, spacing, fontSizes } }: ThemeType) => `
      min-width: 352px;
      max-width: 352px;
      min-height: 450px;
      transform: translateX(0) translateY(0) rotate(0);   
      padding: ${spacing.x4} ${spacing.x3} ${spacing.x3};      
      border: none;
      cursor: pointer;
      position: relative;
      overflow: hidden;      
      display: flex;
      flex-direction: column;

      &:before, 
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        background-repeat: no-repeat;  
        background-size: cover;
        z-index: -1;
      }      

      .ticket-price {
        color: ${colors.black}
      }

      .ticket-terms {
        color: ${colors.black}
        font-weight: 800;
        line-height: 1.2;
        padding: 0 ${spacing.x3};        
        text-align: center;
        text-transform: uppercase;        
        margin-bottom: ${spacing.x1};
      }

      .ticket-name {
        color: ${colors.black}
        min-height: 96px;
        flex: 1;
      }      

      .ticket-fake_link {
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing.x1} ${spacing.x1} ${spacing.x1} ${spacing.x2};
        color: ${colors.black};
        background-color: ${colors.white};        

        svg {
          align-self: flex-start;
        }

        &:hover {
          text-decoration: underline;
        }
      }      
          
      &:nth-of-type(1) {
        background-color: ${colors.blue}
        transform: translateX(15%) translateY(45px) rotate(-30deg);
        transform-origin: 75% 0;
        z-index: 3;      

        &:before {
          top: -50px;
          right: -50px;
          width: 150px;
          height: 150px;
          background-image: url(${Moon});
        }

        &:after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(${PinkSplatter});
          background-position: 133px -210px;
          transform: scaleX(-1);
        }

        @media (max-width: 960px) {
          transform: translateX(33.33%) translateY(45px) rotate(-30deg);
        }
      }

      &:nth-of-type(2) {
        background-color: ${colors.babyPink}
        margin-left: 20px;
        margin-right: 20px;
        z-index: 2;             

        &:after {
          top: 85px;
          right: -55px;
          width: 136px;
          height: 180px;
          background-image: url(${Jelly});
        }

        &:before {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(${BlueSplatter});
          background-size: 150px auto;
          background-position: 202px -90px;
        }
      }

      &:nth-of-type(3) {
        background-color: ${colors.green}
        transform: translateX(-15%) translateY(45px) rotate(30deg);
        transform-origin: 25% 0;
        z-index: 1;

        &:before {
          top: 165px;
          right: -70px;
          width: 182px;
          height: 100%;
          background-size: contain;
          background-image: url(${Bus});
        }    
        
        &:after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(${WhiteSplatter});
          background-size: 290px auto;
          transform: rotate(18deg);
          background-position: 9px -770px;
        }

        @media (max-width: 960px) {
          transform: translateX(-33.33%) translateY(45px) rotate(30deg);
        }        
      }     

      ${mobileView} {                    
        min-width: auto;
        padding: ${spacing.x4} ${spacing.x2} ${spacing.x2};
        transform: translateX(0) translateY(0) rotate(0) !important;                         
        width: 100%;
        min-height: 327px;

        .ticket-price {
          font-size: ${fontSizes.xs};
        }

        .ticket-name {
          min-height: auto;
          font-size: ${fontSizes.lg};
        }

        .ticket-link * {
          font-size: ${fontSizes.xs};
        }

        &:nth-of-type(2) {
          margin-left: 0;
          margin-right: 0;
        }          
        
        &:not(:last-of-type) {
          margin-bottom: ${spacing.x6};        
        }
      }
    }
  `,
);

export const StyledTicket = styled(Ticket)(
  ({ theme: { mobileView, colors, linearGradient, spacing, fontSizes } }: ThemeType) => `    
    display: flex;
    flex-direction: column;

    .ticket-terms {
      text-align: center;
      margin-bottom: ${spacing.x3};
    }

    .ticket-name {
      min-height: 96px;
      margin-bottom: ${spacing.x4};
      
      ${mobileView} {
        min-height: auto;
        font-size: ${fontSizes.lg} !important;
      }
    }     

    .ticket-description {
      flex: 1;
      text-align: left;
      margin-bottom: ${spacing.x4};
    }

    .ticket-link {
      &:hover {
        text-decoration: underline;
      }
    }

    .ticket-terms_url {
      margin-top: 24px;
    }

    &.sold-out {
      border-image: none;
      border-color: ${colors.darkGrey};

      .ticket-price {
        color: ${colors.mediumGrey};
      }

      .ticket-name {
        color: ${colors.white};
      }

      .ticket-link {
        pointer-events: none;
        background-color: transparent;     
        border-image: none;
        border-color: ${colors.darkGrey};
        
        & > * {
          color: ${colors.mediumGrey};
        }
      }

      &:hover {
        box-shadow: none;
        background-image: none;
      }
    }

    &:not(.sold-out):hover {
      box-shadow: 0 0 32px 8px ${colors.green};
      background-image: ${linearGradient.blueGreenBottom};

      .ticket-price,
      .ticket-name,
      .ticket-description,
      .ticket-terms,
      .ticket-terms_url {
        color: ${colors.black};
      }

      .ticket-link {
        background-color: ${colors.black};
      }
    }    
  `,
);
