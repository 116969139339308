import styled, { keyframes, css } from 'styled-components';

const duration = 12; // Seconds

const flail = 1.3;

const swim = (size, rotation) => {
  const operator = size / 45;

  return keyframes`
    0% {
      transform: rotate(${rotation}deg) translateY(0);
      animation-timing-function: ease;
    }
    7.5% {
      transform: rotate(${rotation}deg) translateY(-${operator * 5}vw);
      animation-timing-function: ease-in;
    }
    27.5% {
      transform: rotate(${rotation}deg) translateY(-${operator * 3}vw);
      animation-timing-function: ease;
    }
    35% {
      transform: rotate(${rotation}deg) translateY(-${operator * 8}vw);
      animation-timing-function: ease-in;
    }
    100% {
      transform: rotate(${rotation}deg) translateY(0);
    }
  `;
};

const tentacle1 = keyframes`
  0% {
    transform: rotate(${flail * 7}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(-5deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(${flail * 8}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(-5deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(${flail * 7}deg);
  }
`;

const calf1 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(${flail * 7.5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(-${flail * 7.5}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(${flail * 5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

const tentacle2 = keyframes`
  0% {
    transform: rotate(${flail * 16}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(${flail * 18}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(${flail * 16}deg);
  }
`;

const calf2 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(${flail * 7.5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(-${flail * 7.5}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(${flail * 5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

const tentacle3 = keyframes`
  0% {
    transform: rotate(${flail * 5}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(${flail * 7}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(${flail * 5}deg);
  }
`;

const calf3 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(${flail * 7.5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(-${flail * 3}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(${flail * 7.5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

const tentacle4 = keyframes`
  0% {
    transform: rotate(-${flail * 8}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(-${flail * 10}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-${flail * 8}deg);
  }
`;

const calf4 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(-${flail * 2.5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(${flail * 11}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(-${flail * 2.5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

const tentacle5 = keyframes`
  0% {
    transform: rotate(-${flail * 14}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(-1deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(-${flail * 15}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(-1deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-${flail * 14}deg);
  }
`;

const calf5 = keyframes`
  0% {
    transform: rotate(3deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(-${flail * 9}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(${flail * 12}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(-${flail * 9}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(3deg);
  }
`;

const tentacle6 = keyframes`
  0% {
    transform: rotate(-${flail * 10}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(-${flail * 10}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-${flail * 10}deg);
  }
`;

const calf6 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(-${flail * 7.5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(${flail * 7.5}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(-${flail * 5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

const tentacle7 = keyframes`
  0% {
    transform: rotate(${flail * 3}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(-2deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(${flail * 3}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(-2deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(${flail * 3}deg);
  }
`;

const calf7 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(${flail * 5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(-${flail * 5}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(${flail * 5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

const tentacle8 = keyframes`
  0% {
    transform: rotate(${flail * 8}deg);
    animation-timing-function: ease;
  }
  6.5% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  27.5% {
    transform: rotate(${flail * 9}deg);
    animation-timing-function: ease;
  }
  34% {
    transform: rotate(0deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(${flail * 8}deg);
  }
`;

const calf8 = keyframes`
  0% {
    transform: rotate(-2.5deg);
    animation-timing-function: ease;
  }
  7% {
    transform: rotate(${flail * 7.5}deg);
    animation-timing-function: ease-in;
  }
  27% {
    transform: rotate(-${flail * 7.5}deg);
    animation-timing-function: ease;
  }
  34.5% {
    transform: rotate(${flail * 5}deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(-2.5deg);
  }
`;

interface StyledJerryType {
  top: number;
  left: number;
  width: number;
  height: number;
  rotation: number;
}

export const StaticJerry = styled.svg(
  ({ width, height, top, left, rotation }: StyledJerryType) => `
    position: absolute;
    top: ${top}vw;
    left: ${left}vw;
    width: ${width}vw;
    height: ${height}vw;
    transform: rotate(${rotation}deg);
`,
);

export const AnimatedJerry = styled.svg(({ width, height, top, left, rotation }: StyledJerryType) => {
  const totalDelay = Math.random() * 10 * -1;

  return css`
    position: absolute;
    top: ${top}vw;
    left: ${left}vw;
    width: ${width}vw;
    height: ${height}vw;
    overflow: visible;
    will-change: transform;
    animation: ${swim(height, rotation)} ${duration}s ease infinite;
    animation-delay: ${totalDelay + duration / 100 / (2 / 3)}s;

    .calf {
      will-change: transform;
      animation-delay: ${totalDelay + duration / 500}s !important;
    }

    .tentacle-1 {
      will-change: transform;
      transform-origin: 48px 136px;
      animation: ${tentacle1} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 21.02px 272px;
        animation: ${calf1} ${duration}s linear infinite;
      }
    }

    .tentacle-2 {
      will-change: transform;
      transform-origin: 40px 173px;
      animation: ${tentacle2} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 69.02px 270.5px;
        animation: ${calf2} ${duration}s linear infinite;
      }
    }

    .tentacle-3 {
      will-change: transform;
      transform-origin: 78px 150px;
      transform: rotate(10deg);
      animation: ${tentacle3} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 97.02px 280px;
        animation: ${calf3} ${duration}s linear infinite;
      }
    }

    .tentacle-4 {
      will-change: transform;
      transform-origin: 103px 155px;
      animation: ${tentacle4} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 114.36px 267.5px;
        animation: ${calf4} ${duration}s linear infinite;
      }
    }

    .tentacle-5 {
      will-change: transform;
      transform-origin: 126px 160px;
      animation: ${tentacle5} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 130.52px 284px;
        animation: ${calf5} ${duration}s linear infinite;
      }
    }

    .tentacle-6 {
      will-change: transform;
      transform-origin: 126px 164px;
      animation: ${tentacle6} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 93.44px 272.99px;
        animation: ${calf6} ${duration}s linear infinite;
      }
    }

    .tentacle-7 {
      will-change: transform;
      transform-origin: 88px 154px;
      animation: ${tentacle7} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 67.94px 244.75px;
        animation: ${calf7} ${duration}s linear infinite;
      }
    }

    .tentacle-8 {
      will-change: transform;
      transform-origin: 40px 144px;
      animation: ${tentacle8} ${duration}s linear infinite;
      animation-delay: ${totalDelay + (duration / 1000) * Math.floor(Math.random() * 25)}s;

      .calf {
        transform-origin: 53.52px 263.26px;
        animation: ${calf8} ${duration}s linear infinite;
      }
    }
  `;
});
