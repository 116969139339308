import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-white.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const WhiteSplatter1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} left={0} flipX={true} bottom={bottom} width={13.4} height={60.0} image={SplatterImage} />
);

export default WhiteSplatter1;
