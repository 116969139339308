import styled from 'styled-components';
import { ThemeType } from '../../types';
import tick from '../../assets/svg/tick-box.svg';

export default styled.div(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
  @import url('//d3rxaij56vjege.cloudfront.net/pikaday/1.3.3/pikaday.css');
  padding: ${spacing.x3} ${spacing.x6};
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${mobileView} {
    padding: ${spacing.x3};
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  /* Change auto compile styling in chrome */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 30px rgba(0,0,0,0); inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`,
);

export const StyledForm = styled.form(
  ({ theme: { mobileView, spacing } }: ThemeType & { novalidate?: boolean }) => `
  padding-top: ${spacing.x3};
  & > * { outline:0; }
  ${mobileView} {
    padding-bottom: 10vh;
  }
  textarea { resize:none; }

  input[type="text"]._has_error, textarea._has_error { border-bottom: #f37c7b 2px solid; color: #f37c7b; }

  input[type="checkbox"]._has_error { outline:#f37c7b 1px solid; }

  ._error {
    display:block;
    position:absolute;
    font-size:13px;
    z-index:10000001;
  }
  ._error._above {
    padding-bottom: 4px; 
    top: -${spacing.x2};
    right: 0;
  }
  ._error._below {
    padding-top:4px;
    top:100%;
    right:0;
  }
  ._error._above ._error-arrow { 
    bottom:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid #f37c7b;
  }
  ._error._below ._error-arrow { top:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:5px solid #f37c7b; }
  ._error-inner { padding:8px 12px; background-color:#f37c7b; font-size:13px; font-family:arial, sans-serif; color:#fff; text-align:center; text-decoration:none; -webkit-border-radius:4px; -moz-border-radius:4px; border-radius:4px; }
  ._error-inner._form_error { margin-bottom:5px; text-align:left; }
  ._error-inner._no_arrow { margin-bottom:10px; }
  ._error-arrow { position:absolute; width:0; height:0; }
  ._error-html { margin-bottom:10px; }

 @media all and (min-width:320px) and (max-width:667px) {
   ::-webkit-scrollbar {
     display:none;
    }
  }
 }`,
);

export const InputWrapper = styled.div`
  position: relative;
`;

export const TextInput = styled.input(
  ({ theme: { colors, fontSizes, spacing } }) => `
  &::placeholder {
    color: ${colors.black};
  }
  outline:0;
  letter-spacing: 0.2px;
  color: ${colors.black};
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: ${fontSizes.xxs};
  border-bottom: 1px solid ${colors.black};
  padding: ${spacing.x2} 0;
`,
);

export const FormHeader = styled.h6(
  ({ theme: { spacing } }) => `
  padding: ${spacing.x6} 0 ${spacing.x3};
`,
);

export const RadioGroup = styled.div(
  ({ theme: { spacing } }) => `
  & > *:not(:last-child) {
    padding-bottom: ${spacing.x1};
  }

  input {
    display: none;
    -webkit-appearance: none;
    webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }

  input[type="checkbox"] + label span {
    margin-right: ${spacing.x2};
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-radius: 2px;
  }

  input[type="checkbox"]:checked + label span {
    background: url(${tick}) -2px;
  }
  
`,
);

export const SubmitButton = styled.button(
  ({ theme: { colors, spacing } }) => `
    margin-top: ${spacing.x8};
    width: 100%;
    background: transparent;
    padding: ${spacing.x1};
    text-align: left;
    border: 1px solid ${colors.black};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    fill: ${colors.black};
    outline: none;
    transition: transform 0.2s cubic-bezier(0.42, 0.18, 0.11, 1.31);

    & > *:first-child {
      padding: ${spacing.x2};
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  `,
);

export const ThankYouMessage = styled.div`
  display: none;
`;
