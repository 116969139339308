import React from 'react';
import { ColumnBlockType, CodeType } from '../../types';
import Ad from '../../components/Ad';
import ColumnBlock from '../Block';

interface Props extends ColumnBlockType {
  code?: CodeType;
}

export const CodeEmbed: React.FunctionComponent<Props> = ({ code }) => {
  return (
    <ColumnBlock>
      <Ad {...code} />
    </ColumnBlock>
  );
};

export default CodeEmbed;
