import React from 'react';
import Jerry from '../../Jerry';

const FarJerrySwarm: React.StatelessComponent<any> = () => (
  <>
    <Jerry size={4} top={55} left={14} rotation={27} animated={true} />
  </>
);

export default FarJerrySwarm;
