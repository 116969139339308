import Head from './Head.png';
import T1Calf from './T1-Calf.png';
import T1Thigh from './T1-Thigh.png';
import T2Calf from './T2-Calf.png';
import T2Thigh from './T2-Thigh.png';
import T3Calf from './T3-Calf.png';
import T3Thigh from './T3-Thigh.png';
import T4Calf from './T4-Calf.png';
import T4Thigh from './T4-Thigh.png';
import T5Calf from './T5-Calf.png';
import T5Thigh from './T5-Thigh.png';
import T6Calf from './T6-Calf.png';
import T6Thigh from './T6-Thigh.png';
import T7Calf from './T7-Calf.png';
import T7Thigh from './T7-Thigh.png';
import T8Calf from './T8-Calf.png';
import T8Thigh from './T8-Thigh.png';

export default {
  Head,
  T1Calf,
  T1Thigh,
  T2Calf,
  T2Thigh,
  T3Calf,
  T3Thigh,
  T4Calf,
  T4Thigh,
  T5Calf,
  T5Thigh,
  T6Calf,
  T6Thigh,
  T7Calf,
  T7Thigh,
  T8Calf,
  T8Thigh,
};
