import styled from 'styled-components';
import Slick from 'react-slick';
import { ThemeType, BlockSizeType } from '../../types';
import ColumnBlock from '../../blocks/Block';
import { HeaderRow, Container } from '../../blocks/Block/styles';
import 'slick-carousel/slick/slick.css';
import ICONS from '../../constants/icons';

interface SlideType extends ThemeType {
  size: BlockSizeType;
}

interface ImageType extends ThemeType {
  paddingTop: number;
}

interface ArrowType extends ThemeType {
  to: 'next' | 'previous';
}

export const Arrow = styled(ICONS.carouselArrow)(
  ({ to, theme: { spacing, width, tabletView } }: ArrowType) => `
    height: 40px;
    width: 40px;
    display: block;
    position: absolute;
    z-index: 100;
    cursor: pointer;
    margin-top: -20px;
    top: 50%;
    ${
      to === 'next'
        ? `right: calc((50vw - ((${width.tablet} + ${spacing.x12}) / 2) - ${spacing.x2}));
           transform: ${to === 'next' ? 'rotate(180deg)' : 'rotate(0)'};`
        : `left: calc((50vw - ((${width.tablet} + ${spacing.x12}) / 2) - ${spacing.x2}));`
    }
    display: ${to === 'next' ? 'block' : 'none'};

    ${tabletView} {
      display: none !important;
    }
`,
);

export const Slider = styled(Slick)(
  () => `
    display: block;
    position: relative;
  `,
);

export const ImageContainer = styled.div(
  ({ paddingTop, theme: { spacing } }: ImageType) => `
    margin-bottom: ${spacing.x4};
    overflow: hidden;
    position: relative;
    padding-top: ${paddingTop}%;
    height: auto;

    a {
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
    }
  `,
);

export const Slide = styled.div(
  ({ size, theme: { spacing, mobileView } }: SlideType) => `
    position: relative;
    width: ${size === 'large' ? 552 : 240}px !important;
    margin-right: ${size === 'large' ? spacing.x6 : spacing.x8};

    ${mobileView} {
      width: ${size === 'large' ? 304 : 240}px !important;
      margin-right: ${size === 'large' ? spacing.x3 : spacing.x4};

      ${ImageContainer} {
        ${mobileView} {
          margin-bottom: ${size === 'large' ? spacing.x2 : spacing.x3};
        }
      }
    }
  `,
);

export const ContentContainer = styled.a(
  ({ theme: { spacing } }: ThemeType) => `
    text-decoration: none;
    
    h3 {
      line-height: 1.2;
      margin-bottom: ${spacing.x3};
    }

    span {
      line-height: 1;
      display: block;
    }
  `,
);

export const Grid = styled.div(
  ({ theme: { mobileView, tabletView } }: ThemeType) => `
    display: flex;

    ${tabletView} {
      flex-direction: column;
    }

    ${Slide} {
      flex: 0 1 25%;

      h3 {
        line-height: 1.2;
        font-size: 32px;
      }

      span {
        font-size: 14px;
      }

      &:last-of-type {
        margin-right: 0px !important;
      }

      ${tabletView} {
        max-width: 552px;
        width: 100% !important;
        margin-right: 0px !important;

        &:not(:last-of-type) {
          margin-bottom: 64px;
        }

        &:nth-child(even) {
          align-self: flex-end;
        }

        ${ImageContainer} {
          padding: 0;

          a {
            position: relative;
          }
        }
      }

      ${mobileView} {
        max-width: 304px !important;
      }
    }
`,
);

export const CarouselBlock = styled(ColumnBlock)(
  ({ theme: { width, spacing, mobileView } }: ThemeType) => `
    padding-left: 0;
    padding-right: 0;

    ${HeaderRow} {
      padding-left: ${spacing.x6};
      padding-right: ${spacing.x6};

      // Add padding to the width of container to make up for border-box being pushed in.
      max-width: calc(${width.tablet} + ${spacing.x12});

      ${mobileView} {
        padding-left: ${spacing.x3};
        padding-right: ${spacing.x3};
        max-width: calc(${width.tablet} + ${spacing.x6});
      }
    }

    ${Container} {
      max-width: 100%;

      .slick-track {
        margin-left: calc((50vw - ((${width.tablet} + ${spacing.x12}) / 2) +  ${spacing.x6}));

        @media (max-width: calc(${width.tablet} + ${spacing.x12})) {
          margin-left: ${spacing.x6};
        }

        ${mobileView} {
          margin-left: ${spacing.x3};
        }
      }
    }
  `,
);

export default Slider;
