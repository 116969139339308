import React from 'react';
import Bits from './bits';
import { AnimatedJerry, StaticJerry } from './style';

interface Props {
  size: number;
  top: number;
  left: number;
  rotation: number;
  animated?: boolean;
}

const Jerry: React.StatelessComponent<Props> = ({ size, top, left, rotation, animated = true }) => {
  const SvgTag = animated ? AnimatedJerry : StaticJerry;

  return (
    <SvgTag
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 442.99"
      width={size * (187 / 442.99)}
      height={size}
      top={top}
      left={left}
      rotation={rotation}
    >
      <g className="tentacle-7">
        <image className="calf" width="33" height="202" x="57.94" y="229.75" xlinkHref={Bits.T7Calf} />
        <image className="thigh" width="33" height="131" x="55.44" y="124.25" xlinkHref={Bits.T7Thigh} />
      </g>
      <g className="tentacle-6">
        <image className="calf" width="39" height="185" x="83.44" y="257.99" xlinkHref={Bits.T6Calf} />
        <image className="thigh" width="32" height="153" x="83.44" y="133" xlinkHref={Bits.T6Thigh} />
      </g>
      <g className="tentacle-8">
        <image className="calf" width="31" height="177" x="43.52" y="248.26" xlinkHref={Bits.T8Calf} />
        <image className="thigh" width="27" height="147" x="43.52" y="126.74" xlinkHref={Bits.T8Thigh} />
      </g>
      <g className="tentacle-5">
        <image className="calf" width="35" height="172" x="120.52" y="269" xlinkHref={Bits.T5Calf} />
        <image className="thigh" width="23" height="141" x="117.86" y="142" xlinkHref={Bits.T5Thigh} />
      </g>
      <g className="tentacle-1">
        <image className="calf" width="35" height="150" x="11.02" y="257" xlinkHref={Bits.T1Calf} />
        <image className="thigh" width="31" height="140" x="24.52" y="134" xlinkHref={Bits.T1Thigh} />
      </g>
      <g className="tentacle-2">
        <image className="calf" width="56" height="163" x="59.02" y="255.5" xlinkHref={Bits.T2Calf} />
        <image className="thigh" width="40" height="144" x="43.02" y="137" xlinkHref={Bits.T2Thigh} />
      </g>
      <g className="tentacle-4">
        <image className="calf" width="44" height="167" x="104.36" y="252.5" xlinkHref={Bits.T4Calf} />
        <image className="thigh" width="28" height="148" x="97.02" y="131.5" xlinkHref={Bits.T4Thigh} />
      </g>
      <g className="tentacle-3">
        <image className="calf" width="52" height="146" x="87.02" y="265" xlinkHref={Bits.T3Calf} />
        <image className="thigh" width="36" height="165" x="73.02" y="123" xlinkHref={Bits.T3Thigh} />
      </g>
      <g className="Head">
        <image width="187" height="181" xlinkHref={Bits.Head} />
      </g>
    </SvgTag>
  );
};

export default Jerry;
