import React from 'react';
import StageHeaderImage1 from '../../../assets/images/stage-header-3.png';
import { WidthHeightGreebleType } from './types';
import { StyledStageHeader, StyledBlock } from './style';

const StageHeader1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledBlock>
    <StyledStageHeader top={top} bottom={bottom} right={-5} width={40} height={40 * (393 / 445)} image={StageHeaderImage1} />
  </StyledBlock>
);

export default StageHeader1;
