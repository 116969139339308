import React from 'react';
import { SOCIALS } from '../../constants';
import AnnouncementBanner from '../AnnouncementBanner';
import IconButton from '../IconButton';
import SocialLinks from '../SocialLinks';
import Logo from '../Logo';
import { PageLinkType } from '../../types';
import { HeaderWrapper, ContentWrapper, HeaderContent, PageLinksWrapper, SecondaryHeader, TicketButton } from './styles';
import AnnouncementType from '../../types/Announcement';
import PrimaryHeader from './Primary';

interface Props {
  pageWrapId?: string;
  openSubscribeMenu: () => void;
  openSearch: () => void;
  openMainMenu: () => void;
  pageLinks?: PageLinkType[];
  announcement: AnnouncementType;
  hideAnnouncement: () => void;
  openMySplendourModal: () => void;
  hidePrimaryHeader?: boolean;
}

const Header: React.FunctionComponent<Props> = ({
  pageWrapId,
  openSubscribeMenu,
  openSearch,
  openMainMenu,
  pageLinks,
  announcement,
  hideAnnouncement,
  hidePrimaryHeader,
}) => (
  <HeaderWrapper>
    {announcement && <AnnouncementBanner {...announcement} hide={hideAnnouncement} />}
    {!hidePrimaryHeader && (
      <PrimaryHeader pageWrapId={pageWrapId}>
        <HeaderContent>
          <IconButton type="paperPlane" size="sm" text="Subscribe" onClick={openSubscribeMenu} data-test="SubscribeMenuButton" />
          <SocialLinks links={SOCIALS} size="sm" />
        </HeaderContent>
      </PrimaryHeader>
    )}
    <SecondaryHeader>
      <HeaderContent>
        <ContentWrapper>
          <Logo />
          {pageLinks && (
            <PageLinksWrapper>
              {pageLinks.map(({ title, url }: PageLinkType, key) => (
                <a key={key} className="h8" href={url}>
                  {title}
                </a>
              ))}
            </PageLinksWrapper>
          )}
        </ContentWrapper>
        <ContentWrapper>
          <TicketButton href="https://2019.splendourinthegrass.com/set-times/" target="_blank">
            Set Times
          </TicketButton>
          <IconButton type="search" size="md" onClick={openSearch} data-test="SearchButton" />
          <IconButton type="burger" size="md" onClick={openMainMenu} data-test="MainMenuButton" />
        </ContentWrapper>
      </HeaderContent>
    </SecondaryHeader>
  </HeaderWrapper>
);

export default Header;
