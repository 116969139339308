import styled from 'styled-components';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  open: boolean;
}

export default styled.div(
  ({ theme: { colors, mobileView, spacing } }) => `
    padding-left: ${spacing.x6};
    padding-right: ${spacing.x3};
    color: ${colors.white};
    fill: ${colors.white};

    ${mobileView} {
      padding-left: ${spacing.x1};
    }

    .collapse-css-transition {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
      overflow: hidden;
    }
`,
);

export const TitleRow = styled.button(
  ({ theme: { colors, spacing }, open }: Props) => `
    line-height: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing.x2} 0;

    transition: all 0.3s ease;
    &:hover {
        span {
            color: ${colors.white}
        }
    }

    span {
      text-align: left;
      color: ${open ? colors.white : colors.white70};
      flex: 1;
    }
`,
);

export const ContentBlock = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    padding-bottom: ${spacing.x8};
    line-height: 1.5;
    a {
      text-decoration: underline;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
    ${mobileView} {
      padding-bottom: ${spacing.x4};
    }
`,
);
