import React from 'react';
import { ArtistType, ColumnBlockType } from '../../types';
import { Carousel } from '../../components';
import { CarouselSize } from '../../components/Carousel';
import StyledCarouselBlock from './styles';

interface Props extends ColumnBlockType {
  artists: ArtistType[];
  carouselSize?: CarouselSize;
}

export const ExploreLineup: React.StatelessComponent<Props> = ({ type = 'large', carouselSize = 'large', title, cta, artists }) => (
  <StyledCarouselBlock title={title} cta={cta} type={type}>
    {artists && <Carousel size={carouselSize} items={artists} carousel={true} />}
  </StyledCarouselBlock>
);

export default ExploreLineup;
