import React from 'react';
import CactusImage from '../../../assets/images/cactus-2.png';
import { DancingCactus2 } from './style';
import { SizeGreebleType } from './types';

const Cactus2: React.StatelessComponent<SizeGreebleType> = ({ size, top, bottom, left, right, flipX, flipY, rotation }) => (
  <DancingCactus2
    width={size * (308 / 600)}
    height={size}
    top={top}
    bottom={bottom}
    left={left}
    right={right}
    flipX={flipX}
    flipY={flipY}
    rotation={rotation}
    image={CactusImage}
  />
);

export default Cactus2;
