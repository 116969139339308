import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const TallBlack: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, noFooterBlocks, natural }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Padder height={7} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={10} />
      {!noFooterBlocks && <CSSParallax.Dividers.BrushStroke variant={4} color={theme.colors.purple} />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.PinkSplatter1 top={-40} flipX={true} left={0} />
      <CSSParallax.Greeblies.BlueSplatter2 top={-20} right={0} />
    </CSSParallax.Layer>
  </StyledSection>
);

export default TallBlack;
