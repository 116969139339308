import React from 'react';
import SplatterImage from '../../../assets/images/paint-stroke-white.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const WhiteSplatter3: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} left={0} flipX={true} bottom={bottom} width={5.75} height={60.0} image={SplatterImage} />
);

export default WhiteSplatter3;
