import styled from 'styled-components';

export default styled.div(
  ({ theme: { mobileView, spacing } }) => `
      border-bottom: 1px solid rgba(180, 180, 180, .3);

      & > * {
        border-top: 1px solid rgba(180, 180, 180, .3);
      }

      ${mobileView} {
        padding-top: ${spacing.x3};
      }
  `,
);
