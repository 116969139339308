import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import { ThemeType } from '../../types';
import { HeroWrapper } from '../../blocks/Hero/styles';

export const StyledSection = styled(CSSParallax.Section)(
  ({ theme: { spacing, mobileView } }: ThemeType) => `
    position: relative;
    z-index: 2;
    min-height: 20vw;

    ${mobileView} {
      min-height: 300px;
    }

    ${HeroWrapper} {
      ${mobileView} {
        padding-bottom: ${spacing.x4};
      }
    }

    ${CSSParallax.Content} {
      min-height: 20vw;

      ${mobileView} {
        min-height: 300px;
      }
    }
`,
);

export const StyledContentInner = styled(CSSParallax.ContentInner)`
  min-height: 0;

  h1 {
    margin-bottom: 0;
  }
`;

export const StyledAbovePadder = styled(CSSParallax.Padder)(
  ({ theme: { mobileView } }: ThemeType) => `
    height: 6vw;
    max-height: 100px;

    ${mobileView} {

    }
`,
);

export const StyledBelowPadder = styled(CSSParallax.Padder)(
  ({ theme: { mobileView } }: ThemeType) => `
    height: 9.5vw;
    max-height: 220px;

    ${mobileView} {

    }
`,
);
