import React from 'react';
import { SpotifyBlockType } from '../../types';
import Floater from '../../components/Floater';
import StyledRowBlock, { LinkWrapper, Label } from './styles';
import { Link } from '../../styles/components';
import { SpotifyIcon } from '../../icons';

export const Spotify: React.StatelessComponent<SpotifyBlockType> = ({ title, cta, spotify }) => (
  <StyledRowBlock
    title={title}
    ctaStyling={true}
    type="medium"
    operations={
      cta && (
        <LinkWrapper>
          <Link href={cta.link.url} target={cta.link.target}>
            <SpotifyIcon />
            <Label className="h7" data-test="LinkText">
              {cta.text}
            </Label>
          </Link>
        </LinkWrapper>
      )
    }
  >
    {spotify && spotify.embed && (
      <Floater>
        <div dangerouslySetInnerHTML={{ __html: spotify.embed }} />
      </Floater>
    )}
  </StyledRowBlock>
);

export default Spotify;
