import styled from 'styled-components';
import Block from '../../../blocks/Block/styles';
import { ThemeType } from '../../../types';

interface Props extends ThemeType {
  postWidth?: boolean;
}

export const StyledContent = styled.div(
  ({ theme: { width, mobileView, spacing }, postWidth }: Props) => `
    display: flex;
    flex-wrap: no-wrap;
    margin: auto;       
    max-width: ${postWidth ? width.content : width.default};
    min-height: 200px;
    position: relative;
    width: 100%;
    z-index: 125;
    position: relative;
    padding-right: 48px;

    & + ${Block} {
      margin-top: ${spacing.x20};
    }

    & + ${Block}:last-child {
      margin-bottom: ${spacing.x20};
    }

    & > * {
      flex: 1;
    }

    ${mobileView} {
      max-width: 100%;
      padding-right: 0;
      flex-wrap: wrap;
      
      & > * {
        flex: 1 100%;
      }
    }    
  `,
);
