import styled from 'styled-components';
import Block from '../Block';
import { LinkWrapper } from '../Block/styles';
import { ThemeType } from '../../types';

export default styled(Block)(
  ({ theme: { spacing } }: ThemeType) => `
    ${LinkWrapper} {
      text-align: center;
      margin-top: ${spacing.x8};
    }
`,
);
