import React from 'react';
import Jerry from '../../Jerry';

const MidJerrySwarm: React.StatelessComponent<any> = () => (
  <>
    <Jerry size={6} top={46} left={9} rotation={27} animated={true} />
    <Jerry size={7} top={57.5} left={25} rotation={27} animated={true} />
  </>
);

export default MidJerrySwarm;
