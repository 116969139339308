import styled from 'styled-components';
import OversprayImage from '../../assets/images/overspray.png';

interface Props {
  height?: string;
}

export default styled.div(
  ({ height }: Props) => `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    ${height ? `padding-top: ${height};` : ''}
    background-image: url(${OversprayImage});
    background-size: contain;
    background-position: center bottom;
    pointer-events: none;
    opacity: 0.4;
`,
);
