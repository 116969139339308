import React from 'react';
import ExternalLink from '../ExternalLink';
import { TicketType } from '../../types';
import { ExternalLinkIcon } from '../../icons';
import { StyledTicket, FrontTicket } from './styles';

export const FrontPageTicket: React.FunctionComponent<TicketType> = ({ title, data: { ticketCta, conditionalText, price } }) => (
  <FrontTicket href="/tickets">
    <span className="p4 ticket-price" data-test="Price">
      {price}
    </span>

    {conditionalText ? (
      <div className="p4 ticket-terms" data-test="Terms">
        {conditionalText}
      </div>
    ) : null}

    <h4 className="h3 ticket-name" data-test="Name">
      {title}
    </h4>

    <div className="ticket-fake_link" data-test="Link">
      <span className="h7" data-test="Text">
        {ticketCta ? ticketCta : 'Buy Tickets'}
      </span>
      <ExternalLinkIcon size="sm" />
    </div>
  </FrontTicket>
);

export const Ticket: React.FunctionComponent<TicketType> = ({
  title,
  data: { soldOut, termsConditionsLink, ticketCta, conditionalText, description, price, ticketLink },
}) => (
  <StyledTicket className={soldOut ? 'sold-out' : ''}>
    <span className="p4 ticket-price" data-test="Price">
      {price}
    </span>

    {conditionalText ? (
      <div className="p4 ticket-terms" data-test="Terms">
        {conditionalText}
      </div>
    ) : null}

    <h4 className="h3 ticket-name" data-test="Name">
      {title}
    </h4>

    <div className="p4 ticket-description" data-test="Description" dangerouslySetInnerHTML={{ __html: description ? description : '' }} />

    <ExternalLink
      className="ticket-link"
      href={ticketLink || '/tickets'}
      text={soldOut ? 'Sold Out' : ticketCta ? ticketCta : 'Buy Tickets'}
      target="_blank"
      data-test="Link"
    />

    {termsConditionsLink ? (
      <a className="p4 ticket-terms_url" href={termsConditionsLink} data-test="TermsLink">
        Terms and Conditions
      </a>
    ) : null}
  </StyledTicket>
);

export default Ticket;
