import styled, { css, keyframes } from 'styled-components';
import Block from '../../../blocks/Block/styles';
import theme from '../../../styles/theme';
import { ThemeType } from '../../../types';

interface Props {
  image?: string;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  flipX?: boolean;
  flipY?: boolean;
  rotation?: number;
  zIndex?: number;
}

export const StyledGreeble = styled.div(({ image, width, height, top, left, right, bottom, flipX, flipY, rotation, zIndex }: Props) => {
  return css`
    position: absolute;
    display: block;
    font-size: 0;
    ${top || top === 0 ? `top: ${top}vw;` : ''}
    ${left || left === 0 ? `left: ${left}vw;` : ''}
    ${right || right === 0 ? `right: ${right}vw;` : ''}
    ${bottom || bottom === 0 ? `bottom: ${bottom}vw;` : ''}
    ${width ? `width: ${width}vw;` : ''}
    ${height ? 'height: 0;' : ''}
    ${height ? `padding-top: ${height}vw;` : ''}
    ${image ? `background-image: url(${image});` : ''}
    ${image ? 'background-size: contain;' : ''}
    ${image ? 'background-repeat: no-repeat;' : ''}
    ${flipX || flipY || rotation ? 'transform-origin: center center;' : ''};
    transform:
      ${flipY ? 'scaleY(-1)' : ''}
      ${flipX ? 'scaleX(-1)' : ''}
      ${rotation ? `rotate(${rotation}deg)` : ''}
      ${flipX || flipY || rotation ? '' : ' none'};
    ${zIndex ? `z-index: ${zIndex}` : ''}
  `;
});

export default StyledGreeble;

interface StyledStageHeaderType extends ThemeType {
  image?: string;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  flipX?: boolean;
  flipY?: boolean;
  rotation?: number;
  zIndex?: number;
}

export const StyledStageHeader = styled.div(
  ({ theme: { mobileView, tabletView }, image, width, height, left, right, flipX, flipY, rotation, zIndex }: StyledStageHeaderType) => `
    position: absolute;
    display: block;
    font-size: 0;
    top: 75%;
    ${left || left === 0 ? `left: ${left}%;` : ''}
    ${right || right === 0 ? `right: ${right}%;` : ''}
    ${width ? `width: ${width}%;` : ''}
    ${height ? 'height: 0;' : ''}
    ${height ? `padding-top: ${height}%;` : ''}
    ${image ? `background-image: url(${image});` : ''}
    ${image ? 'background-size: contain;' : ''}
    ${image ? 'background-repeat: no-repeat;' : ''}
    ${flipX || flipY || rotation ? 'transform-origin: center center;' : ''};
    transform: translateY(-50%) ${flipY ? 'scaleY(-1)' : ''} ${flipX ? 'scaleX(-1)' : ''} ${rotation ? `rotate(${rotation}deg)` : ''};
    ${zIndex ? `z-index: ${zIndex}` : ''}

    ${tabletView} {
      display: none;
    }

    ${mobileView} {
      display: none;
    }
  `,
);

export const StyledBlock = styled(Block)`
  position: relative;
  height: 100%;
`;

const danceTransform = (rotation, flipX) => {
  return keyframes`
    0% {
      transform: skew(0.01turn, 0deg) translateX(-1%) ${flipX ? 'scaleX(-1)' : ''} ${rotation ? `rotate(${rotation}deg)` : ''};
    }
    50% {
      transform: skew(-0.01turn, 0deg) translateX(3%) ${flipX ? 'scaleX(-1)' : ''} ${rotation ? `rotate(${rotation}deg)` : ''};
    }
    100% {
      transform: skew(0.01turn, 0deg) translateX(-1%) ${flipX ? 'scaleX(-1)' : ''} ${rotation ? `rotate(${rotation}deg)` : ''};
    }
  `;
};

const danceHeight = (height) => {
  return keyframes`
    0% {
      padding-top: ${height * 1.03}vw;
    }
    25% {
      padding-top: ${height * 0.97}vw;
    }
    50% {
      padding-top: ${height * 1.03}vw;
    }
    75% {
      padding-top: ${height * 0.97}vw;
    }
    100% {
      padding-top: ${height * 1.03}vw;
    }
  `;
};

export const DancingCactus = styled(StyledGreeble)(({ height, flipX, rotation }: Props) => {
  return css`
    background-size: 100% 100%;
    will-change: transform;
    transform: skew(0.01turn, 0deg) translateX(-1%) ${flipX ? 'scaleX(-1)' : ''} ${rotation ? `rotate(${rotation}deg)` : ''};
    animation: ${danceTransform(rotation, flipX)} 2s ease-in-out infinite, ${danceHeight(height)} 2s ease-in-out infinite;
  `;
});

export const DancingCactus1 = styled(DancingCactus)`
  transform-origin: bottom center;
`;

export const DancingCactus2 = styled(DancingCactus)`
  transform-origin: 46% 78%;
`;

export const DancingCactus3 = styled(DancingCactus)`
  transform-origin: 46% 78%;
`;

export const StyledGalaxyHoleClipper = styled.div`
  -webkit-clip-path: polygon(
    57.15% 40.87%,
    52.84% 40.11%,
    41.32% 40.11%,
    38.25% 40.87%,
    34.26% 40.99%,
    29.95% 40.11%,
    26.03% 40.11%,
    23.27% 40.68%,
    21.6% 42.75%,
    19.16% 42.75%,
    17.3% 44.83%,
    12.91% 49.86%,
    10.66% 49.74%,
    9.73% 58.17%,
    6.89% 63.07%,
    6.89% 65.78%,
    7.71% 66.66%,
    8.8% 68.86%,
    9.15% 71.25%,
    8.8% 76.03%,
    8.8% 82.07%,
    9.88% 86.85%,
    11.16% 90.38%,
    12.79% 92.84%,
    15.58% 94.79%,
    19.09% 95.54%,
    22.71% 95.39%,
    25.19% 95.01%,
    27.54% 93.83%,
    31.11% 95.08%,
    34.18% 96.35%,
    35.38% 97.04%,
    37.45% 98.3%,
    39.96% 99.18%,
    42.91% 99.56%,
    45.12% 100%,
    47.18% 99.37%,
    52.57% 96.6%,
    57.5% 93.02%,
    59.79% 91%,
    61.81% 90.38%,
    65.73% 90.56%,
    75.28% 93.91%,
    80.09% 93.96%,
    81.95% 87.29%,
    82.84% 84.46%,
    82.84% 82.83%,
    83.39% 80.56%,
    84.32% 80.62%,
    84.2% 78.23%,
    86.26% 76.16%,
    87.23% 73.58%,
    88.24% 72.01%,
    87.81% 65.72%,
    86.96% 63.64%,
    87.42% 62.19%,
    85.99% 58.04%,
    82.96% 55.21%,
    81.91% 53.76%,
    81.37% 52.13%,
    80.4% 51.06%,
    80.4% 49.42%,
    77.99% 47.09%,
    75.28% 45.46%,
    71.63% 43.26%,
    69.42% 43.95%,
    66.55% 42.5%,
    64.18% 41.62%,
    61.07% 41.62%,
    59.02% 40.99%,
    57.15% 40.87%
  );
  clip-path: polygon(
    57.15% 40.87%,
    52.84% 40.11%,
    41.32% 40.11%,
    38.25% 40.87%,
    34.26% 40.99%,
    29.95% 40.11%,
    26.03% 40.11%,
    23.27% 40.68%,
    21.6% 42.75%,
    19.16% 42.75%,
    17.3% 44.83%,
    12.91% 49.86%,
    10.66% 49.74%,
    9.73% 58.17%,
    6.89% 63.07%,
    6.89% 65.78%,
    7.71% 66.66%,
    8.8% 68.86%,
    9.15% 71.25%,
    8.8% 76.03%,
    8.8% 82.07%,
    9.88% 86.85%,
    11.16% 90.38%,
    12.79% 92.84%,
    15.58% 94.79%,
    19.09% 95.54%,
    22.71% 95.39%,
    25.19% 95.01%,
    27.54% 93.83%,
    31.11% 95.08%,
    34.18% 96.35%,
    35.38% 97.04%,
    37.45% 98.3%,
    39.96% 99.18%,
    42.91% 99.56%,
    45.12% 100%,
    47.18% 99.37%,
    52.57% 96.6%,
    57.5% 93.02%,
    59.79% 91%,
    61.81% 90.38%,
    65.73% 90.56%,
    75.28% 93.91%,
    80.09% 93.96%,
    81.95% 87.29%,
    82.84% 84.46%,
    82.84% 82.83%,
    83.39% 80.56%,
    84.32% 80.62%,
    84.2% 78.23%,
    86.26% 76.16%,
    87.23% 73.58%,
    88.24% 72.01%,
    87.81% 65.72%,
    86.96% 63.64%,
    87.42% 62.19%,
    85.99% 58.04%,
    82.96% 55.21%,
    81.91% 53.76%,
    81.37% 52.13%,
    80.4% 51.06%,
    80.4% 49.42%,
    77.99% 47.09%,
    75.28% 45.46%,
    71.63% 43.26%,
    69.42% 43.95%,
    66.55% 42.5%,
    64.18% 41.62%,
    61.07% 41.62%,
    59.02% 40.99%,
    57.15% 40.87%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* IE 10+ */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }

  /* Edge */
  @supports (-ms-ime-align: auto) {
    display: none;
  }
`;

interface StyledGalaxyHoleInnerType {
  image: string;
}

export const StyledGalaxyHoleInner = styled.div(
  ({ image }: StyledGalaxyHoleInnerType) => `
  background-image: url(${image});
  background-color: ${theme.colors.black};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  transform: translateZ(0);
  will-change: transform;
`,
);
