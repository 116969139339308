import styled from 'styled-components';

export default styled.a(
  ({ theme: { colors, linearGradient, spacing } }) => `
    padding: ${spacing.x1};
    display: block;
    display: flex;
    justify-content: space-between;

    border-style: solid;
    border-width: 2px;
    border-image-source: ${linearGradient.blueGreenBottom};
    border-image-slice: 1;
    fill: ${colors.green};

    div {
      padding: ${spacing.x1} ${spacing.x3};
    }
`,
);
