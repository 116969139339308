import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-white-3.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const WhiteSplatter4: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top} right={0} bottom={bottom} width={12.2} height={60.0} image={SplatterImage} />
);

export default WhiteSplatter4;
