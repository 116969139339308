import styled, { keyframes, css } from 'styled-components';

export default styled.div(
  ({ theme: { colors, linearGradient, mobileView, spacing } }) => `
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${spacing.x2} ${spacing.x6};
  background-image: ${linearGradient.blueGreenLeft};
  color: ${colors.black};
  position: relative;
  z-index: 1001;

  & > *:not(:last-child) {
    padding-right: ${spacing.x4};
  }
  
  & > .h7 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  ${mobileView} {
    padding: ${spacing.x1} ${spacing.x3};
    & > *:not(:last-child) {
      padding-right: ${spacing.x2};
    }
  }
`,
);

const gradient = keyframes`
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
`;

export const Button = styled.a(
  ({ theme: { colors, linearGradient, mobileView, spacing }, showForm }: Props) => css`
    display: inline-block;
    border: 2px solid ${colors.black};
    line-height: 1;
    padding: 0.3em 0.8em 0.2em;
    align-self: flex-end;
    &:hover {
      text-decoration: none;
      background: ${colors.black};
      color: ${colors.green};
    }
  `
)

export const FooterBanner = styled.div(
  ({ theme: { colors, linearGradient, mobileView, spacing }, showForm }: Props) => css`
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${spacing.x2} ${spacing.x6};
    background: ${linearGradient.blueGreenBottom};
    background-size: 200% 200%;
    animation: ${gradient} 15s ease infinite;
    color: ${colors.black};
    position: fixed;
    min-height: 40vh;
    max-height: 100%;
    left: 0;
    bottom: 0;
    z-index: 1001;

    &::before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 0;
      left: 0;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      > input[type='checkbox'] {
        display: none;
      }

      > input[type='checkbox']:checked ~ div {
        height: 100vh;
        opacity: 1;
        display: block;
      }

      > input[type='checkbox']:checked ~ label {
        display: none;
      }
    }
    > button {
      position: absolute;
      top: 0;
      right: 0;
      svg {
        height: 5vmin;
        width: 5vmin;
        min-height: 16px;
        min-width: 16px;
      }
    }
    ${!showForm &&
    `
    &:hover{
      background-image: none;
      background-color: rgba(0, 0, 0, 0.8);
      color: ${colors.green};
      &:before{
        background-image: ${linearGradient.blueGreenRight};
      }
    }
  `}
    ${mobileView} {
      padding: ${spacing.x1} ${spacing.x3};
      & > *:not(:last-child) {
        padding-right: ${spacing.x2};
      }
    }
  `,
);
const buzz = keyframes`
  0% {
    transform: translateX(3px) rotate(1deg);
  }

  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }

  5% {
    transform: translateX(3px) rotate(1deg);
  }

  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }

  10% {
    transform: translateX(2px) rotate(1deg);
  }

  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }

  15% {
    transform: translateX(2px) rotate(1deg);
  }

  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }

  20% {
    transform: translateX(1px) rotate(1deg);
  }

  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }

  25% {
    transform: translateX(0) rotate(0);
  }
`;
export const Title = styled.label(
  ({ theme: { spacing } }) => css`
    font-size: 9vmin;
    font-weight: 800;
    text-align: center;
    animation: ${buzz} 3s linear infinite;
    width: 100%;
    display: block;
    padding: 1em 0;
    cursor: pointer;
  `,
);

export const Content = styled.div(
  ({ theme: { spacing } }) => `
  display: inline-block;
  margin-right: ${spacing.x1};
  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }
`,
);

export const SubscribeWrapper = styled.div(
  ({ theme: { spacing } }) => `
    width: 600px;
    max-width: 100%;
    height: 0;
    transition: height 0.5s;
    overflow: auto;
    -webkit-overflow-scroll: touch;
  `,
);
