import styled from 'styled-components';

export default styled.div(
  ({ theme: { colors } }) => `
    a {
      color: ${colors.green};
    }

    p {
      &:first-child {
        margin-top: 0;
      }
    }

    img, 
    iframe {
      max-width: 100%;
    }

    .alignnone {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      height: auto;
    }

    .aligncenter {
      display: block;
      margin: 2em auto;
      height: auto;
    }

    .alignleft,
    .alignright {
      margin-bottom: 0;
      height: auto;
    }
  `,
);
