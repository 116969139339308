import StarsGreenImage from '../assets/images/stars-green.png';
import StarsWhiteImage from '../assets/images/stars-white.jpg';
import PinkBeachFrontImage from '../assets/images/pink-beach-front.png';
import PinkSandscapeBackImage from '../assets/images/pink-sandscape-back.png';
import RockyBeachFrontImage from '../assets/images/rocky-beach-front.png';
import RockyBeachBackImage from '../assets/images/rocky-beach-back.png';
import RockyRoadFrontImage from '../assets/images/rocky-road-front.png';
import RockyRoadBackImage from '../assets/images/rocky-road-back.png';
import DesertRoadFrontImage from '../assets/images/desert-road-front.png';
import DesertRoadBackImage from '../assets/images/desert-road-back.png';
import ToxicWastelandFrontImage from '../assets/images/toxic-wasteland-front.png';
import ToxicWastelandMiddleImage from '../assets/images/toxic-wasteland-middle.png';
import ToxicWastelandBackImage from '../assets/images/toxic-wasteland-back.png';
import ToxicWastelandBackFlippedImage from '../assets/images/toxic-wasteland-back-flipped.png';
import StarsWhiteSparseImage from '../assets/images/stars-white-sparse.png';
import VintageTvImage from '../assets/images/vintage-tv.png';
import theme from '../styles/theme';

export const LAYERS = {
  WHITE_STARS: {
    backgroundImage: `url(${StarsWhiteImage})`,
    backgroundColor: theme.colors.black,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '115% auto',
  },
  GREEN_STARS: {
    backgroundImage: `url(${StarsGreenImage})`,
    backgroundColor: theme.colors.lightBlack,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100% auto',
  },
  ROCKY_BEACH_FRONT: {
    top: 'auto',
    height: '25.5vw',
    backgroundImage: `url(${RockyBeachFrontImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  ROCKY_BEACH_BACK: {
    top: 'auto',
    height: '29vw',
    backgroundImage: `url(${RockyBeachBackImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  ROCKY_ROAD_FRONT: {
    top: 'auto',
    height: '21vw',
    backgroundImage: `url(${RockyRoadFrontImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  ROCKY_ROAD_BACK: {
    top: 'auto',
    height: '29vw',
    backgroundImage: `url(${RockyRoadBackImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  DESERT_ROAD_FRONT: {
    top: 'auto',
    height: '21vw',
    backgroundImage: `url(${DesertRoadFrontImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  DESERT_ROAD_BACK: {
    top: 'auto',
    height: '29vw',
    backgroundImage: `url(${DesertRoadBackImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  TOXIC_WASTELAND_FRONT: {
    top: 'auto',
    height: '21vw',
    backgroundImage: `url(${ToxicWastelandFrontImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  TOXIC_WASTELAND_MIDDLE: {
    top: 'auto',
    bottom: '7vw',
    height: '29vw',
    backgroundImage: `url(${ToxicWastelandMiddleImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  TOXIC_WASTELAND_BACK: {
    top: 'auto',
    height: '29vw',
    backgroundImage: `url(${ToxicWastelandBackImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  TOXIC_WASTELAND_BACK_FLIPPED: {
    top: 'auto',
    height: '29vw',
    backgroundImage: `url(${ToxicWastelandBackFlippedImage})`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '85% auto',
  },
  COOL_TV: {
    top: '-19.5vw',
    bottom: 'auto',
    height: '23.5vw',
    backgroundImage: `url(${VintageTvImage})`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  GALAXY_HOLE: {
    top: '-8.25vw',
    bottom: 'auto',
    height: '23.5vw',
  },
  PINK_BEACH: {
    top: 'auto',
    bottom: '0',
    marginBottom: '-4vw',
  },
  PINK_SANDSCAPE_FRONT: {
    top: 'auto',
    height: '35vw',
    backgroundImage: `url(${PinkBeachFrontImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  PINK_SANDSCAPE_BACK: {
    top: 'auto',
    height: '12vw',
    backgroundImage: `url(${PinkSandscapeBackImage})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  PINK_SANDSCAPE_TV: {
    top: 'auto',
    bottom: '-1vw',
    height: '23.5vw',
    backgroundImage: `url(${VintageTvImage})`,
    backgroundPosition: '120% top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '79% auto',
  },
  DEEP_BACKGROUND: {
    backgroundImage: `url(${StarsWhiteSparseImage})`,
    backgroundColor: theme.colors.lightBlack,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100% auto',
  },
};
