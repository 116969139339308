import styled, { keyframes } from 'styled-components';

const hoverAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
`;

export const AddButton = styled.button`
  appearance: none;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: none;

  &:hover {
    animation: ${hoverAnimation} 0.8s ease-in-out infinite;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 0.24px;
    color: white;
  }

  svg {
    margin-right: 12px;
    fill: white;
  }
`;
