import React from 'react';
import LineupComponent from '../../components/Lineup';
import LineupType from '../../types/Lineup';
import { CtaType } from '../../types';
import { CtaLink } from '../Block';
import StyledBlock from './styles';

interface Props extends LineupType {
  lineupPageSlug: string;
  cta?: CtaType;
}

export const Lineup: React.FunctionComponent<Props> = ({ artists, cta }) => {
  const filteredArtists = artists.filter(artist => !artist.data.hideFromLineup);
  return (
  <>
    {filteredArtists && (
      <StyledBlock ctaStyling={true}>
        <LineupComponent artists={filteredArtists} />
        {cta && <CtaLink {...cta} />}
      </StyledBlock>
    )}
  </>
)};

export default Lineup;
