import styled from 'styled-components';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  hasText: boolean;
  reverse: boolean;
}

export const StyledSpan = styled.span`
  white-space: nowrap;
`;

export default styled.button(
  ({ theme: { colors, spacing }, hasText, reverse }: Props) => `
    width: ${spacing.x3};
    height: ${spacing.x3};
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    will-change: color;
    transition: color 0.2s ease-in-out;

    svg {
      will-change: fill;
      transition: fill 0.2s ease-in-out;
    }

    * {
      z-index: 2;
      position: relative;
    }

    &:before {
      content: '';
      display: block;
      opacity: 0;
      will-change: transform;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: opacity 0.2s ease-in-out, transform 0.2s cubic-bezier(0.42, 0.18, 0.11, 1.31);
      position: absolute;
      top: -7px;
      left: -7px;
      right: -7px;
      bottom: -7px;
      background-color: ${colors.white};
      z-index: 1;
      border-radius: 100px;
    }

    &:hover {
      color: ${colors.black};

      svg {
        fill: ${colors.black};
      }

      &:before {
        opacity: 1;
        transform: scale(1);
      }
    }

    ${hasText &&
      `
      width: auto;
      ${reverse ? 'flex-direction: row-reverse;' : ''}
  
      & > *:not(:last-child) {
        margin-${reverse ? 'left' : 'right'}: ${spacing.x2};
      }

      &:before {
        left: ${reverse ? '-12px' : '-8px'};
        right: ${reverse ? '-8px' : '-12px'};
      }
    `}
`,
);
