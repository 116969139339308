import React from 'react';
import SvgType from './type';
import StyledSvg from './style';

const BrushStroke1: React.StatelessComponent<SvgType> = ({ color, flipX, flipY }) => (
  <StyledSvg flipX={flipX} flipY={flipY} preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 112" color={color}>
    <path
      d="M1130.9,6.4l4.1-2.5l-3.4-2c-2.3,0-5.7-0.8-8.3,0.4c-2.7,1.3-5.9-1.7-7.7,1.8c-2.1-1.9-4.7-2.4-6.2-1.4
			c-3.3,2-5.1-0.3-7.6-1.3c-0.7,0.3-1.4,0.6-2.1,0.7c-1.5,0.2-3-0.4-4.6,0.9c-0.6,0.5-2.5-0.6-3.4-0.9l-7.9,2.4
			c3.2,2.1,6.3-1.1,9.6-0.3c3.1,0.7,6.4-0.7,9.4,1.3c0.2-0.2,0.7-0.9,1.1-0.9c8.7,0.2,17.3,0.5,26,0.9
			C1130.2,5.4,1130.5,6.1,1130.9,6.4z"
    />
    <path
      d="M1192.1,37.9c-2.2,0.4-4.3,0.8-7.6,1.5l3.9,0.9l0.7,2.2c1.8-0.5,3.7,0.7,5.6-0.8c0.7-0.5,2.5,0.4,4.2,0.8l1.9-1l1.6,1.4
			c0.2-0.5,0.4-1,0.6-1.6l-2.3-1.2l1.8-2l2.4,1.4l0.3-1.5c-1.8-2.4-4.2,1.4-5.9-0.8C1197.1,39.1,1194.5,37.5,1192.1,37.9z"
    />
    <path
      d="M1221.2,11.7c1.8,0.2,3.5,0.2,5.8,0.2c-1.7-1.8-3.9-0.5-4.9-1.4c-1.6-1.5-3.2-1-5-1.2s-3.9,0.4-5.3-0.4
			c-1.7-0.8-3.6-1.2-5.5-1.2c-1.8-0.1-3.6-0.1-5.4-0.2l-5.4-0.2l-5.4-0.2l-5.3-0.1c0,0.4,0.1,0.8,0.2,1.1c2.4,0.1,4.7,0.3,7,0.2
			c8.2-0.3,16.2,2.4,24.5,2C1217.9,10.4,1219.4,11.4,1221.2,11.7z"
    />
    <path
      d="M392.9,32.5c2.7-1,5.7-1.1,9-1.6c-3.9-1.3-3.9-1.3-6.2-0.1c-1.8-0.4-3.5-1.3-4.6-0.8c-3.3,1.6-6.6,0.3-9.9,0.3
			c-0.2,0-0.5-0.5-0.7-0.7l-3.9,1.9c2.4,0.1,3.8,0,5.2,0.2c1.7,0.2,3.2,0,5,1C388.3,33.5,390.7,33.3,392.9,32.5z"
    />
    <path d="M996.6,5.4l2-4.1c-4.7,2.5-8.5,1.3-12.3,1.5c-0.2,3.4,3.6,1.3,4.3,3.4C992.9,5.3,992.9,5.3,996.6,5.4z" />
    <path d="M1351.3,34.2l5.1-1.4c-0.1-0.6-0.2-1.2-0.2-1.7l-9.2-0.3l-0.1,0.6l3.8,0.8C1350.9,32.8,1351.1,33.5,1351.3,34.2z" />
    <path d="M113.1,26.1l3-0.1l-5.4-4.7l-6,1.6c2.4,1,4.4,0.5,6.8,0.9L113.1,26.1z" />
    <path d="M966.1,8.2c-1.4-0.8-2.5-0.5-3.8,0.5c-0.8,0.6-2.3,0-3.5,0C961.3,10.1,963.9,10.4,966.1,8.2z" />
    <path d="M1073.7,0.2l-4.8-0.2c-0.1,0.5-0.2,1.1-0.3,1.5C1070.8,3.8,1072.1,0.5,1073.7,0.2z" />
    <path d="M401,36.9l-7.2-0.2C396.1,38.2,398.6,37.4,401,36.9z" />
    <path d="M262.1,32.3l-5.1-0.2C258.7,33.5,260.4,33.1,262.1,32.3z" />
    <path d="M1181.4,38.4l4.2,0.1c0-0.2,0-0.3,0-0.5l-4.2-0.1L1181.4,38.4z" />
    <path d="M1137.3,5.5l2.2-1.9l-4.3-0.5L1137.3,5.5z" />
    <path d="M942.3,10.2c0-0.2,0-0.4,0-0.6L939,9.5c0,0.2,0,0.4,0,0.6L942.3,10.2z" />
    <path d="M1224.6,41.3L1224.6,41.3C1224.5,41.5,1224.5,41.5,1224.6,41.3z" />
    <path d="M1222.1,40.2l2.4,1.2c0-0.2,0.1-0.6,0.2-1.1L1222.1,40.2z" />
    <path d="M309.9,32.4c0.1-0.2,0.1-0.4,0.1-0.6l-2.8-0.5c0,0.3-0.1,0.7-0.1,1L309.9,32.4z" />
    <path d="M404,39l0.5-1.9l-0.9-0.1l-0.1,1.9L404,39z" />
    <path d="M718,23l1.8,0.5l0.2-1l-1.8-0.1L718,23z" />
    <path d="M543.1,38h-0.4v1.1h0.4V38z" />
    <path d="M1274.6,50.9h0.4v-1.1h-0.4C1274.6,50.1,1274.6,50.5,1274.6,50.9z" />
    <path d="M1282.4,46.6h0.4v-1.1h-0.4V46.6z" />
    <path d="M1286.7,46.7h0.4v-1.1h-0.4V46.7z" />
    <path d="M790.1,21.3h0.4c0-0.4,0-0.8,0-1.1h-0.4V21.3z" />
    <path d="M956.4,9.4c0-0.1,0-0.2,0-0.4h-1.1v0.4H956.4z" />
    <path d="M974.5,9.2l0-1.1h-0.4c0,0.4,0,0.8,0,1.1H974.5z" />
    <path d="M1227.3,17.6l0.1-1.1h-0.4c0,0.4,0,0.8,0,1.1H1227.3z" />
    <path
      d="M363,73.4l1.2,1.4c5.8,0.2,11.4,0.4,16.9,0.5c0.3,0,0.5-0.5,0.8-0.8c0.2,0.3,0.5,0.6,0.7,0.8c0.3,0.2,0.6,0.4,0.9,0.4
			c1.6-0.4,3.3-0.4,5-0.1c1.1,0.3,2.5-0.8,3.7-1.3c0.4-0.2,0.7-0.7,1.4-1.6c-2.8-0.1-5.1,0-7.3-0.3c-2.1-0.2-4.3,0.2-6.2-0.8
			s-4.2-0.8-6.6-0.1c-1.7,0.5-3.8-0.7-5-0.9L363,73.4z"
    />
    <path
      d="M325.5,65.9c0,2.2,0.9,4.3,2.3,5.9c3.6-3,7.1,1.4,10.5,0.1l3.1-1.5c-0.5-0.2-0.9-0.4-1.3-0.6c-3.9-1.6-8-2.4-12-3.8
			C327.2,65.9,326.4,65.9,325.5,65.9z"
    />
    <path d="M282.7,67.3c0,0.5,0,0.8,0,1.2c3.9,0.8,3.9,0.8,5.5-2.2C286,64.6,284.6,67.1,282.7,67.3z" />
    <path d="M247.9,76.8c-0.1,1.3-0.1,2.4-0.1,3.5l1.7,0.1c0.1-1,0.2-2,0.4-3.1L247.9,76.8z" />
    <path
      d="M337.2,62.9c-0.3-0.6-0.4-1.1-0.5-1.1c-1.5-0.1-3.1-0.1-4.7-0.2c-0.1,0.8-0.1,1.3-0.2,2c0.9-0.3,1.8-0.6,2.7-0.7
			C335.4,62.8,336.3,62.8,337.2,62.9z"
    />
    <path d="M312,67.9v0.5l4.1,1.4c0.1-0.4,0.2-0.9,0.4-2L312,67.9z" />
    <path d="M357.4,80.8c-0.1,0.2-0.1,0.4-0.1,0.6l2.9,0.5c0-0.3,0.1-0.7,0.1-1L357.4,80.8z" />
    <path d="M262.8,81.1l-0.1,0.6l1.8,0.5l0.1-1L262.8,81.1z" />
    <path d="M262.9,78.4h1.8c0-0.2,0.1-0.3,0.1-0.5l-1.8-0.5L262.9,78.4z" />
    <path d="M361.8,80.7c-0.1,0.3-0.2,0.7-0.2,1h1.8c0-0.2,0.1-0.3,0.1-0.5L361.8,80.7z" />
    <path d="M219.6,75.1l-0.1,0.6l1.8,0.5l0.1-1L219.6,75.1z" />
    <path d="M293.5,68.6c0,0.2,0,0.4,0,0.6l2.2,0.1c0-0.2,0-0.4,0-0.6L293.5,68.6z" />
    <path d="M279.8,65.6c0,0.8-0.1,1.5-0.1,2.3h0.5l0.1-2.3H279.8z" />
    <path d="M302.6,67.4c-0.1,0.3-0.2,0.7-0.2,1l1.8,0.1c0-0.2,0.1-0.4,0.1-0.5L302.6,67.4z" />
    <path d="M266.9,61.7c0,0.3-0.1,0.7-0.1,1h1.8l0.1-0.5L266.9,61.7z" />
    <path d="M317.9,85.3c0,0.1,0,0.2,0,0.4h1.1c0-0.1,0-0.2,0-0.4H317.9z" />
    <path d="M332,84.7c0,0.1,0,0.3,0,0.4h1.1c0-0.1,0-0.2,0-0.4H332z" />
    <path d="M243.5,80.2v1.1h0.4v-1.1H243.5z" />
    <path d="M365.1,80.8c0,0.4,0,0.8,0,1.1h0.4v-1.1H365.1z" />
    <path d="M225.2,75.1c0,0.4,0,0.8,0,1.1h0.4v-1.1H225.2z" />
    <path d="M401.2,74.1v1.1h0.4v-1.1H401.2z" />
    <path d="M269,65.2v1.1h0.4c0.1-0.4,0.1-0.8,0.1-1.1H269z" />
    <path d="M276.6,65.5v1.1h0.4c0-0.4,0-0.8,0-1.1H276.6z" />
    <path d="M392.4,70c0-0.1,0-0.2,0-0.4h-1.1c0,0.1,0,0.2,0,0.4L392.4,70z" />
    <path d="M85.7,54.8c0,0.2-0.1,0.3-0.1,0.5l1.8,0.5l0.2-1L85.7,54.8z" />
    <path d="M96.6,56.1v1.1H97v-1.1H96.6z" />
    <path
      d="M0,20.8L0,112h1440V68c-1.5-0.4-3-0.7-4.5-0.9c-1.9-0.2-4.5-0.3-5.9-1.3c-1.9-1.4-3.8-0.6-5.9-1.4l-1.8-3.2
			c-2.6-0.1-5.7,0.5-8.2-0.5c-1.8-0.7-3.6-1-5.5-1c-1.6,0-3.2-0.8-4.6,0.8c-0.3,0.4-1.5,0.3-2.1,0c-2.6-1.5-5.5-0.9-8.2-1.5
			c-2.5-0.4-5-1.2-7.2-2.3c-1.1-0.7-2.8-0.1-4.3-0.3c-1.2-0.1-2.2-1.6-3.5-0.1c-0.2,0.2-0.7,0-1.1,0c-11.8-0.7-23.6,1.2-35.4,0
			c-1.1-0.1-2.3-0.5-3.6-0.8c-0.6,0.1-1.9,0.9-2.4,0.5c-2.1-1.6-4.4-1.2-6.6-1.2c-15.2-0.5-30.4-1-45.6-1.5
			c-1.9-0.2-3.9-0.5-5.8-0.9l-4.3,0.9c-0.8-1-1.4-1.9-2-2.8l-2.5,2.4l-4.6-0.4l3.7-1.7c-3.4-0.7-6.8,0.2-8.6-2.8
			c-3.2,2.9-6-0.2-9-0.4c-3-0.3-5.9-0.8-8.8-1.5c0.1-1.4,0.1-2.6,0.2-4c-0.5-0.2-1.1-0.3-1.6-0.4c-3.3-0.3-6.1,2.1-9.3,2.4
			c-0.7,0.1-1.3,0.7-1.9,0.6c-7.1-1-14.3,0.9-21.3,0.5c-7-0.4-14.1-0.5-21.2-0.8c-0.1-0.6-0.2-1.2-0.3-2.1
			c-3.3-0.6-6.9-0.2-10.4-0.6c0.5-2.8,0.5-2.8,4.8-3.7l-4.1-1.1c0.4-0.7,0.6-1.5,1-1.7c1.1-0.5,2.2-0.9,3.4-1
			c1.8-0.1,3.6,0.1,5.4,0.1c1.8,0,4,0.7,5.3-0.2c1.6-1,3.3,2.2,4.3-0.6c1.3,1.9,3.2,0.8,4.9,1.3s3.3-2.5,5,0
			c1.5-1.8,3.4-0.7,5.1-0.8c2-0.1,4,0.1,6,0.2l5.4,0.2l6,0.2c1.8,0.1,3.6,0.2,5.4,0.2c3.3,0,6.5,0.9,9.3,2.7
			c1.8,1.1,3.6,1.1,5.5,1.2c1.6,0.1,3.3,0,4.9,0.2c1.3,0.1,2.6,1.3,4,0.1c1.2,1.6,3,1,4.4,1.3c1.4,0.4,2.7,1.4,4.1,1.9
			c1,0.3,2,0.5,3.1,0.5c4.2,0.2,8.3,0.1,12.5,0.5c6.2,0.5,12.4,0.6,18.4,1.9c3.5,0.8,6.9,0.9,10.4,1.3c1.3,0.3,2.6,0.7,3.9,1.2
			c1.3,0.3,2.6,0.1,4,1c2,1.2,5-0.3,7,1.7c5.5-0.1,10.8,2.3,16.3,1.7c1.7-0.2,3.3-0.8,5-0.9c1.8-0.1,3.6-0.1,5.4,0.2
			c1.4,0.3,2.8,0.7,4.1,1.2l1.5-1c3.5,1.3,7,2.8,11,1.9l-1.8-0.8l-0.5-1.6c-0.3-0.1-0.6-0.4-0.9-0.4c-3.1,0.3-5.8-0.7-8.5-2
			c-1.8-0.8-3.3-1.1-5.2,0.4c-0.5-0.8-1.1-1.7-1.4-2.3c-0.9,0.4-1.8,0.8-2.8,1c-1.9,0.2-3.9-0.7-5.6,0.9c-0.3,0.3-1.1,0.1-1.6,0
			c-1-0.2-1.6-2.1-3.2-0.7c-0.5,0.5-2-0.5-3-0.5c-3.2,0-6-1.3-8.9-2.2c-1.3-0.4-2.9-0.3-4.2-0.5c-1.3-0.3-2.5-0.9-3.9-1.2
			c-2.3-0.6-4.6-1.1-6.8-1.6c0.1-0.7,0.1-1.1,0.1-1.9c3.1,2.6,7.2-0.2,10.3,2.5l1.5-1.5c-0.2-0.2-0.4-0.7-0.6-0.7
			c-4-0.1-7.7-1.5-11.5-2.7c-2.3-0.7-4.6-1.2-7.5-2c3.2-0.7,5.7-1.6,8.7-1.5c3.8,0.2,7.5-0.6,11.5-0.6s7.9,0.3,11.9,0.3
			c1.2-0.2,2.3-0.5,3.4-1l1.6,1.2l3.4-1.5c0-1.2,0.1-2.5,0.2-4.3l-3.2,2.6l-1.9-2.4c-0.6,0-1.1-0.1-1.6-0.2
			c-0.7-0.4-1.5-0.9-2.1-1.4c-0.8,1.2-1.4,2.2-2.2,3.4c-5.2,0.4-10.4-0.4-15.3-2.3c-1.6-0.6-3.3-0.9-5.1-1c-1.5,0-3,0.2-4.4,0.5
			c-4.1-3.1-8-5.4-13-5.1l-3.5,2.5l-1.2-2.9l-2.1,1.1c-3.1-2.1-7.2,0.2-9.8-1.5c-3-2-6.6,0.8-8.5-1.9c-1.9,0.1-3.5,0.7-4.8,0.3
			c-3.1-0.9-6.1-2.3-9.5-2.2c-2.1,0.1-4.2-0.3-6.1-1.2c-1.8-0.8-4,0.1-6-1.3c-1.3-0.9-3.6,0.5-4.9-1.2l-2.7,1.2
			c-0.5,1.5,6.6,4.9,9,4.3c0.9-0.3,1.8-0.8,2.6-1.2l2.7,2.7c1.2,0.1,2.5,0.2,3.7,0.1c1-0.3,2-0.7,2.9-1.3c4.7,3.1,10.3,4.6,15.9,4.1
			l1.8,1.7c-3.7,0-7.3,1.6-10.5-0.7c-0.7-0.3-1.4-0.4-2.1-0.1c-2.7,1.3-5.8,1.2-8.4-0.2c-1-0.5-2.6,0.3-4.5,0.6l-3.3-2
			c-4.1,2.9-8-1.2-12.1-0.6c-4.1,0.6-8-0.8-12.1-1.7l3.4-2.1l-4-1.9c-1.4,0.6-3.8,1.7-6.3-0.3c-0.6-0.5-1.7-0.2-2.6-0.3
			c-2.2-0.2-4.5,0.3-6.6-1.3c-1.4-1.1-3,1-4.7,0.2c-1.5-0.7-2.8-0.4-4.5,0.1c-2.6,0.8-5.3,0.7-7.9-0.1c-1.6-0.6-3.3-0.9-5-1
			c-1.8-0.2-3.6-0.3-5.4-0.2c-10.6,0.4-21-2.3-31.5-2.2c-3.5,0.1-6.8,0.6-10.5,0.7s-7.2-0.3-10.8-0.3c-0.8,0-1.5,0.7-2.2,1
			c-1.1-0.5-2.2-0.9-3.4-1.2c-4.9-0.3-9.9-1.1-14.6-0.4c-7.2,1.1-14.2,0.1-21.3,0.3c-2.3,0.1-4.6,0.7-6.8,0.8
			c-2.5,0.1-5.1-0.5-7.6-0.1c-2.4,0.5-4.8,0.8-7.2,0.8c-2.4-0.1-4.8,0.2-7.2,0.9c-4,1.5-8.2,1.8-12.6,2.4c-0.4-0.7-0.9-1.8-1.5-3
			c-3.8,0.5-7.6-1.2-11.5,0.7c-0.6,0.3-1.8-1-2.7-1c-0.9,0-1.9,0.8-2.9,0.8c-5-0.1-10.1,0.5-14.9,1.7c-2.1,0.5-4.6,0.6-6.7-1.4
			l-1.6,1.2c-1.4-2-2.9,0.6-4.6,0c-1.9-0.5-3.9-0.6-5.9-0.3c-1.6,0.2-3.2,0.6-4.8,1.1l-0.8-2.5c-2.6-0.1-5.6-1.1-7.5,0
			c-2.3,1.4-4.8-0.4-7,1.6c-1.2,1-3.7,0.3-5.5,0.8s-3,1.6-4.6,2.5l-2.6-1.6l-2.8,3.6l-7.6-3.7c-3.1,1.6-6.8,1.4-9.8-0.3l-12.8,3.4
			c-2.3,0.3-4.8,1.1-7-0.6c-0.8-0.4-1.8-0.5-2.6-0.1c-4.7,2.3-9.5,0.1-14.3,0.6c-0.3,0-0.8-0.7-1.1-1.1c-3.3,0.9-6.5,2.1-10,2
			c-3.2-0.2-6.5-0.3-9.8-0.3c-1.4,0-2.8-0.6-4.1,0.7c-0.4,0.4-1.9-0.2-2.8-0.5c-2.3-0.8-2.4-0.8-5.5,0.5l-2.2-1.4l-2,1.4l-2.1-1.5
			c-2.4,1.5-4.8,0.5-7.5,0.4s-5.8,0.8-8.5,1.4s-5.5-0.4-8.4,0.5c-2.8,0.9-5.7,1.4-8.6,1.4c-2.7-0.1-5.4,0.9-8.4,1.4l-1.4-1.9
			c-3.7-0.1-7.5-0.3-11.3-0.3c-2,0-4.1,0.7-6.1,0.8s-4.4-0.9-5.9,0c-3.3,1.9-7.1-0.6-10.1,1.8c-0.3,0.2-0.7,0.2-1.1,0
			c-4.3-2.4-9.1-0.8-13.9-1.7l-0.6-2.1l-1.9-0.1l0.1-0.1l-0.7-1l-0.3,0.3l0.8,0.8l0.1-0.1l1.2,2.3c-2.2-0.4-4.4,0.8-5.8-1l-3.6,1
			c-0.3-0.9-0.5-1.6-0.5-1.8l-3.1,3l-0.6-0.9l-4.5,1.5l-0.8-2.5l0.4-0.5l-2.2-1.3l-6.2,0.8c-0.4,0.6-1.1,1.5-2,2.8l-4.6,1.3
			c-0.1-1.2-0.2-2.2-0.3-3.3l-3.4-0.1c-2.2,1.9-5,2.9-7.9,3.1c-2.9,0.2-5.8,0.8-9,1.2c0.1-1.9,0.1-3.3,0.2-5.2
			c3.1-0.9,6.3-2.5,9.6-2.7c8.9-0.5,17.8-0.3,26.7-0.4c1.3,0,2.5,0.2,3.8,0.1c0.6-0.1,1.2-0.6,2-1.1c1.5,0.2,2.9,2.7,4.9,0.5
			c0.3-0.4,1.3-0.2,2-0.2c5.4,0.4,10.8-0.2,16-1.7c0.9-0.1,1.8-0.1,2.7,0.1c10,1.2,20-1,30-0.1c4.9-1.7,9.9-0.1,14.8-0.8
			c7.9-1.1,15.7,0.4,23.5-0.5c5.5-0.6,11,0.4,16.4-0.5c7.2-1.1,14.3,0.2,21.3-0.5c4.4-0.4,8.9,0.1,13.1-0.7c2.8-0.4,5.5-0.6,8.3-0.7
			c1.2-0.1,2,1.5,3.5,0.3c0.7-0.6,2.5-0.1,3.7,0c1.4,0,2.7-0.3,4-0.9c1.8-0.6,3.9-0.1,5.9,0.1c2.2,0.4,4.5-0.2,6.3-1.6
			c0-0.4,0-0.7,0-1.1c-0.3-0.2-0.7-0.6-0.9-0.5c-1.5,0.8-3.3,1.2-5,1c-8,0-16,0-24,0.4c-3.2,0.1-6.3,1.1-9.5,0.7
			c-1.5-0.2-3.3,0.6-4.5-1.2c-0.1-0.2-0.7-0.2-1-0.1c-3.2,1.5-6.6,0.8-9.9,0.8c-1.6,2-3.7,1-5.6,0.9c-10-0.4-20,1.4-30,0.2
			c-1.9-0.2-3.8,2.8-5.7,0c-1.7,1.5-3.7,0.8-5.5,0.8c-2.2,0-4.3-0.3-6.5-0.2s-4.6,0.6-6.6,0.9s-4.2-0.9-6.2,0.6
			c-2.4-1.6-4.7,0.7-7.1,0.2c-2.7-0.4-5.4-0.5-8.1-0.3c-7.3,0.4-14.5,1.1-21.8,0.4c-3.6,1.5-7.4,0.3-11,0.9
			c-2.5,0.6-5.1,0.8-7.8,0.6c-2.4-0.3-4.7,0.5-7,0.4c-2.6-0.1-5.3,0.3-7.9,0.3c-2.7,0.1-5.4,0-8.1-0.4c-1.4-0.3-2.7,1.9-4,0
			c-1.4,1.8-3.4,0.8-5,0.9s-3.2,0-4.9-0.1s-3.4-0.5-4.9-0.1c-5.3,1.6-10.7-0.1-15.9,0.7c-5.4,0.8-11,0.5-16.3,2.5
			c-0.7,0.3-1.4,0.3-2.1,0c-0.9-0.8-2.1-1.3-3.4-1.2c-5.6-0.1-11.2-0.3-16.8-0.5c-3.2,1.9-6.6,0.9-9.9,0.7
			c-3.5-0.1-6.9,1.5-10.5,0.9c-3.4-0.5-7-1-10.3-0.3c-4.8,1.1-9.6-0.2-14.2,0.7c-5,1-9.9,0.3-14.8,0.5c-2.4,0.1-4.7,1.3-7.2,1
			c-2.4-0.3-4.9-0.9-7.2,0.8c-0.5,0.4-1.4,0.1-2.1,0c-6-1-12,1.3-18,0.5c-2.8,1.6-5.8,0.8-8.8,0.9l-1.5,1.5l1.1,2.3
			c-0.6,0.3-0.9,0.6-1.2,0.6c-1.1,0-2.2-0.1-3.3-0.1c-2.7-0.1-5.4-0.4-8.1-0.2c-2.4,0.1-4.7,0.8-8,1.3c3.1,1.2,5.5,0.6,7.8,1
			l1.4,1.6c-2,1.6-2,1.6-6.1,1.9l7.7,3.6c0.3-0.9,0.6-1.5,0.8-2.2l8.2-0.8l1.1,0.8l6.3-2.2c0.2,0.7,0.4,1.4,0.6,2l4.3,1.6l4.2-2.1
			l5.9,0.2c0.9-1.3,1.5-2.3,2.3-3.5l2,2.4l12.4,0.4c0.2,0.7,0.3,1.2,0.4,1.8c-2,0.5-4,1.4-5.9,1.4c-2.5,0-2.2,3.7-4.7,3.3l-15.5-0.5
			c-0.1,1-0.2,2-0.4,3.6l6.2-0.9l1.3,1l2.7-1.3l1.6,1.7c-0.1,0.3-0.2,0.6-0.4,0.8c-3.6,2.1-7.3,3.1-11.3,0.9
			c-1.2,0.4-2.5,0.7-3.8,0.8c-4.7,0-9.4-0.2-14.1-0.5c-1-0.1-2.1-0.3-3-0.8c-2.4-1.1-4.7-0.6-7,0.7c-0.3-0.7-0.4-1.2-0.6-1.8
			c2.4-1.7,5.5-0.6,8.5-2.5l-9.9-0.3c-0.2-0.8-0.4-1.5-0.5-2.1c-2.4-0.2-2.4-0.2-4.6,1.4l3.3,0.7c-0.5,1-0.9,2-1.3,2.9
			c-2.6,1.4-3.3-1.6-5.6-2.6l-1.4,2.8c-2.9-0.7-5.4,0.6-7.4-1.5l-2.2,2.1l-8.8-0.3l-1.4-1.9l3.2-0.6v-0.5c-0.8-0.2-1.5-0.3-2.3-0.3
			c-1.1,0-2.1,0.4-3.1,0.4c-0.7-0.1-1.3-0.3-2-0.5c-0.6-0.1-1.3-0.1-1.9-0.1l-6.7,3.2c-2.1-0.9-4.3-1.8-6.6-2.4
			c-1.2-0.1-2.4-0.7-3.2-1.6c-1.3-1.6-2.7-1.2-4.1,0.1l-1.2-1.1c-0.3,0.7-0.6,1.4-1,2.5l-1.5-1.3l0.9-0.7l-0.3-0.4l-0.8,0.9l0.1,0.1
			c-1.9,0.2-3.7,0.5-5.5,1c-1.4,0.5-2-1.9-3.6-0.5c-0.8,0.6-2.4,0.2-3.7,0.2c-1.4,0-2.8-0.1-3.9-0.1c-0.9,1.3-1.5,2.3-2.3,3.4
			c-1.2-1.9-2.9-1.2-4.5-1.4s-3.3,0.5-4.4-1.3L446,43l-0.8-2.3l-5.2,2.2l-2.4-2.6c-2-0.9-4.6,0.3-6.9-1.4c-1.7-1.2-4.6,0.5-6.6-1.7
			l-1.8,1.1c-4.4-1.6-8.8-1.7-11.8-0.3l-1.9-1.5l-3.6,4.8c-3-1-5.9-2.5-8.9-0.3c-0.4,0.2-1.1-0.2-1.6,0c-1.4,0.6-2.4-1.6-4.1-0.3
			c-0.9,0.7-2.9,0.1-4.2,0s-2.6,0-4-0.9c-1.1-0.7-3.2,0-5.5,0.2c-2.9-1.9-7-0.3-10.7-1.5l-8.5-3.4l-1.2,1l-2.4-1.5
			c-0.3,1.4-0.4,2.4-0.7,3.7l-9.3-0.3c-0.1-0.8-0.3-1.8-0.5-3.3c-3.2,1.2-6-2-9.8-1c1.6,0.9,2.8,2.4,3.5,4.1
			c-1.8,0.1-3.5,0.8-4.8-1.1l-1.2,1l-0.9-0.9c-1.8,0.4-3.5,0.6-5.3,0.7c-7.1-0.1-14.2,0.2-21.1-0.8c-6.1-1-12.3-1.5-18.5-1.5
			c-3,0-6,1-9.8,1.6c-0.1-0.2-0.5-1.3-0.8-1.9c-2.7-0.1-4.8-0.7-6.5-0.1c-2,0.6-3.4-1.2-5.2-0.5c-0.9,0.4-1.9,0.7-3,1
			c-5.4-2-26.1-4.5-31.7-2l-2.7-2.7l-2,1.4c-2.5-1.8-5.5-1.2-8.6-1.4l-0.8,2.2l-6.5-3.5l-4.3-0.1c-0.9-2.3-3.1-3.8-5.5-3.6
			c-6.5-0.2-13-0.4-19.5-0.6c-1.9-0.1-3.8-0.7-5.8-0.8s-4.5,1-6.2,0.2c-3.8-1.6-7.7-0.9-11.5-1.4c-2.5-0.3-4.9,0.4-6.9,1.9
			c-1.6-2.1-4.2-1.5-5.9-1c-2.2,0.7-3.8,1-5.7-0.4c-0.3-0.3-1,0-1.9,0l1.8,4.1c-2.2,0.7-3.9,2-6.8,0.7c-3-1.4-6.8-1.1-10.1-2
			c-3.6,1.8-7.3,0.6-11,0.8c-1.6,0-3.2-0.1-5.2-0.2l-1.5-2.4c-2.1,0.5-3.9-0.7-6.1,0.9c-1.2,0.9-3.7,0.6-5.3-0.1
			c-4.4-2-9.1-0.8-14.3-1.7c-0.2-0.4-0.7-1.4-1-1.9l-9.9,0.6c-3.7,0.2-7-1.5-10.6-2.4c-3.8-0.7-7.7-1-11.7-1
			c-8.5-0.3-17-0.2-25.4,0.5C11.7,19.7,5.8,20.4,0,20.8z M553.5,30.7l-1.5,2.1l-2-2.2L553.5,30.7z M1261.3,52.5l0.2-1l1.8,0.5
			c0,0.2-0.1,0.3-0.1,0.5L1261.3,52.5z M1254.7,52.2c0-0.2,0.1-0.5,0.1-0.7l4,0.1l-0.1,1L1254.7,52.2z M1172.5,40.4
			c0-0.2,0-0.4,0-0.6l2.2,0.1c0,0.2,0,0.4,0,0.6L1172.5,40.4z M1175.8,35.3c-2.4,0.5-4.9,1.3-7.2-0.2L1175.8,35.3L1175.8,35.3z
			 M1163.9,35.8c-2.1,1.6-4.2,1-6.5,0.8l0.2-2l9.3,0.3c0,0.6-0.1,1.4-0.1,2.5L1163.9,35.8L1163.9,35.8z M1155.7,36.8
			c0-0.4,0-0.8,0-1.1h0.4v1.1L1155.7,36.8z M1147.7,36.1v-0.4h1.1c0,0.1,0,0.3,0,0.4H1147.7z M1137.9,36.5l5.2-1l1.3,1.5
			c-2.2,0.8-4.1,2.3-6.5,1C1137.9,37.6,1137.9,37.1,1137.9,36.5z M1131.6,37.1l-22.8-0.8c7.6-3.1,15.4-1.4,23.1-1.6l2.5,2.6l2.3-1.1
			c-0.2,1.4-0.9,3-1.4,2.9c-2.1-0.3-4,2.5-6.6,0.3L1131.6,37.1z M769.5,43.2c0.8,0.1,1.7,0.1,2.5,0c0.7-0.1,1.3-0.4,1.9-0.7l9.3,1.5
			c0,0.2-0.1,0.4-0.1,0.5l-13.7-0.5L769.5,43.2z M765.3,44.2v-1.1h0.4v1.1H765.3z M702.5,28.2c0-0.2,0-0.4,0-0.6l4.3,0.1
			c0,0.2,0,0.4,0,0.6L702.5,28.2z M698.1,31.2c0-0.3,0.1-0.9,0.1-1.9l2.1,0.5L698.1,31.2z M693.1,31.5l3.5-2.5
			c0.1,1.1,0.2,1.8,0.4,2.6L693.1,31.5z M650.2,30.2c-2.4,0-2.4-0.9-1.5-2.4l1.6,0.4C650.2,28.9,650.2,29.4,650.2,30.2L650.2,30.2z
			 M612.5,32.4C612.7,32.5,612.9,32.7,612.5,32.4z M604.7,33.3c-1.9,3.3-5.3,1.6-8.1,2.6l1.6-2.8l6.2,0.2c2.4-1.2,5.2-1.6,7.9-0.9
			l-2.2,4C608,35.1,606.2,34.2,604.7,33.3L604.7,33.3z M593.8,42c0-0.4,0-0.8,0-1.1h0.4l0,1.1H593.8z M584.1,42.8
			c-0.2-0.2-0.3-0.5-0.6-1.1c0.7,0.1,1.5,0.1,2.2,0c0.7-0.2,1.4-0.5,2-0.9l1.5,2.3l-0.5,1C587.1,43.7,585.3,44.8,584.1,42.8
			L584.1,42.8z M505.8,85.1c7.3,0.1,14.4-2.7,20.9-0.1l3,0.8c-7.2,1.1-13.1-1.1-18.4,0.7L505.8,85.1z M478.9,84.4
			c0-0.4,0.1-0.8,0.1-1.1c2,0.8,4.1-0.4,6.6,1.4L478.9,84.4z M463.9,84.1V83h0.4v1.1H463.9z M460.4,83.8l0.1-1l1.8,0.5l-0.1,0.6
			L460.4,83.8z M456.7,89.2c0.2-0.8,0.3-1.3,0.3-1.5l1.3,1.6L456.7,89.2z M451.5,87.8l0.1-0.5l1.8,0.1c0,0.3-0.1,0.7-0.2,1
			L451.5,87.8z M450.6,84.6l0.2-1l1.8,0.5c0,0.2-0.1,0.3-0.1,0.5L450.6,84.6z M144.1,27.1c-0.2,1-0.3,1.5-0.4,2.2
			c-1.8-1.9-5.1,2.1-6.2-2.7l2.1,0.9L144.1,27.1z M217.4,31.8v1.1H217v-1.1L217.4,31.8z M284.7,39.7c2.2,0.2,4.3,0.6,6.5,1.1
			c-4.1,1.8-8.6,2.5-13.1,2c-1.7-0.2-3.5,0.3-4.9-1.2c-1.5,1.7-3.4,0.7-5.1,0.8c-1.4,0.1-2.9-0.1-4.3-0.1l-4.3-0.1l-4.3-0.1
			c-1.6,0-3.3,0-4.9-0.2c-1.3-0.2-3.1-1.6-3.8-1.1c-1.5,1.2-2.4-0.3-3.5-0.3c-6.1-0.1-12.3-0.4-18.4-0.6l-13-0.4
			c-4.6-0.1-9-0.3-13.6-0.4c-4.2-0.1-8.2-1.9-12.5-1.7c-4.5,0.1-9,0-13.6-0.4c-1.2-0.2-2.3-0.6-3.4-1.1c-2.4,1.6-4.9,1-7.6,0.6
			l0.5-2l5.5,0.2c8.5,0.3,16.9,0.6,25.5,0.8s17,1.9,25.5,2.1C240.2,38,262.4,38.9,284.7,39.7L284.7,39.7z M340.9,35.5
			c1.1,3-0.1,2.8-1.5,2.3L340.9,35.5L340.9,35.5z M351.6,45.2c1.1,0.2,2.3,0.3,3.5,0.2c-0.1,0.4-0.1,0.8-0.1,1.1
			c-2.6-0.1-5.2-0.2-7.9-0.3c-0.2,0-0.3-0.5-0.4-0.6l1.8-1.9C349.4,44.3,350.4,44.8,351.6,45.2L351.6,45.2z M357.4,37.5v1.1H357
			v-1.1H357.4z M429.7,89.4c0-0.2,0.1-0.3,0.1-0.5l13.2-1.2l2,2.2l-8.7,2.1l-1.8-2c-0.9,0-1.8,0-2.7-0.1
			C431.1,89.7,430.4,89.5,429.7,89.4z M418.9,90.3c2.1-0.6,4.2-1.3,6.1,0.2L418.9,90.3z M417.9,73.5c7.4,0.7,14.3-0.3,21.5,2.7
			c-3.8,0.5-6.8,1-9.8,1.2c-3.3,0.1-6.7-0.2-10.1-0.3L417.9,73.5z M417.7,69.8l6-2.7c0.6,0.7,1,1.1,1.3,1.5l-1.7,1.7L417.7,69.8z
			 M411.5,91.4c1.5-0.3,2.9-3.7,5.1-1.4c-0.1,0.4-0.2,1-0.3,1.5L411.5,91.4z M400.6,84c0.1-0.2,0.1-0.4,0.1-0.6l2.9,0.1
			c0,0.3-0.1,0.7-0.1,1L400.6,84z M385.2,83.2l4.7-1.5c2.8,2.4,6.3,0.5,10.3,2.1c-4.8,0.9-8.7-0.7-12.6,0.8L385.2,83.2z M379.3,84.7
			c-0.1,0-0.3-0.5-0.4-0.8l3.3-0.9l2.2,1.2C382.7,84.7,381,84.9,379.3,84.7L379.3,84.7z M372.9,91.2c-0.7,0-1.4-0.4-1.6-1.1l1.7,0.4
			L372.9,91.2L372.9,91.2z M367.7,89.7c0-0.3,0.1-0.5,0.1-0.8c1,0,1.9,0.4,2.5,1.2L367.7,89.7z M337.4,87.5c0-0.2,0.1-0.3,0.1-0.5
			l1.8,0.1l-0.2,1L337.4,87.5z M112.6,57.7c-0.6,0.1-1.4-0.9-1.7-1.2l2.8-5.4c7.4,0.2,15.1,0.4,22.9,0.8c2.9,0.1,6.1-0.7,8.8,1.3
			c0.5,0.3,1.1,0.3,1.6,0c1.9-1.8,4.1-1,6.1-0.8c5.3,0.4,10.5,0.2,15.8,1.3c5.6,1.2,11.6,0.3,17.5,0c7.4-0.4,14.8,1.9,22.3,1.6
			c1.2-0.1,2.9,0.5,3.7-0.2c1.9-1.6,3.8-0.2,5.4-0.8c2.3,3.5,6.2,2.4,8.1,3.7l7.2,9.1c3.1,0.9,5.9-0.6,8.2,1.6
			c0.5,0.3,1.1,0.3,1.6,0.1c3.2-2.7,5.2,0.8,8.2,1.1c3.8-2.8,8.8-1.2,13.3-1.6c-0.6-1.3-0.9-2.2-1.6-3.6l-6.1,2.3
			c-2-1.8-4.5-1-7-1.2s-5.1-0.2-7.6-0.3l-7.9-0.3c-0.4-0.9-0.8-2-1.6-3.8l7.7,1.5c6.4-3.1,13.1-4.6,19.5,0.9l0.8-2.2
			C265,62.2,269,59,274,60l-2.5,1.1c0.3,0.7,0.7,1.5,1,2.1c1.6-0.5,2.7-0.9,3.9-1.1c1.1-0.1,2.1-0.2,3.2-0.2
			c-3.3-0.9-3.3-0.9-4.9-2.9c1.3-0.3,2.7-0.6,4-0.7c1.2,0.1,2.5,0.3,3.6,0.6c1.5,0.2,3-0.2,4.5,1c1,0.7,3.2-0.4,4.9-0.8
			c1.9-0.5,3.1,1.3,4.9,1.3s3.8-0.4,5.4,0.3c1.9,0.9,2.9-2.3,4.9-0.7c0.8,0.6,2.9-0.6,4.4-1c0.5-0.2,1-0.3,1.5-0.4
			c10.6,0.3,21.2,0.7,31.8,1l2.6,4.6c4.5,0.2,8.9,0.5,13.5,0.4s9.2,1.3,13.7-0.6c0.7-0.2,1.5-0.2,2.1,0.1c2.9,1.2,5.9,1.9,8.9,0.2
			l25.4,0.8c-0.1,0.9-0.1,1.4-0.1,2l-16.3,2.5c7.2,1.6,14.2,0.5,19.9-0.7l3.2,1.5l-5.2,1.9l4.7,2.4c-1.6,0.8-3,0.6-4.4,0.8
			s-2.5,1-4,1c-4.7,0-9.5,0.3-14.2,0.6c-0.3,0-0.8-0.1-1,0.1c-1.9,1.8-4.2,0.5-6.2,0.8c-2.2,0.3-4.5,1.1-6.6,0.8
			c-2.7-0.3-5.1,0.8-7.7,1.1c-1.3,0.1-2.5,0.9-4.1,1.6l2.1,1.9l5.6,0.2c-4.5,0.3-9.1,1.6-13.5-0.6c-0.6-0.3-1.7-0.5-2.1-0.2
			c-1.9,1.8-4.2,0.7-6.2,0.9c-1.1,0.1-2.3-0.2-3.1,0.3c-1.2,0.9-2.3,1-3.4,0.2c-1.3-1-2.4,1.1-3.6,0.3c-0.7-0.6-1.4-1.2-2.1-1.8
			l4.3-2.8c-1.7,0.3-3.4,0.6-5.1,0.7c-1.4,0-2.7-0.6-4.1-0.7c-1.6-0.2-3.3,0-4.9-0.2c-1.6,0-3.1-0.3-4.6-0.9c-2.3-1-4.9-1-7.2-0.1
			c-1.3,0.3-2.7,0.4-4,0.1c-0.8-0.2-1.6-0.5-2.3-0.9c-0.7-0.3-1.3-0.7-2-1l-0.6,2.2c-3.5-0.8-7.3,0.8-10.6-1.7l-2.1,1.3l-3.5-2.9
			c-0.5,1.1-0.9,1.8-1.3,2.6c0-0.1-0.1-0.1-0.1-0.2c-0.8-1.2-2.5-1.5-3.6-0.6c-1,0.5-2.6,0.9-3.5,0.5c-1.6-1-3.5-1.3-5.3-0.7
			c-1.8,0.5-4-1.1-5.8-0.5c-3.2,1-6.3,0.7-9.4,0.7c-0.3,0.1-0.6,0.2-0.9,0.4c1.5,1.5,2.3,1.8,5.1,2.6c0.1,0.6,0.2,1.1,0.3,1.7
			l9.3,0.3c2.9-0.1,5.8,0.1,8.7,0.4c2.8,0.5,5.7-3,8.4,0.2c0.2-0.3,0.5-0.7,0.8-0.8c4.4-1.2,8.9-1,13.4-2.1c0.9-0.2,1.8-0.1,2.5,0.4
			c1.6,1.3,3.7,1.6,5.6,0.7c2.8-1.3,5.6-0.4,7.8-0.7l4.3,2.5l2.4-1.5l2.2,2.8l-3.9,3.5l-5-3.1l-0.8,3.8c-2.5-1.8-5.4-2.4-7.7-1.4
			c-1.8,0.7-3.4,0.5-5.1,0.7c-2.2,0.3-4.1-1.3-6-1.2c-7.7,0.3-15.2-2-22.8-2c-5.6,0-11.4-0.5-16.8-0.5s-11-1.6-16.4,0.5
			c-2.5-1.7-5.6-0.6-8-1.9s-4.4-0.7-6.9,0l1.7-4.3c0,0,0,0.1,0,0.1c0.6-2.5,4.1,0.5,4.2-2.8c-1.9-1.2-4.1,0-6.4-1
			c0,1.9,1.7,2.4,2.1,3.6l-3.1,1.1l-1.3-1.4c-2-0.3-3.4-0.5-4.9-0.8c-2-0.5-3.3,0.8-4.7,1.4c0.3,1.1,0.5,1.9,0.8,3.2
			c-2.9-0.6-5.5,0.5-7.6-1.5c-0.5-0.3-1.1-0.3-1.6-0.1c-1.1,1.2-1.9,0-2.9,0c-1.3,0-2.6,0.8-3.9,0.8c-0.9,0-1.8-1-2.8-1.7l7-2.2
			c-1.5-1.4-3.4-2.4-5.5-2.8c-2.9-0.6-6,0.6-8.8-1.4c-0.4-0.2-1.3-0.3-1.5,0c-1.3,1.7-3.1,1-4.5,0.8c-2.7-0.4-5.3-1.3-8-1.9
			c-1.7-0.3-3.7-1-5-0.3c-1.5,0.8-2.7,0.5-4.1,0.6c-7,0.6-13.7-2-20.7-1.7c-4.3,0.2-8.3-1.7-12.5-1.7c-1.7,0-3.4-1.2-5.8-2.1
			c4.1-0.6,7.5,0.7,10.7-1c1-0.5,2.2-0.4,3.2,0.1c1.9,1.7,4,1.1,6.1,1.2c5.1,0.2,10.1,0.4,15.2,0.5c0.3,0,0.7-0.1,1-0.3
			c0.2-0.7,0.5-1.3,0.8-2.3l3.8,1.6l3.4-1.2l2.5,2.6c3.4,0.8,6.7-2.8,9.7,0.2c4.2-2.6,8.4,0.4,12.4,0.4c0.1-1.3,0.1-2.2,0.1-3.5
			c-2.3,0.2-4.4,0.5-6.4,0.6s-4.1-2.1-5.4-0.4c-9.4-1-18-5.1-27.6-2.6c-1.4-0.9-2.7-2.3-4.8-2.3c-3.1,0-6.1-0.1-9.2-0.3
			c-2.9-0.4-6.1,1-8.7-1.3c-4.9,2.3-9.9,0.2-14.8,0.5c-1.5,0.1-3-0.6-4.5-1C120.9,61.1,116.8,56.7,112.6,57.7L112.6,57.7z
			 M75.7,51.5l1.7-1.7c4.3,0.7,9.1-0.8,13,1.2l7.1-0.9c0.4,0.9,0.8,1.8,1.4,3.1l1-2.5l8.2,0.3c0,1.2-0.1,2.3-0.1,3.8l-2.9,3.1
			l-6.6-1.5l-0.6,3c-6.3,1.1-12.1-1.6-18-1.7c-0.1-0.9-0.2-1.4-0.3-2L77.3,57L75.7,51.5z"
    />
  </StyledSvg>
);

export default BrushStroke1;
