import styled from 'styled-components';
import { ThemeType } from '../../../types';
import { HEADER_OFFSET } from '../../../constants/offsets';

export const Sticky = styled.div(
  ({ theme: { tabletView, mobileView } }: ThemeType) => `
    position: sticky;
    top: ${HEADER_OFFSET.default}px;

    ${tabletView} {
      top: ${HEADER_OFFSET.tablet}px;
    }

    ${mobileView} {
      position: relative;
      top: 0;
    }
`,
);

export const StyledSidebar = styled.aside(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
      flex: 0 300px;        
      
      ${mobileView} {
        flex: 1 100%;
        padding: 0 ${spacing.x3};        
      }
  `,
);
