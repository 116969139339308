import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-pink.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const PinkSplatter1: React.StatelessComponent<WidthHeightGreebleType> = ({ top, left, right, bottom, flipX }) => (
  <StyledGreeble top={top} left={left} flipX={flipX} bottom={bottom} right={right} width={16.73} height={42.0} image={SplatterImage} />
);

export default PinkSplatter1;
