import React from 'react';
import { ArtistType } from '../../types';
import Row, { Session, Title, Sessions, IconButton } from './styles';
import { ExternalLinkIcon } from '../../icons';
import { format } from 'date-fns';

interface Props extends ArtistType {
  selectArtist: any;
  fade: boolean;
}

const LineupRow: React.FunctionComponent<Props> = ({ wordpress_id, title, data: { sessions }, selectArtist, fade }) => {
  const handleClick = () => selectArtist(wordpress_id);
  return (
    <Row onClick={handleClick}>
      <Title className="h4" fade={fade}>
        {title}
      </Title>
      <Sessions>
        {sessions &&
          sessions.map(({ sessionDate, startTime }) => (
            <Session>{`${sessionDate ? format(sessionDate, 'ddd') : ''} ${startTime ? format(startTime, 'HH:mm') : ''}`}</Session>
          ))}
      </Sessions>
      <ExternalLinkIcon />
    </Row>
  );
};

export default LineupRow;
