import React from 'react';
import ColumnBlock from '../Block';
import { FrontPageTicket } from '../../components/Ticket';
import { TicketBlockType } from '../../types';
import StackContainer from './styles';
import { useInView } from 'react-intersection-observer';
import isBrowser from '../../utils/isBrowser';

export const TicketBlock: React.FunctionComponent<TicketBlockType> = ({ title, subTitle, tickets, cta }) => {
  if (!isBrowser()) {
    return null;
  }

  const [ref, onScreen] = useInView({ threshold: 0.75 });

  return (
    <ColumnBlock title={title} subTitle={subTitle} cta={cta}>
      <StackContainer className={onScreen ? 'visible' : ''} data-test="Tickets" ref={ref}>
        {tickets && tickets.slice(0, 3).map((props, index) => <FrontPageTicket key={index} {...props} />)}
      </StackContainer>
    </ColumnBlock>
  );
};

export default TicketBlock;
