import styled from 'styled-components';

export const TicketsWrapper = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: ${spacing.x6};
    grid-row-gap: ${spacing.x8};

    ${mobileView} {
      grid-template-columns: 1fr;
    }
`,
);
